import { useEffect, useState } from 'react';
import DifferedTask from '../DifferedTask';

const useDifferedTask = (task_id, { client: _client, websocketUrl, token }) => {
    const [data, setData] = useState(null);
    const [client, setClient] = useState(null);

    useEffect(() => {
        if (!task_id) return;

        if (_client) setClient(_client);
        else if (websocketUrl) setClient(new DifferedTask(task_id, websocketUrl, token));
    }, [_client, websocketUrl, token, task_id]);

    useEffect(() => {
        if (!client) return;

        client.on((d) => setData(d));
        return () => {
            if (!client) return;
            client.off((d) => setData(d));
        };
    }, [client]);

    return data;
};

export default useDifferedTask;
