import { Button, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditIcon } from '@a2d24-icons/edit-icon';
import useStyles from '../styles';
import React, { useState } from 'react';
import EditDialog from './EditDialog';
import moment from 'moment/moment';

const UnavailabilityRange = ({fields, index, setEditIndex, setEditOpen, setInitialEditValues, eventTypeMapping})=> {
    const classes = useStyles();
    return(
        <div style={{marginLeft: '15px'}}>
            <div className={classes.UnavailableItem} style={{ alignItems: 'center', justifyContent: 'center'}}>
                <div>
                    {moment(fields.value[index].start_datetime).format('MMMM Do YYYY HH:mm')} - {moment(fields.value[index].end_datetime).format('MMMM Do YYYY HH:mm')}
                </div>
                <IconButton
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }}
                    onClick={() => {
                        setInitialEditValues(fields.value[index]);
                        setEditIndex(index);
                        setEditOpen();
                    }}
                    aria-label="edit"
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }}
                    onClick={() => {
                        fields.remove(index);
                    }}
                    aria-label="delete"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
            <div className={classes.UnavailableBadges} style={{marginBottom: '10px'}}>
                {
                    (fields.value[index].event_types??[]).map(
                        (event_type, idx)=>{
                            return(
                                <div key={idx} className={classes.UnavailableBadge}>
                                    {eventTypeMapping[event_type]}
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )

}
const UnavailabilityFieldArray = ({ mutators: { push } , eventTypes}) => {
    const classes = useStyles();
    const fieldArrayId = `unavailability`;
    const eventTypeOptions = eventTypes.map((eventType)=>{
        return {
            value: eventType.event_type_id,
            label: eventType.name
        }
    })
    const eventTypeMapping = {};
    eventTypes.forEach((eventType)=>{
        eventTypeMapping[eventType.event_type_id]=eventType.name
    })
    const [editOpen, setEditOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
    const [initialEditValues, setInitialEditValues] = useState({});

    return (
        <div>
            <FieldArray name={fieldArrayId}>
                {({ fields }) => {
                    return (
                        <div className={classes.UnavailabilityContainer}>
                            <Button
                                onClick={() => {
                                    setEditIndex(fields.length);
                                    setInitialEditValues({});
                                    setEditOpen(true);
                                }}
                                style={{ margin: '5px' }}
                                variant="contained"
                                color={'primary'}
                            >
                                Add a date override
                            </Button>
                            <EditDialog
                                open={editOpen}
                                handleDialogClose={()=>{setEditOpen(false)}}
                                fields={fields}
                                index={editIndex}
                                eventTypes={eventTypeOptions}
                                initialValues={initialEditValues}
                            />
                            <div className={classes.timePickersContainer}>
                                {
                                    fields.map((name, index) => {
                                        return (
                                            <div key={index} className={classes.UnavailabilityFormContainer}>
                                                <UnavailabilityRange
                                                    fields={fields}
                                                    name={name}
                                                    index={index}
                                                    key={index}
                                                    eventTypeMapping={eventTypeMapping}
                                                    setEditIndex={setEditIndex}
                                                    setEditOpen={()=>setEditOpen(true)}
                                                    setInitialEditValues={setInitialEditValues}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )
                    }}
            </FieldArray>

        </div>
    );
};

export default UnavailabilityFieldArray;
