import React, { useRef, useState } from 'react';
import FieldLayout from './FieldLayout';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { Field } from 'react-final-form';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useLocation } from 'react-router-dom';
// @import '~filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginGetFile from 'filepond-plugin-get-file';

registerPlugin(FilePondPluginGetFile);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const UploadUniversalField = ({ id, name, optional, initial, description, warning, metadata }) => {
    let label = name;

    return (
        <>
            <FieldLayout description={description} warning={warning}>
                <Field
                    name={id}
                    placeholder={label}
                    optional={optional}
                    initial={initial}
                    metadata={metadata}
                    component={UploadAdapter}
                ></Field>
            </FieldLayout>
        </>
    );
};

export default UploadUniversalField;

const UploadAdapter = ({ input, optional, metadata, ...rest }) => {
    const metadata_upload = metadata;
    const classes = useStyles();
    const { onChange, ...restInput } = input;
    const [value, setValue] = useState('');
    const location = useLocation();
    const [files, setFiles] = useState([]);

    const handleUploadSuccess = (bucket_key) => {
        setValue(bucket_key);
    };

    React.useEffect(() => {
        onChange(value === '' ? null : value);
    }, [value]);

    return (
        <>
            {
                <FilePond
                    key={'file_upload'}
                    files={files}
                    oninit={() => {
                        if (restInput.value !== '') {
                            setValue(restInput.value);
                            setFiles([
                                {
                                    source: restInput.value,
                                    options: {
                                        type: 'local',
                                        // mock file information
                                        file: {
                                            name: `${restInput.name}`,
                                            size: 3001025,
                                            type: 'application/pdf',
                                        },
                                    },
                                },
                            ]);
                        }
                    }}
                    maxFileSize="25MB"
                    onupdatefiles={(fileItems) => {
                        setFiles(fileItems);
                    }}
                    server={{
                        // load: (source, load, error, progress, abort, headers) => {
                        //     // console.log('attempting to load', source);
                        //     headers("Content-Disposition=inline; filename=my-file.jpg")
                        //     load('http://www.africau.edu/images/default/sample.pdf');
                        //     // implement logic to load file from server here
                        //     return {
                        //         abort: () => {
                        //             // User tapped cancel, abort our ongoing actions here
                        //             setFiles([])
                        //             console.log('cancel')
                        //             // Let FilePond know the request has been cancelled
                        //             abort();
                        //         }
                        //     };
                        // },
                        revert: (uniqueFileId, load, error) => {
                            handleUploadSuccess('');
                            setFiles([]);
                            load();
                        },
                        process: (
                            fieldName,
                            file,
                            metadata,
                            load,
                            error,
                            progress,
                            abort,
                            transfer,
                            options
                        ) => {
                            // send a POST request to retrieve presigned url for s3
                            const CancelToken = axios.CancelToken;
                            const source = CancelToken.source();
                            const contentType = file.type;
                            const fileType = file.type.split('/', 2);
                            const media_type = fileType[0];
                            const media_extension = fileType[1];
                            const location_arr = location.pathname.split('/');
                            const procedure_detail_id = location_arr[location_arr.length - 1];
                            const consult_id = location_arr[location_arr.length - 3];
                            Recycle.rpcPostV2({
                                controller: 'DocumentController',
                                method: 'get_presigned_post_url_for_universal_file_upload',
                                kwargs: {
                                    key: metadata_upload.key,
                                    content_type: contentType,
                                    file_extension: media_extension,
                                },
                            })
                                .then((res) => {
                                    const responseData = res;
                                    const presignedUrlData = responseData[0];
                                    const presignedUrl = presignedUrlData['url'];
                                    const fields = presignedUrlData['fields'];
                                    const headers = {
                                        'Content-Type': '"multipart/form-data',
                                    };
                                    let formData = new FormData();
                                    for (let key in fields) {
                                        if (fields.hasOwnProperty(key)) {
                                            formData.append(key, fields[key]);
                                        }
                                    }
                                    formData.set('Content-Type', contentType);
                                    formData.append('file', file);
                                    axios
                                        .post(presignedUrl, formData, {
                                            headers: headers,
                                            timeout: 15000,
                                            cancelToken: source.token,
                                            onUploadProgress: (progressEvent) => {
                                                progress(
                                                    progressEvent.lengthComputable,
                                                    progressEvent.loaded,
                                                    progressEvent.total
                                                );
                                            },
                                        })
                                        .then((responseFileUpload) => {
                                            load(fields['key']);
                                            handleUploadSuccess(fields['key']);
                                        })
                                        .catch((errorUpload) => {
                                            console.log(errorUpload);

                                            if (axios.isCancel(errorUpload)) {
                                                console.log(
                                                    'Request cancelled',
                                                    errorUpload.message
                                                );
                                            } else {
                                                error();
                                                // handle error
                                            }
                                        });
                                })
                                .catch((e) => {
                                    console.log(e);
                                    if (axios.isCancel(e)) {
                                        console.log('Request canceled', e.message);
                                    } else {
                                        error();
                                        // handle error
                                    }
                                });
                            // Should expose an abort method so the request can be cancelled
                            return {
                                abort: () => {
                                    console.log('aborting upload');
                                    // cancel the request (the message parameter is optional)
                                    source.cancel('Upload cancelled by the user.');

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                },
                            };
                        },
                    }}
                    allowDownloadByUrl={true}
                    allowMultiple={false}
                    credits={false}
                    acceptedFileTypes={['application/pdf']}
                    labelIdle={`<span class="filepond--label-action">Upload ${restInput.name} Document</span>`}
                />
            }

            {
                // Hack - does not support required
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={value}
                    required={!optional}
                    // readOnly
                    onChange={() => {}}
                />
            }
        </>
    );
};
