import styles from './styles';
import { format, Day } from 'date-fns';

export function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
}

export function getDateOnly(date) {
    const fullDate = new Date(date);
    fullDate.setHours(0, 0, 0, 0);
    return fullDate;
}

export const renderValue = (
    heading,
    field,
    isDate = false,
    valueBackgroundColor = 'transparent'
) => {
    const classes = styles();
    return (
        <div className={classes.InlinesItemContainer}>
            <div className={classes.SubHeadingDiv}>{heading}</div>
            {field?.value ? (
                <div
                    className={classes.ValuesDiv}
                    style={{ backgroundColor: valueBackgroundColor }}
                >
                    {isDate ? formatDate(new Date(field?.value)) : field?.value}
                    {field?.units}
                </div>
            ) : (
                <div className={classes.NoInformationDiv}>No Information Available</div>
            )}
        </div>
    );
};

export const renderValues = (heading, values, is_dict = true) => {
    const classes = styles();
    return (
        <div className={classes.InlinesItemContainer}>
            <div className={classes.SubHeadingDiv}>{heading}</div>
            {(values ?? []).length > 0 ? (
                <div className={classes.FlexColumnContainer}>
                    {values.map((value, idx) => {
                        return <div key={idx}>{is_dict ? value.value : value}</div>;
                    })}
                </div>
            ) : (
                <div className={classes.NoInformationDiv}>No Information Available</div>
            )}
        </div>
    );
};
