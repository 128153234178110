import styles from './styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import theme from '../../theme';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

const CheckBoxCell = ({ rowData, onCheckBoxChange, checkedMessage, uncheckedMessage, variant }) => {
    const classes = styles();
    let color = null;
    switch (variant) {
        case 'white':
            color = 'white';
            break;
        case 'dark':
            color = theme.palette.primary.dark;
            break;
        default:
            color = theme.palette.primary.dark;
    }
    const handleChange = (event) => {
        const isChecked = event.target.checked;
        rowData['isSelected'] = isChecked;
        onCheckBoxChange(rowData, isChecked);
    };
    return (
        <div className={classes.checkBoxDiv}>
            <Tooltip title={rowData.isSelected ? checkedMessage : uncheckedMessage}>
                <Checkbox
                    checked={rowData.isSelected ? rowData.isSelected : false}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize={'medium'} />}
                    checkedIcon={<CheckBoxIcon fontSize={'medium'} />}
                    sx={{
                        color: color,
                        '&.Mui-checked': {
                            color: theme.palette.primary.dark,
                        },
                    }}
                />
            </Tooltip>
        </div>
    );
};

export default CheckBoxCell;
