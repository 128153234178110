import React from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import '../ReactFinalFormFields/PhoneNumberInput.css';
import FieldLayout from './FieldLayout';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container_error: {
        marginTop: theme.spacing(2),
        '& .form-control': {
            width: '100%',
            border: 'none',
            borderBottom: `2px solid ${theme.palette.error.main}`,
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '-webkit-transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            marginBottom: '-2px',
        },
        '& .form-control:focus': {
            borderBottom: `2px solid ${theme.palette.error.main}`,
            marginBottom: '-2px',
        },
        '& .form-control:hover': {
            borderBottom: `2px solid ${theme.palette.error.main}`,
            marginBottom: '-2px',
        },
        '& .flag-dropdown': {
            border: 'none',
        },
        '& .special-label': {
            color: theme.palette.error.main,
        },
    },
    container: {
        marginTop: theme.spacing(2),
        '& .form-control': {
            width: '100%',
            border: 'none',
            borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '-webkit-transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            marginBottom: '-1px',
        },
        '& .form-control:focus': {
            borderBottom: `2px solid rgba(0, 0, 0, 0.87)`,
            marginBottom: '-2px',
        },
        '& .form-control:hover': {
            borderBottom: `2px solid rgba(0, 0, 0, 0.87)`,
            marginBottom: '-2px',
        },
        '& .flag-dropdown': {
            border: 'none',
        },
        '& .special-label': {
            color: `rgba(0, 0, 0, 0.6)`,
        },
    },
}));

export const PhonenumberComponent = ({
    value,
    onChange,
    name,
    label,
    required,
    invalid,
    dirty,
    defaultCountry = 'za',
}) => {
    const classes = useStyles();

    return (
        <PhoneInput
            masks={{ za: '.. ... ....' }}
            name={name}
            specialLabel={label}
            inputProps={{
                name: name,
                required: required,
            }}
            value={value}
            enableSearch={true}
            disableSearchIcon={true}
            containerClass={invalid && dirty ? classes.container_error : classes.container}
            placeholder="+27 92 123 4567"
            preferredCountries={[defaultCountry]}
            onChange={(_value, _country, _e, formattedValue) => onChange(formattedValue)}
            country={defaultCountry}
        />
    );
};

const PhonenumberField = ({ id, name, optional, unit, description, warning, initial }) => {
    let label = name;
    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id} placeholder={label} optional={optional} unit={unit}>
                {(fieldProps) => {
                    const { invalid, dirty } = fieldProps.meta;
                    return (
                        <PhonenumberComponent
                            name={fieldProps.input.name}
                            label={fieldProps.placeholder}
                            onChange={fieldProps.input.onChange}
                            required={optional === false}
                            invalid={invalid}
                            initialValue={initial}
                            dirty={dirty}
                        />
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default PhonenumberField;
