import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchCareActivity = (patient_id, care_activity_id) => {
    return axios
        .post(`${API_URL}/CarePlannerController/care_activity_by_id`, {
            patient_id: patient_id,
            care_activity_id: care_activity_id,
        })
        .then((r) => r.data);
};

export const fetchPatientConditions = (patient_id) => {
    return axios
        .post(
            `${API_URL}/MedicalConditionsController/get_current_active_patient_medical_conditions`,
            {
                patient_identifier: patient_id,
            }
        )
        .then((r) => r.data);
};

export const fetchAdditionalConditions = (current_plans) => {
    return axios
        .post(`${API_URL}/MedicalConditionsController/get_additional_medical_condition_paths`, {
            current_plans: current_plans,
        })
        .then((r) => r.data);
};
