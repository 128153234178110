import React, { useEffect, useState } from 'react';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import MaterialDivider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploader from './FileUploader';
import {
    ButtonContainer,
    ColorlibConnector,
    ColorlibStepIcon,
    ContainerPaper,
    GreyPaperContainer,
    SMSTextContainer,
} from './Styling';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import { useStores } from '@a2d24/care-at-work-state/stores';
import useDifferedTask from '@a2d24/differed-task/react/useDifferedTask';
import DynamicForm from '../DynamicForm';

const RegisterPatientConsent = ({ config, props }) => {
    const { authStore } = useStores();
    const { patient, booking } = props;
    const dsp_details = authStore.dsp_details;
    const classes = useStyles();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [updateDetails, setUpdateDetails] = useState(update_details);
    const [contactNumber, setContactNumber] = React.useState(patient.contact_number);
    const [useSMSConsent, setUseSMSConsent] = React.useState(false);
    const steps = getSteps();
    const [openUpdateNumberPopup, setOpenUpdateNumberPopup] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [sms_consent_task_id, setSMSTaskId] = useState();
    const triggerConsentSMS = () => {
        Recycle.rpcPostV2({
            controller: 'PatientController',
            method: 'trigger_terms_and_conditions_consent_sms',
            kwargs: {
                patient_primary_identifier: patient.primary_identifier.value,
                booking_id: booking.booking_id,
            },
        })
            .then((res) => {
                setUseSMSConsent(true);
                setSMSTaskId(res.task_id.toString());
            })
            .catch(({ response }) => {
                const { status } = response;
                setUseSMSConsent(false);
                if (status !== 403) {
                    enqueueSnackbar('There was an error trying to trigger a consent SMS', {
                        variant: 'error',
                    });
                }
            });
    };
    const differedData = useDifferedTask(sms_consent_task_id, {
        websocketUrl: process.env.REACT_APP_WS_ENDPOINT,
        token: authStore.occhealthToken,
    });

    React.useEffect(() => {
        if (useSMSConsent === true) return;
        triggerConsentSMS();
    }, [useSMSConsent, enqueueSnackbar, patient, booking]);

    useEffect(() => {
        if (differedData !== null) {
            if (
                differedData.progress.description === 'Sms consent given' &&
                parseInt(differedData.progress.current_step) === 2 &&
                parseInt(differedData.progress.total_steps) === 2
            ) {
                setActiveStep(2);
            }
        }
    }, [differedData]);

    function getSteps() {
        return [
            "Receive an SMS on patient's phone",
            "Agree to the terms of services & reply 'Yes'",
            'Successfully Registered',
        ];
    }

    const handleSubmitUpdateNumber = async (values) => {
        if (values.contact_number === contactNumber) {
            return;
        }
        await Recycle.rpcPostV2({
            controller: 'PatientController',
            method: 'update_patient_contact_number',
            kwargs: {
                primary_identifier: patient.primary_identifier.value,
                patient_number: values.contact_number,
                booking_id: booking.booking_id,
            },
        })
            .then((res) => {
                setOpenUpdateNumberPopup(false);
                setUseSMSConsent(res.use_sms_consent);
                setContactNumber(res.contact_number);
                setSMSTaskId(res.task_id.toString());
            })
            .catch((err) => {
                setUseSMSConsent(false);
                enqueueSnackbar('There was an error trying to trigger a consent SMS', {
                    variant: 'error',
                });
            });
    };

    const handleCloseUpdateNumber = () => {
        setOpenUpdateNumberPopup(false);
    };

    const care_planner_options = [
        'occupational_health_consult_with_cof',
        'chronic_care_consult',
        'workplace_disease_consult',
    ];
    const handleProceed = () => {
        Recycle.rpcPostV2({
            controller: 'BookingController',
            method: 'update_booking_status',
            kwargs: {
                new_status: 'arrived',
                booking_id: props.booking.booking_id,
                patient_identifier: patient.primary_identifier.value,
            },
        })
            .then((res) => {
                if (care_planner_options.includes(booking.consult_type)) {
                    navigate(
                        `/care-plan-recommender/${patient.primary_identifier.value}/${booking.booking_id}`
                    );
                } else {
                    Recycle.rpcPostV2({
                        controller: 'ConsultController',
                        method: 'create_consult',
                        kwargs: {
                            booking_id: booking.booking_id,
                            patient_primary_identifier: patient.primary_identifier.value,
                        },
                    })
                        .then((res) => {
                            enqueueSnackbar('Registration Successful', { variant: 'success' });
                            navigate('/dashboard');
                        })
                        .catch((err) => {
                            enqueueSnackbar('Failed to create consult', { variant: 'error' });
                        });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Failed to update booking status', { variant: 'error' });
            });
    };

    return (
        <React.Fragment>
            <ContainerPaper>
                <div className={classes.title}>Patient Consent</div>
                <Typography>Please confirm Terms of Service before proceeding</Typography>
                {useSMSConsent ? (
                    <>
                        <GreyPaperContainer square={false}>
                            <SMSTextContainer>
                                <p> SMS Sent to</p>
                                <p>
                                    {' '}
                                    {patient.first_name} {patient.last_name} | {contactNumber}
                                </p>
                                {activeStep < 2 && <CircularProgress />}
                            </SMSTextContainer>
                        </GreyPaperContainer>
                        <Stepper
                            style={{ padding: 24 }}
                            alternativeLabel
                            activeStep={activeStep}
                            connector={<ColorlibConnector />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                        Step {index + 1} {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <MaterialDivider variant="middle" />
                    </>
                ) : (
                    <>
                        <GreyPaperContainer square={false} className={classes.noConsentBanner}>
                            <p>
                                The patient needs to have a valid South African contact number to
                                consent using SMS.
                            </p>
                            <p>
                                Registered contact number: <b>{contactNumber}</b>
                            </p>
                            <p>
                                Please use manual process or <b>UPDATE CONTACT</b> below to change
                                their registered contact number
                            </p>
                        </GreyPaperContainer>
                    </>
                )}

                <h5> {useSMSConsent ? 'Try' : 'Use'} Manual Process</h5>
                <div style={{ paddingBottom: '10px' }}>
                    <div>
                        <GetAppIcon />
                        <Link
                            href="https://care-at-work-dev-assets.s3.af-south-1.amazonaws.com/Care@Work+Consent+160922.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Downloads Terms and Conditions for Signature
                        </Link>
                    </div>
                </div>
                <FileUploader
                    patient_identifier={`${patient.primary_identifier.value}`}
                    booking_id={booking.booking_id}
                />

                <ButtonContainer>
                    <Button variant="contained" onClick={() => setOpenUpdateNumberPopup(true)}>
                        Update Contact
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleProceed}
                        color="primary"
                        disabled={activeStep !== 2}
                    >
                        Proceed to Confirm
                    </Button>
                </ButtonContainer>
            </ContainerPaper>

            {/*TODO: Update the contact method change*/}

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openUpdateNumberPopup}
                onClose={handleCloseUpdateNumber}
                PaperProps={{
                    className: classes.dialogPaper,
                }}
                sx={{ justifyContent: 'center' }}
            >
                <DynamicForm
                    onSubmit={handleSubmitUpdateNumber}
                    config={{
                        fields: updateDetails,
                        defaultValues: { contact_number: contactNumber },
                    }}
                    actions={[
                        ({ submitting }) => (
                            <Button
                                key="cancel"
                                disabled={submitting}
                                onClick={handleCloseUpdateNumber}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        ),
                        ({ submitting }) => (
                            <Button
                                id="update-number"
                                key="update"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >
                                Update
                            </Button>
                        ),
                    ]}
                />
            </Dialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        overflowY: 'unset',
        padding: '20px',
    },
    dialogContent: {
        overflowY: 'initial',
    },
    noConsentBanner: {
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-center',
        marginTop: theme.spacing(2),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
    },
    smsText: {
        padding: '5px',
        backgroundColor: '#f8f3f3',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    printAcknowledgement: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    divider: {
        width: '65%',
    },
    checkIcon: {
        color: theme.palette.success.main,
    },
}));

let update_details = {
    id: 'UPDATE_CONTACT',
    name: 'Update Contact',
    groups: [
        {
            id: 'part_1',
            name: 'Update Contact Number',
            fields: [
                {
                    id: 'contact_number',
                    name: 'Contact Number',
                    type: 'phonenumber_field',
                    validation: ['valid_phone_number', 'get', 'contact_number'],
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                },
            ],
        },
    ],
};

export default RegisterPatientConsent;
