import React from 'react';
import TransferStockInFromAnotherClinic from '../StockManagement/TransferStockInFromAnotherClinic';
import {
    GetClinicsTransferIn,
    getStockItems,
    getTransferStockItems,
    saveStockReceivedTransferIn,
} from '../../StockManagerConfig';

const TransferStockInFromAnotherClinicBase = ({ config, props }) => {
    return (
        <>
            <TransferStockInFromAnotherClinic
                GetClinicsTransferIn={GetClinicsTransferIn}
                getStockItems={getStockItems}
                getTransferStockItems={getTransferStockItems}
                saveStockReceivedTransferIn={saveStockReceivedTransferIn}
            />
        </>
    );
};

export default TransferStockInFromAnotherClinicBase;
