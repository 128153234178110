import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';

const InfoText = ({ placeholder, text, icon, capitalize, className }) => {
    const classes = useStyles({ capitalize });

    return (
      text!=undefined ?
        <div className={className}>
            <div className={classes.root}>
                {icon ? <Icon className={classes.icon}>{icon}</Icon> : null}
                <div>
                    <div className={classes.placeholder}>{placeholder}</div>
                    <div className={classes.text}>{text}</div>
                </div>
            </div>
        </div>
        :
        null
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
    },
    placeholder: {
        color: '#666666',
        marginBottom: theme.spacing(1),
    },
    text: (props) => {
        const textTransform = props.capitalize ? 'capitalize' : 'initial';
        return {
            color: '#333333',
            textTransform: textTransform,
            fontWeight: 'bold',
        };
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
}));

export default InfoText;
