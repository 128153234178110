import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';
import { evaluatePolishExpression } from '@a2d24/care-at-work-core/Recycle';

const CalculatedNumericField = ({
    id,
    name,
    optional,
    unit,
    description,
    warning,
    calculation,
    numeric_config,
    calculation_precondition,
}) => {
    const config = numeric_config || {};
    const { precision = null } = config;

    const label = name;
    const form = useForm();
    const state = useFormState({ subscription: { values: true } });

    React.useEffect(() => {
        const shouldCalculate = evaluatePolishExpression(calculation_precondition, state.values);
        if (!shouldCalculate) {
            return;
        }

        let value = evaluatePolishExpression(calculation, state.values);

        if (value && precision) {
            const q = Math.pow(10, precision);
            value = Math.round(value * q) / q;
        }

        // [IM]: Quick fix resulted in this fragile nested if structure. Needs restructuring.
        if (value === undefined) {
            if (state.values[id] !== undefined) {
                form.change(id, null);
            }
        } else if (value === Number.POSITIVE_INFINITY) {
            if (state.values[id] !== Number.POSITIVE_INFINITY) {
                form.change(id, Number.POSITIVE_INFINITY);
            }
        } else if (value === Number.NEGATIVE_INFINITY) {
            if (state.values[id] !== Number.NEGATIVE_INFINITY) {
                form.change(id, Number.NEGATIVE_INFINITY);
            }
        }
        if (isNaN(value)) {
            if (!isNaN(state.values[id])) {
                form.change(id, value);
            }
        } else if (state.values[id] !== value) {
            form.change(id, value);
        }
    }, [state.values, id, form, calculation, precision, calculation_precondition]);

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id} placeholder={label} optional={optional} unit={unit}>
                {(fieldProps) => {
                    return (
                        <TextField
                            label={fieldProps.placeholder}
                            name={fieldProps.input.name}
                            testid={id}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment key={`${id}-Adornment`} position="end">
                                        <label>{unit}</label>
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            required={optional === false}
                        />
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default CalculatedNumericField;
