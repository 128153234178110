import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import InfoText from '../InfoText';
import { calculateAge } from '../../shared/utils';
import { sharedStyles } from '../../shared/styles';

const AgendaConsultDetails = ({ config, props }) => {
    const classes = useStyles();
    const sharedClasses = sharedStyles();

    return (
        <div>
            <div className={classes.top}>
                <img
                    className={classes.profileImage}
                    src={`/${props.consult.client_id}_logo.png`}
                    alt="profile"
                />

                <div className={classes.consultDetails}>
                    <div className={sharedClasses.heading}>
                        {props.consult.patient.first_name} {props.consult.patient.last_name}
                    </div>
                    <div className={clsx([classes.identifier, sharedClasses.helperText])}>
                        ID: {props.consult.patient.identifier}
                    </div>

                    <div className={clsx([sharedClasses.helperText, classes.service])}>
                        <div>Arrived on: {dayjs(props.consult.arrived_at).format('hh:mm A')}</div>
                    </div>
                </div>
            </div>

            <Divider className={classes.divider} variant="middle" />

            <div className={classes.rightContainer}>
                <BasicInformation patient={props.consult.patient} />
            </div>
        </div>
    );
};

const BasicInformation = ({ patient }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.heading}>Basic Information</div>

            <div className={classes.infoContainer}>
                <InfoText
                    className={classes.infoText}
                    placeholder="Gender"
                    capitalize={true}
                    text={patient.gender}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Age"
                    text={calculateAge(patient.date_of_birth)}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Contact No."
                    text={patient.contact_number}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Email ID"
                    text={patient.email}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    top: {
        display: 'flex',
        textTransform: 'capitalize',
    },
    consultDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    profileImage: {
        height: '82px',
    },
    identifier: {
        textAlign: 'end',
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    infoText: {
        padding: theme.spacing(3),
        '&:not(:first-child)': {
            borderLeft: '1px solid #EEEEEE',
        },
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    service: {
        display: 'flex',
    },
}));

export default AgendaConsultDetails;
