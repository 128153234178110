import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DefaultInput } from '@a2d24-ui/react-final-pickers';
import { Field } from 'react-final-form';
import React from 'react';

export const FieldDateTimePicker = ({name, validationFn, label, minuteStep, onChange=null, minDateTime=null, maxDateTime=null, disabled=false}) =>{
    return (
        <Field name={name} validate={validationFn}>
              {(fieldProps) => {
                return (
                    <div>
                        <DateTimePicker
                            value={fieldProps.input.value}
                            onChange={(val) => {
                                fieldProps.input.onChange(val);
                                if (onChange) {
                                    onChange(val);
                                }
                            }}
                            renderInput={DefaultInput}
                            ampm={false}
                            minutesStep={minuteStep}
                            minDateTime={minDateTime}
                            maxDateTime={maxDateTime}
                            label={label}
                            disabled={disabled}
                        />
                        <label
                            style={{
                                color: '#d29292',
                                fontSize: '12px',
                                display: 'block',
                                fontWeight: 400,
                                marginTop: '3px',
                                textAlign: 'left'
                            }}
                        >
                            {fieldProps.meta.error}
                        </label>
                    </div>
                );
              }}
        </Field>
    )
}
