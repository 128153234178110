import React from 'react';
import { Button } from '@mui/material';
import useStyles from '../styles';
import FreeTextField from '../../components/FormComponents/FreeTextField';
import MultiSelector from '../../components/FormComponents/MultiSelector';
import UnitField from '../../components/FormComponents/UnitField';

const NewEventTypeForm = ({
    handleSubmit,
    dirty,
    form,
    submitting,
    values,
    errors,
    closeNewEventType,
    filteredServices
}) => {
    const classes = useStyles();
    const { mutators } = form;

    return (
        <form style={{width:'100%'}} onSubmit={handleSubmit}>
            <div className={classes.NewEventTypeContainer}>
                <FreeTextField id={"name"} label={"Title"} description={'Provide a clear title for your event type that clearly explains what type of booking it is (e.g. Short consult)'} variant={'outlined'} optional={false}/>
                <MultiSelector id={"services"} label={"Services included in the event"} description={'Select all services that can be booked for this event, ensuring the services are not used in more than one event'} variant={'outlined'} optional={false} options={filteredServices}/>
                <UnitField id={"duration"} label={"Duration"} description={'Select the duration of the of the event in minutes'} variant={'outlined'} optional={false} unit={"minute(s)"} numeric_config={{step:5, min:5, precision:0}}/>
                <UnitField id={"limits.event_per_slot"} label={"Consultations per time slot"} description={'Reflects how many consultants you have available for booking at one time slot.'} variant={'outlined'} optional={false} numeric_config={{min:1, precision:0}}/>
                <UnitField id={"limits.minimum_notice"} label={"Booking notice period"} description={'Reflects how much notice is required for a booking in hours (e.g. 2 hours, 0.5 hours, 24 hours)'} variant={'outlined'} optional={false} unit={"hour(s)"} numeric_config={{min:1, step:0.5, precision:1}}/>
                <UnitField id={"limits.cancellation_period"} label={"Cancellation notice period"} description={'Reflects how much notice is required for a cancellation in hours (e.g. 2 hours, 0.5 hours, 24 hours) '} variant={'outlined'} unit={"hour(s)"} optional={false} numeric_config={{min:1, step:0.5, precision:1}}/>
            </div>
            <div className={classes.ActionDiv}>
                {/*{dirty && <div>{errors.general}</div>}*/}

                <div className={classes.advancedSettings}>
                    <Button
                        onClick={closeNewEventType}
                        style={{ margin: '5px' }}
                        variant="contained"
                        color={'primary'}
                    >
                        Exit

                    </Button>
                </div>
                <Button
                    type={'submit'}
                    disabled={submitting}
                    style={{ margin: '5px' }}
                    variant="contained"
                    color={'primary'}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default NewEventTypeForm;
