const theme = {
    palette: {
        warning: {
            main: '#e9bf7a',
            light: 'rgba(188,166,120,0.1)',
            dark: 'rgba(188,166,120,1)',
        },
        success: {
            main: '#6caa9c',
            light: 'rgba(94,157,143,0.1)',
            dark: 'rgba(94,157,143,1)',
        },
        error: {
            main: '#d29292',
            light: '#ffc3c2',
            dark: '#9f6464',
        },
        primary: {
            main: '#1D3440',
            light: '#475e6b',
            dark: '#000d1a',
        },
        gray: {
            main: '#77858f',
            light: '#D3D3D3',
            dark: 'hsl(206,8%,12%)',
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ],
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    zIndex: 'unset',
                },
            },
        },
    },
};

export default theme;
