import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FileSaver from 'file-saver';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Fab } from '@mui/material';
import { DownloadFromUrl } from '@a2d24/downloader/helpers';

const useStyles = makeStyles((theme) => ({
    document: {
        display: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottomMargin: '1px',
        height: '100%',
        overflow: 'auto',
    },
    page: {
        border: '1px solid #ccc',
        margin: 5,
        marginLeft: 20,
        marginRight: 20,
    },
    documentContainer: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: '1fr auto',
    },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfViewer = ({isUrl=false, data, scale, download_filename}) => {
    const theme = useTheme();
    const classes = useStyles();

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    return (
        <>
            <div className={classes.documentContainer}>
                <div className={classes.document}>
                    <Document
                        file={isUrl ? data : `data:application/pdf;base64,${data}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            className={classes.page}
                            scale={scale}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </div>
                <MobileStepper
                    steps={numPages}
                    position="static"
                    variant="text"
                    activeStep={pageNumber - 1}
                    nextButton={
                        <Button size="small" onClick={nextPage} disabled={pageNumber === numPages}>
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={previousPage} disabled={pageNumber === 1}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </div>

            <Fab
                style={{ position: 'fixed', right: '30px', bottom: '60px' }}
                onClick={() => {
                    if(isUrl) {
                        DownloadFromUrl(data, download_filename);
                    }
                    else{
                        var blob = base64toBlob(data, 'application/pdf');
                        FileSaver.saveAs(blob, download_filename);
                    }
                }}
                color="primary"
                aria-label="download"
            >
                <CloudDownloadIcon />
            </Fab>
        </>
    );
};

export default PdfViewer;

const base64toBlob = function (b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
