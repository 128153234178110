import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';
import Divider from './Divider';

const CofResultDetail = ({ rule: { business_rules_evaluation_result, cof_result } }) => {
    const {
        status: cof_status,
        comments: cof_comments,
        cof_evaluation_status,
        client_specific_cof_status: cof_client_specific_cof_status,
    } = cof_result || {};
    let cof_other_comments_and_statuses = cof_result?.other_comments_and_statuses ?? [];
    if (cof_other_comments_and_statuses.length === 0) {
        cof_other_comments_and_statuses = business_rules_evaluation_result
            .filter((evaluation_status) => {
                return (
                    evaluation_status.rule_category === 'cof' &&
                    evaluation_status?.comments &&
                    evaluation_status?.status?.name !== cof_status.name
                );
            })
            .sort((comment_1, comment_2) => {
                return comment_2.status.score - comment_1.status.score;
            });
    }
    return (
        cof_result && (
            <div>
                <Typography
                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                    variant="h6"
                >
                    {cof_evaluation_status} Certificate of Fitness Result
                </Typography>
                <Typography variant="body1" color="textSecondary" fontWeight="bold">
                    {cof_client_specific_cof_status}
                </Typography>
                {cof_comments && cof_comments.length >= 1 && (
                    <Typography variant="body1">Comments:</Typography>
                )}
                {cof_comments && cof_comments.length >= 1 && (
                    <ul>
                        {cof_comments.map((note, idx) => (
                            <li key={idx}>
                                <p>{note}</p>
                            </li>
                        ))}
                    </ul>
                )}
                {cof_other_comments_and_statuses && cof_other_comments_and_statuses.length >= 1 && (
                    <>
                        <Divider />
                        <Typography variant="body1">Other fitness comments:</Typography>
                    </>
                )}
                {cof_other_comments_and_statuses && cof_other_comments_and_statuses.length >= 1 && (
                    <ul>
                        {cof_other_comments_and_statuses.map((comment_and_status, idx) => (
                            <li key={idx}>
                                <p>
                                    {comment_and_status.comments} [{comment_and_status.status.name}]
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    );
};

const CoMorbidityResultDetail = ({
    rule: { business_rules_evaluation_result, co_morbidity_result },
}) => {
    const {
        status: co_morbidity_status,
        comments: co_morbidity_comments,
        other_comments_and_statuses: co_morbidity_other_comments,
        co_morbidity_evaluation_status,
    } = co_morbidity_result || {};
    return (
        co_morbidity_result && (
            <div>
                <Typography
                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                    variant="h6"
                >
                    {co_morbidity_evaluation_status} Co-morbidity Risk Stratification
                </Typography>
                <Typography variant="body1" color="textSecondary" fontWeight="bold">
                    {co_morbidity_status.name}
                </Typography>
                {co_morbidity_comments && co_morbidity_comments.length >= 1 && (
                    <Typography variant="body1">Comments:</Typography>
                )}
                {co_morbidity_comments && co_morbidity_comments.length >= 1 && (
                    <ul>
                        {co_morbidity_comments.map((note, idx) => (
                            <li key={idx}>
                                <p>{note}</p>
                            </li>
                        ))}
                    </ul>
                )}
                {co_morbidity_other_comments && co_morbidity_other_comments.length >= 1 && (
                    <>
                        <Divider />
                        <Typography variant="body1">Other co-morbidity risk comments:</Typography>
                    </>
                )}
                {co_morbidity_other_comments && co_morbidity_other_comments.length >= 1 && (
                    <ul>
                        {co_morbidity_other_comments.map((comment_and_status, idx) => (
                            <li key={idx}>
                                <p>
                                    {comment_and_status.comments} [{comment_and_status.status.name}]
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    );
};

const ConsultResult = ({ props: { rules_result } }) => {
    const classes = useStyles();
    if (!rules_result?.cof_result && !rules_result?.co_morbidity_result) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Paper className={classes.root}>
                <CofResultDetail rule={rules_result} />
            </Paper>
            <Paper className={classes.root}>
                <CoMorbidityResultDetail rule={rules_result} />
            </Paper>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
    },
}));

export default ConsultResult;
