import styles from '../styles';
import { formatDate, renderValue } from '../utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export const Spirometry = ({spirometry}) => {
    const classes = styles();
    const getColorForLFT = (lung_function_test) => {
        if(lung_function_test === "Normal (>=75%)"){
            return '#5e9d8f'
        }
        if(["Mild Restrictive (60-74%)", "Mild Obstructive (60-74%)"].includes(lung_function_test)){
            return '#97a0a9'
        }
        if(["Moderate Restrictive (41-59%)", "Moderate Obstructive (41-59%)"].includes(lung_function_test)){
            return '#daaa67'
        }
        if(["Severe Restrictive (<=40%)", "Severe Obstructive (<=40%)"].includes(lung_function_test)){
            return '#9f6464'
        }
    }

    const renderLungFunctionTestTable = (results)=>{
        return (
        <TableContainer sx={{ width: '50%',border: '3px solid #000d1a'}}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: '900', color:'#000d1a'}} align="left">Date</TableCell>
                <TableCell sx={{ fontWeight: '900', color: '#000d1a'}} align="left">Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {results.map((row, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell sx={{ fontWeight: '500', color: '#000d1a'}} >{formatDate(new Date(row.created_at))}</TableCell>
                  <TableCell sx={{ fontWeight: '500', color: 'white', backgroundColor:`${getColorForLFT(row.value)}`}}>{row.value}</TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
        )
    }

    return(
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Occupational health history: Spirometry</div>
            {renderValue('Date of last test', spirometry?.date_of_last_test, true)}
            {renderValue('Lung Function', spirometry?.spirometry_results_fev1)}

            {
                spirometry.historical_spirometry_results_fev1?
                    <>
                        <div className={classes.SubHeadingDiv}> Lung Function Test Results </div>
                        {renderLungFunctionTestTable(spirometry?.historical_spirometry_results_fev1??[])}
                    </>


                :
                    renderValue('Lung Function Test Results', spirometry?.spirometry_results_fev1)
            }
        </div>

    )
}
