import { default as Grid } from '@mui/material/Unstable_Grid2';
import React from 'react';

const FieldLayout = ({ children, description, warning }) => {
    return (
        <Grid
            wrap="nowrap"
            spacing={10}
            columnSpacing={'unset'}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item xs={8}>
                {children}
            </Grid>
            {description && (
                <Grid item xs={4}>
                    <label name="description" style={{ color: 'grey', fontSize: '12px' }}>
                        {description}
                    </label>
                </Grid>
            )}
            {warning && (
                <Grid item xs={4}>
                    <label name="warning" style={{ color: 'red', fontSize: '16px' }}>
                        {warning}
                    </label>
                </Grid>
            )}
        </Grid>
    );
};

export default FieldLayout;
