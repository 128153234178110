import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
export const PatientConditionDetails = ({ patientConditions }) => {
    const classes = useStyles();
    return (
        <div className={classes.conditions}>
            {patientConditions.map((condition, idx) => {
                return (
                    <Tooltip
                        key={idx}
                        title={`${condition.disease} ${
                            ['Controlled', 'Uncontrolled'].includes(condition.pathway)
                                ? condition.pathway
                                : ''
                        }`}
                        className={classes.conditionTooltip}
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'white',
                                    color: '#000d1a',
                                    boxShadow: '2px 2px 2px 3px rgba(0, 0, 0, 0.1)',
                                    fontSize: '0.8rem',
                                },
                            },
                        }}
                    >
                        <div className={classes.conditionBadge}>
                            <img
                                src={`/diseases/${condition.disease
                                    .toLowerCase()
                                    .replaceAll(' ', '_')}.svg`}
                                width={'35px'}
                                height={'35px'}
                                alt={condition}
                            />
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    conditions: {
        alignSelf: 'flex-end',
        marginBottom: 'auto',
        display: 'flex',
    },
    conditionBadge: {
        borderRadius: '50%',
        fontSize: '1rem',
        fontWeight: 'bold',
        width: '35px',
        height: '35px',
        border: '4px solid white',
        backgroundColor: '#d1d6da',
        color: '#56636b',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '-10px',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
