// Auto-Generated by @a2d24/react-engine. Do not edit directly

import initEngine from '@a2d24/react-engine';
import renderRootComponent from '@a2d24/react-engine-dom';

// prettier-ignore
const engineConfig = {
    'modules': {
        'root-router': '@a2d24/root-router',
        'root-wrappers': '@a2d24/root-wrappers',
        'care-at-work-template-module': '@a2d24/care-at-work-template-module',
        'care-at-work-state': '@a2d24/care-at-work-state',
        'care-at-work-core': '@a2d24/care-at-work-core',
        'care-at-work-login': '@a2d24/care-at-work-login'
    },
    'template': '@a2d24/care-at-work-template-module',
    'render': 'root-router.RootRouter',
    'moduleGenerators': {
        'root-router': () => require('@a2d24/root-router').default,
        'root-wrappers': () => require('@a2d24/root-wrappers').default,
        'care-at-work-template-module': () => require('@a2d24/care-at-work-template-module').default,
        'care-at-work-state': () => require('@a2d24/care-at-work-state').default,
        'care-at-work-core': () => require('@a2d24/care-at-work-core').default,
        'care-at-work-login': () => require('@a2d24/care-at-work-login').default
    }
};

try {
    initEngine(engineConfig);
    renderRootComponent();
} catch (e) {
    console.error(e);
}
