import React from 'react';
import dayjs from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';

const ServiceCell = ({ consult }) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.description}>{consult.tariff_description}</div>
            <div className={classes.date}>{dayjs(consult.arrived_at).format('DD/MM/YYYY')}</div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    description: {
        fontSize: '14px',
        fontWeight: 600,
    },
    date: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
        textTransform: 'capitalize',
    },
}));

export default ServiceCell;
