import React from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import Button from '@mui/material/Button';
import PatientCell from './PatientCell';
import ServiceCell from './ServiceCell';
import StatusCell from './StatusCell';

const ConsultSearchResultsTable = ({ config, props }) => {
    const navigate = useNavigate();

    const columns = [
        {
            field: 'patient_details',
            title: 'PATIENT_DETAILS',
            render: (rowData) => <PatientCell consult={rowData} />,
        },
        {
            field: 'tariff_description',
            title: 'SERVICE',
            render: (rowData) => <ServiceCell consult={rowData} />,
        },
        {
            field: 'consult_status',
            title: 'STATUS',
            render: (rowData) => <StatusCell status={rowData.consult_status} />,
        },
    ];

    const handleViewConsult = (consult) => {
        navigate(`/consult-review/${consult.consult_id}`);
    };

    return (
        <MaterialTable
            data={props.consults || []}
            columns={columns}
            title="Search Results"
            actions={[
                (rowData) => ({
                    label: 'view',
                    onClick: handleViewConsult,
                }),
            ]}
            components={{
                Action: (props) => {
                    const buttonProps = props.action.action(props.data);
                    return buttonProps.hidden ? null : (
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: 15 }}
                            disabled={props.data.consult_status !== 'completed'}
                            onClick={() => buttonProps.onClick(props.data)}
                        >
                            {buttonProps.label}
                        </Button>
                    );
                },
            }}
            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px',
                },
                search: false,
                actionsColumnIndex: -1,
            }}
        />
    );
};

export default ConsultSearchResultsTable;
