import Button from '@mui/material/Button';
import React from 'react';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const RequestSignoffAction = ({ consult, setSubmitting, submitting, role }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const requestSignoff = () => {
        setSubmitting(true);
        Recycle.rpcPostV2({
            controller: 'ConsultController',
            method: 'request_signoff',
            kwargs: { consult_id: consult.consult_id },
        })
            .then((res) => {
                setSubmitting(false);
                enqueueSnackbar('Sign off requested', { variant: 'success' });
                if (role === 'dsp_omp') {
                    navigate(`/dashboard`);
                } else if (role === 'clinician') {
                    navigate(`/disease-management-selector/${consult.consult_id}`);
                } else {
                }
            })
            .catch((err) => {
                setSubmitting(false);
                enqueueSnackbar('Failed to request sign off', { variant: 'error' });
            });
    };

    return (
        <Button variant="contained" onClick={requestSignoff} disabled={submitting}>
            request sign off
        </Button>
    );
};

export default RequestSignoffAction;
