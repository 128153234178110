import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { appointmentStatusColorMapping } from '../const';

const StatusCell = ({ status }) => {
    const classes = useStyles({ status });

    return <div className={classes.root}>{status.replace(/_/g, ' ')}</div>;
};

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        const color = appointmentStatusColorMapping(theme)[props.status].dark;
        return {
            color: color,
            textTransform: 'capitalize',
            fontSize: '12px',
        };
    },
}));

export default StatusCell;
