import React from 'react';
import { styled, theme } from '@a2d24-ui/theme';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { observer } from '@a2d24/care-at-work-state/mobx-react-lite';
import { ChatBubbleIcon, CheckIcon, EnvelopeClosedIcon } from '@radix-ui/react-icons';

const AuthenticatorContents = styled('div', {
    maxWidth: '65ch',
    width: '80vw',

    padding: '3ch',
    border: '1px solid $cardIconSeparatorColor',
    borderRadius: '$2',

    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: '2ch',
    p: {
        marginLeft: '4ch',
    },
});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$primary',
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$primary4' },
    '&:focus': { boxShadow: `0 0 0 2px $colors$primary3` },
});

export const Authenticator = observer(({ authenticator, setSelected }) => {
    return (
        <AuthenticatorContents>
            {authenticator.oob_channel === 'email' ? (
                <EnvelopeClosedIcon width={25} height={25} />
            ) : (
                <ChatBubbleIcon width={25} height={25} />
            )}
            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {authenticator.name.replaceAll('X', '*')}
                {!authenticator.active && (
                    <span
                        style={{
                            fontSize: 'smaller',
                            color: theme.colors.warning,
                            padding: '2px 5px',
                        }}
                    >
                        Unverified
                    </span>
                )}
            </p>
            <IconButton
                onClick={() => {
                    setSelected(authenticator);
                }}
            >
                <CheckIcon />
            </IconButton>
        </AuthenticatorContents>
    );
});

export default observer(({ setSelected }) => {
    const { mfaStore } = useStores();
    return (
        <Content>
            <h1>Which method would you like to use to authenticate?</h1>
            {mfaStore.mfaAuthenticators.length === 0 && (
                <p>You currently do not have any verification methods set up</p>
            )}
            {mfaStore.mfaAuthenticators.map((auth) => (
                <Authenticator authenticator={auth} key={auth.id} setSelected={setSelected} />
            ))}
        </Content>
    );
});

const Content = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '2ch',
    color: '$primaryBlack',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 4ch',
    minHeight: '100%',
    width: '100%',
    alignSelf: 'flex-start',
    overflowY: 'auto',
    h1: {
        fontWeight: 300,
        fontSize: '1.5em',
        textAlign: 'center',
    },
});
