import 'chartjs-adapter-moment'
import styles from '../styles';
import { getDateOnly, renderValue } from '../utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const Audiometry = ({audiometry}) => {
    const classes = styles();

    const renderAudiometryTestLineGraph = ()=>{
         const options = {
          // response: true,
         maintainAspectRatio: false,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "week",
              }
            }
          }
        };

         const data = {
          datasets: [
              {
                  label: 'STS',
                  data:  (audiometry?.historical_audiometry_results_sts??[]).map(
                      (datapoint) => {
                          return {
                              x: getDateOnly(datapoint.created_at),
                              y: parseFloat(datapoint.value)
                          }
                      }
                  ),
                  borderColor: 'rgb(61,128,68)',
                  backgroundColor: 'rgba(61,128,68, 0.5)'
              },
              {
                  label: 'Baseline',
                  data:  (audiometry?.historical_audiometry_results_baseline??[]).map(
                      (datapoint) => {
                          return {
                              x: getDateOnly(datapoint.created_at),
                              y: parseFloat(datapoint.value)
                          }
                      }
                  ),
                  borderColor: 'rgb(110,110,110)',
                  backgroundColor: 'rgba(110,110,110, 0.5)'
              },
               {
                  label: 'PLH',
                  data:  (audiometry?.historical_audiometry_results_plh??[]).map(
                      (datapoint) => {
                          return {
                              x: getDateOnly(datapoint.created_at),
                              y: parseFloat(datapoint.value)
                          }
                      }
                  ),
                  borderColor: 'rgb(231,53,68)',
                  backgroundColor: 'rgba(231,53,68,0.5)'
              },
          ]
        };

         return(
                <Line options={options} data={data} style={{height: '300px'}}></Line>

         )

    }
    return(
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Occupational health history: Audiometry</div>
            {renderValue('Date of last test', audiometry?.last_audiometry_test_date, true)}
            {renderValue('Baseline', audiometry?.audiometry_results_baseline)}
            {renderValue('PLH', audiometry?.audiometry_results_plh)}
            {renderValue('STS', audiometry?.audiometry_results_sts)}
            {
                audiometry.historical_audiometry_results_sts?
                    <div style={{padding: '25px'}}>
                        {renderAudiometryTestLineGraph()}
                    </div>
                :
                    null
            }


        </div>

    )
}
