import styles from '../styles';

export const SummaryDemographics = ({demographics})=> {
    const classes = styles();
    const getDemographicsString = ()=>{
        let demographicsString = `${demographics.first_name} ${demographics.surname}`
        if(demographics?.id){
            demographicsString += ` | ${demographics.id.value}`
        }
        if(demographics?.passport_number){
            demographicsString += ` | ${demographics.passport_number.value}`
        }
        if(demographics?.job_title){
            demographicsString += ` | ${demographics.job_title.value}`
        }
        if(demographics?.employee_number){
            demographicsString += ` | Employee #: ${demographics.employee_number.value}`
        }
        return demographicsString;
    }
    return(
        <div className={classes.DemographicsContainer}>
            <div className={classes.MainHeadingDiv}>
                Patient Summary
            </div>
            <div className={classes.ValuesDiv}>
                {
                    getDemographicsString()
                }
            </div>
        </div>
    )
}
