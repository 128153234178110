import React, { useState } from 'react';
import { ColumnFlex, styled, Text, theme } from '@a2d24-ui/theme';
import { BellIcon } from '@radix-ui/react-icons';
import { Popover, PopoverContent, PopoverTrigger } from '@a2d24-ui/popover';

const notificationVersion = '3.4.0';
const lastVisibleDate = '2024-04-24T00:00:00.000+02:00';
const messageText = 'Click here to learn more about all the new features in Care@Work!';
const now = new Date().toISOString();

const SidebarNotifications = ({ open }) => {
    const [popoverOpen, setPopoverOpen] = useState(
        localStorage.getItem('lastNotificationDisplayed') !== notificationVersion
    );

    React.useEffect(() => {
        localStorage.setItem('lastNotificationDisplayed', notificationVersion);
    }, []);

    const onBellIconClick = () => {
        window.open('https://release.oh.netcare.digital/');
    };


    return (
        <>
            <Container>
            <PortalsDiv>
                    <Header>
                        Portals
                    </Header>
                <PortalLink href={process.env.REACT_APP_STANDARD_BANK_PORTAL} target={'_blank'}>Standard Bank</PortalLink>

                </PortalsDiv>
            </Container>
            {
                now <= lastVisibleDate ?
                    <Popover open={popoverOpen} onOpenChange={(newOpen) => setPopoverOpen(newOpen)}>
                        <PopoverTrigger asChild>
                            <Container
                                open={open}
                                onMouseEnter={() => {
                                    setPopoverOpen(true);
                                }}
                                onMouseLeave={() => {
                                    setPopoverOpen(false);
                                }}
                            >

                                <BellIcon width="35px" height="35px" color={'#1D3440'} onClick={onBellIconClick} />
                            </Container>
                        </PopoverTrigger>
                        <PopoverContent
                            showArrow={false}
                            side={'top'}
                            css={{
                                marginTop: '-10px',
                                border: 'none',
                                '&:hover': {
                                    border: 'none'
                                },
                                '&:focus': {
                                    border: 'none'
                                }
                            }}
                        >
                            <Text.Primary>{messageText}</Text.Primary>
                        </PopoverContent>
                    </Popover>
                :
                null
            }

        </>

    );
};

export default SidebarNotifications;

const Container = styled(ColumnFlex, {
    paddingLeft: '30px',
    paddingBottom: '30px',
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    variants: {
        open: {
            true: {
                bottom: '120px',
                marginBottom: '20px',
            },
        },
    },
});

const PortalsDiv = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    gap: '10px'
})

const Header = styled('div', {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#1D3440'
})


const PortalLink = styled('a', {
        fontWeight: 300,
        color: '#666666',
        cursor: 'pointer',
        textDecoration: 'none',
    }
)
