import React, { useContext } from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { AppConfigContext } from '../../App';
import { useStores } from '@a2d24/care-at-work-state/stores';

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 3,
    },
    addIcon: {
        marginRight: theme.spacing(1),
        zIndex: 3,
    },
}));

const check_if_fixed_site = (site_code) => {
    const split_site = site_code.split('/');
    const site = split_site[split_site.length - 2];
    return site.length >= 3 && site.substring(0, 3) === 'FNS';
};

const ManualBookingButton = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const { authStore } = useStores();
    const is_fixed_site = authStore.dsp_details?.is_fixed_site;

    return is_fixed_site ? (
        <div>
            <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                variant="extended"
                onClick={() => navigate('/manual_booking/create', { replace: true })}
            >
                <AddIcon className={classes.addIcon} />
                Add Booking
            </Fab>
        </div>
    ) : null;
};

export default ManualBookingButton;
