import React from 'react';
import { Field } from 'react-final-form';
import { Checkboxes } from 'mui-rff';

const MedicalHistoryCheckboxField = ({ id, data, name, optional = false }) => {
    const label = name;

    return (
        <Field name={id} placeholder={label}>
            {(fieldProps) => {
                return (
                    <Checkboxes
                        variant="standard"
                        label={fieldProps.placeholder}
                        name={fieldProps.input.name}
                        testid={id}
                        data={data}
                        required={optional === false}
                    />
                );
            }}
        </Field>
    );
};

export default MedicalHistoryCheckboxField;
