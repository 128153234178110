import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { copyConsultProceduresFromPreviousConsult } from '../Apis/Consult';
import { DialogActions, Typography } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';

const CommonConsultDialog = ({ open, handleDialogClose, consult_id }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const handleCopyProcedureValues = () => {
        copyConsultProceduresFromPreviousConsult(consult_id).then((r) => {
            enqueueSnackbar('Consult Procedures Copied', { variant: 'success' });
            handleDialogClose();
        });
    };
    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                Previous Consult Copy
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    Do you want to copy over Questionnaire/Examination responses from the last
                    consult at this practice
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={handleCopyProcedureValues}>
                    Copy Procedure Values
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        // minHeight: '60vh',
        // maxHeight: '60vh'
    },
}));

export default CommonConsultDialog;
