import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicForm from './DynamicForm';
import { Form } from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import PatientDetails from './PatientDetails';
import RegisterPatient from './RegisterPatient';
import { AppConfigContext } from '../App';

const PatientSearch = ({ config, props }) => {
    const [patient, setPatient] = useState(null);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const [showPatientForm, setShowPatientForm] = useState(false);
    const [initialFormValues, setInitialiFormValues] = useState({});
    const navigate = useNavigate();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);

    const handleSearch = (values) => {
        Recycle.rpcPostV2({
            controller: 'PatientController',
            method: 'search_patient',
            kwargs: { identifier_type: values.identifier_type, identifier: values.identifier },
        })
            .then((res) => {
                setPatient(res);
                setShowPatientDetails(true);
            })
            .catch((err) => {
                setInitialiFormValues(values);
                setShowPatientForm(true);
                // console.log(err.response);
            });
    };

    const handleArrivePatient = () => {
        appConfig.setState({ ...appConfig, currentBookingID: props.booking.booking_id });
        navigate(
            `/booking/${props.booking.booking_id}/consent/${patient.primary_identifier.value}`
        );
        // if (!('accepted_terms_and_conditions' in patient) || patient['accepted_terms_and_conditions'] === false) {
        //     appConfig.setState({...appConfig, currentBookingID: props.booking.booking_id});
        //     navigate(`/booking/${props.booking.booking_id}/consent/${patient['patient_identifier']}`);
        //     return
        // }
        // Recycle.rpcPostV2({
        //     controller: "BookingController",
        //     method: "update_booking_status",
        //     args: ["arrived", props.booking.booking_id]
        // }).then((res) => {
        //     Recycle.rpcPostV2({
        //         controller: "ConsultController",
        //         method: "create_consult",
        //         args: [appConfig.user.dsp, props.booking.booking_id, patient.identifier_type, patient.identifier]
        //     }).then((res) => {
        //         navigate('/dashboard');
        //     }).catch((err) => {
        //     })
        // })
    };

    const handleUpdate = () => {
        setInitialiFormValues(patient);
        setShowPatientDetails(false);
        setShowPatientForm(true);
    };

    const renderSearchForm = () => (
        <DynamicForm
            onSubmit={handleSearch}
            config={{ fields: searchForm, defaultValues: {} }}
            actions={
                [
                    // <Button variant="contained" color="primary" type="submit" onClick={() => console.log("button clicked")}>Test</Button>
                ]
            }
        />

        // <Form
        //     onSubmit={handleSearch}
        //     render={({ handleSubmit, form, submitting, pristine, values }) => {
        //         return <DynamicForm config={{ fields: searchForm[0].fields, onSubmit: handleSubmit }} >
        //             <div className={classes.buttonContainer}>
        //                 <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>Proceed</Button>
        //             </div>
        //         </DynamicForm>
        //     }}
        // />
    );

    const renderPatientDetails = () => (
        <React.Fragment>
            <PatientDetails props={{ patient }} />
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.updateButton}
                    variant="outlined"
                    color="primary"
                    onClick={handleUpdate}
                >
                    Update
                </Button>
                <Button variant="contained" color="primary" onClick={handleArrivePatient}>
                    Confirm
                </Button>
            </div>
        </React.Fragment>
    );

    const renderRegisterPatient = () => (
        <RegisterPatient props={props} config={{ defaultValues: initialFormValues }} />
    );

    const render = () => {
        if (showPatientDetails) {
            return renderPatientDetails();
        } else if (showPatientForm) {
            return renderRegisterPatient();
        } else {
            return renderSearchForm();
        }
    };

    return <React.Fragment>{render()}</React.Fragment>;
};

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    updateButton: {
        marginRight: theme.spacing(2),
    },
}));

const searchForm = {
    id: 'PATIENT_SEARCH',
    name: 'Search Patient',
    groups: [
        {
            id: 'part_1',
            name: 'Personal Particulars of Applicant',
            fields: [
                {
                    id: 'identifier_type',
                    name: 'Identity Type',
                    type: 'radio',
                    default: 'None',
                    optional: false,
                    display_view: 'radio',
                    render_condition: [],
                    calculation_precondition: [],
                    calculation: [],
                    options: [
                        {
                            id: 'NNZAF',
                            name: 'ID Number',
                        },
                        {
                            id: 'PPN',
                            name: 'Passport Number',
                        },
                    ],
                },
                {
                    id: 'identifier',
                    name: 'ID Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    validation: ['valid_said', 'get', 'identifier'],
                    render_condition: ['=', 'get', 'identifier_type', 'NNZAF'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
                {
                    id: 'identifier',
                    name: 'Passport Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    render_condition: ['=', 'get', 'identifier_type', 'PPN'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
            ],
        },
    ],
};

export default PatientSearch;
