//conditions is an array of Conditions
export const updatePlanOnChange = (plans, updatedPlan, conditions) => {
    return plans.map((plan) => {
        let conditionOutput = true;
        conditions.forEach((condition) => {
            if (condition.isEqual) {
                conditionOutput =
                    conditionOutput &&
                    plan[condition.attribute] === updatedPlan[condition.attribute];
            } else {
                conditionOutput =
                    conditionOutput &&
                    plan[condition.attribute] !== updatedPlan[condition.attribute];
            }
        });
        if (conditionOutput) {
            return updatedPlan;
        } else {
            return plan;
        }
    });
};
