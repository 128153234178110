import { useStores } from '@a2d24/care-at-work-state/stores';
import useDeadpollList from '@a2d24/care-at-work-core/Deadpoll/useDeadpollList';
import { useState } from 'react';
import AppointmentsCalendar from '../AppointmentsCalendar';
import BookingsTable from '../BookingsTable';
import moment from 'moment';

const isOnDate = (date, referenceDate) => {
    return new Date(date).toDateString() === new Date(referenceDate).toDateString();
};

const AppointmentCalendarTable = ({ props }) => {
    const { authStore } = useStores();
    const deadpollListId = props.deadpoll_list_id;
    const {
        data: deadpollBookings,
        loading: isLoadingBookings
    } = useDeadpollList(`${authStore.occhealthPracticeId}#${deadpollListId}`);
    const [filterDate, setFilterDate] = useState(new Date(new Date().toDateString()));
    const filteredBookings = (deadpollBookings ?? []).filter(
        booking => {
            return isOnDate(booking.scheduled_at, filterDate);
        });
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '30px'
        }}>
            <AppointmentsCalendar props={
                {
                    bookings: deadpollBookings,
                    onSelectEvent: (event) => {
                        setFilterDate(new Date(event.start.toDateString()));

                    }
                }
            } />
            <div style={{ width: '100%' }}>
                <BookingsTable props={{
                    bookings: filteredBookings,
                    title: moment(filterDate).format("DD MMMM YYYY")
                }} />
            </div>
        </div>
    );
};

export default AppointmentCalendarTable;

