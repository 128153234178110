import styles from './styles';
import { consultTypeMapping } from './Mappings/ConsultTypeMapping';

export const RecommendedMedicalTypeCell = ({ rowData }) => {
    const classes = styles();
    return (
        <div className={classes.recommendedCellDiv}>{consultTypeMapping[rowData.consult_type]}</div>
    );
};

export const AlternativesMedicalTypeCell = ({ rowData }) => {
    const classes = styles();
    return (
        <div className={classes.alternativesCellDiv}>
            {consultTypeMapping[rowData.consult_type]}
        </div>
    );
};
