import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import TextsmsIcon from '@mui/icons-material/Textsms';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import StepConnector from '@mui/material/StepConnector';
import styled from 'styled-components';
import MaterialPaper from '@mui/material/Paper';
import Paper from '@mui/material/Paper';

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

export function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <TabletAndroidIcon />,
        2: <TextsmsIcon />,
        3: <VerifiedUserIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export const ContainerPaper = styled(MaterialPaper)`
    display: grid;
    background-color: white;
    padding: 20px 30px 20px 30px;
    margin: 5px;
    grid-gap: 5px;
`;
export const SMSTextContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
`;
export const ManualContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
`;
export const GreyPaperContainer = styled(Paper)`
    background-color: #f8f3f3;
    margin: 10px 30px;
`;
