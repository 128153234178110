import React from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';

const ProcedureDetailsCard = ({ stock_option_selected, path, icon, flip }) => {
    const navigate = useNavigate();
    const classes = useStyles({ flip: flip });

    const handleRouting = () => {
        navigate(`/stock-management/${path}`);
    };

    // const heading = stock_option_selected.charAt(0).toUpperCase() + stock_option_selected.slice(1).replace(/-/g, " ")

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <Icon>{icon}</Icon>
            </div>

            <div className={classes.detailsContainer}>
                <div>
                    <div className={classes.procedureText}>{stock_option_selected}</div>
                </div>
            </div>

            <Button className={classes.button} color="primary" onClick={handleRouting}>
                SELECT
            </Button>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        height: '100%',
        gridTemplateAreas: '"icon details details" "button button button"',
        gridTemplateRows: '1fr auto',
        gridTemplateColumns: '2fr 8fr',
    },
    iconContainer: (props) => {
        let flip = 'scaleX(1)';
        if (props?.flip) {
            flip = 'scaleX(-1)';
        }
        return {
            backgroundColor: '#bee2ff',
            transform: flip,
            color: '#293873',
            gridArea: 'icon',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
    },
    detailsContainer: {
        gridArea: 'details',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    procedureText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    button: {
        gridArea: 'button',
        borderTop: '1px solid #979797',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        height: '50px',
    },
}));

export default ProcedureDetailsCard;
