import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchMedicalHistory = (patient_id) => {
    return axios
        .post(`${API_URL}/MedicalHistoryController/get_medical_history_by_patient`, {
            patient_id: patient_id,
        })
        .then((r) => r.data);
};

export const fetchVaccinationHistory = (patient_id) => {
    return axios
        .post(`${API_URL}/MedicalHistoryController/get_vaccination_history_by_patient`, {
            patient_id: patient_id,
        })
        .then((r) => r.data);
};
