import React from 'react';
import { Field } from 'react-final-form';
import { RFFDatePicker } from '../../modules/react-final-pickers';
import { isAfter, isBefore, isValid, parseISO } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import RffFieldLayout from './RffField';

const useStyles = makeStyles((theme) => ({
    pickerInput: {
        marginBottom: '1rem',
    },
}));

const AFTER_DATE = parseISO('1900-01-01T00:00:00Z');

const dateValidator =
    (errorMessage, { after, before }) =>
    (value) => {
        let valid = isValid(value);
        valid = valid && (after ? isAfter(value, after) : true);
        valid = valid && (before ? isBefore(value, before) : true);
        return valid ? undefined : errorMessage;
    };

const RffDateField = ({ id, name, optional, unit, isDisabled = false, ...rest }) => {
    const classes = useStyles();
    const [openedPickers, setOpenedPickers] = React.useState({});

    const onOpenPickerChange = (name, value) =>
        setOpenedPickers((prev) => ({
            ...prev,
            [name]: value,
        }));

    const label = name;

    return (
        <RffFieldLayout>
            <Field
                name={id}
                component={RFFDatePicker}
                validate={dateValidator('A valid date is required', {
                    after: AFTER_DATE,
                    before: parseISO(new Date().toISOString()),
                })}
                label={label}
                inputFormat="dd/MM/yyyy"
                mask="__/__/____"
                disableMaskedInput={false}
                InputProps={{
                    fullWidth: true,
                    className: classes.pickerInput,
                    testid: id,
                }}
                showTodayButton={true}
                disabled={isDisabled}
                open={openedPickers['start_date'] || false}
                onOpen={() => onOpenPickerChange('start_date', true)}
                onClose={() => onOpenPickerChange('start_date', false)}
                {...rest}
            />
        </RffFieldLayout>
    );
};

export default RffDateField;
