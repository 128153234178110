import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Fab } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileSaver from 'file-saver';
import { DownloadFromUrl } from '@a2d24/downloader/helpers';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'grid',
        marginTop: 'auto',
        marginBottom: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
    },

    image: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100vw - 180px)',
        maxHeight: 'calc(100vh - 180px)',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ImageViewer = ({
    title,
    setClose,
    open,
    isBase64Image = true,
    imgSrc = null,
    data = null,
    imageType = null,
}) => {
    const classes = useStyles();
    const handleClose = () => {
        setClose();
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <TransformWrapper
            // initialScale={0.5}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {title}
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => resetTransform()}
                                    aria-label="reset"
                                    size="large"
                                >
                                    <RestartAltIcon />
                                </IconButton>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => zoomOut()}
                                    aria-label="zoomout"
                                    size="large"
                                >
                                    <ZoomOutIcon />
                                </IconButton>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => zoomIn()}
                                    aria-label="zoomin"
                                    size="large"
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <div className={classes.imageContainer}>
                            <TransformComponent>
                                <img
                                    className={classes.image}
                                    src={
                                        isBase64Image
                                            ? `data:image/${imageType};base64,${data}`
                                            : imgSrc
                                    }
                                    alt="image not available"
                                />
                            </TransformComponent>
                        </div>
                    </>
                )}
            </TransformWrapper>
            <Fab
                style={{ position: 'fixed', right: '30px', bottom: '60px' }}
                onClick={() => {
                    if (isBase64Image) {
                        var blob = base64toBlob(data, `image/${imageType}`);
                        FileSaver.saveAs(blob, `${title}.${imageType}`);
                    } else {
                        DownloadFromUrl(imgSrc, title, {
                            headers: {
                                'Cache-Control': 'no-cache',
                            },
                        });
                    }
                }}
                color="primary"
                aria-label="download"
            >
                <CloudDownloadIcon />
            </Fab>
        </Dialog>
    );
};

export default ImageViewer;

const base64toBlob = function (b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
