import React from 'react';
import StockTake from '../StockManagement/StockTake';
import { getCurrentStockItems, saveStockTake } from '../../StockManagerConfig';

const StockTakeBase = ({ config, props }) => {
    return (
        <>
            <StockTake getCurrentStockItems={getCurrentStockItems} saveStockTake={saveStockTake} />
        </>
    );
};

export default StockTakeBase;
