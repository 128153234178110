import React from 'react';
import get from 'lodash.get';
import Widget from './index';
import { PaperContainer } from './Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const Conditional = ({ props, config }) => {
    const navigate = useNavigate();

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        const value = get(props, props.conditionParameter, false);
        const operator = props.conditionOperator;
        const conditionValue = props.conditionValue;

        let render = false;
        if (operator === '==') {
            render = value === conditionValue;
        } else if (operator === '!=') {
            render = value !== conditionValue;
        } else if (operator === 'defined') {
            render = value !== undefined && value !== null;
        }

        setShouldRender(render);
    });

    if (!shouldRender || !config.widgets) {
        if (!props.message) return null;

        return (
            <PaperContainer>
                <h3>{props.message || 'Not rendering'}</h3>

                {props.link && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => navigate(props.link.to)}
                    >
                        {props.link.text}
                    </Button>
                )}
            </PaperContainer>
        );
    }
    return config.widgets.map((widget) => <Widget config={widget} props={props} key={widget.id} />);
};

export default Conditional;
