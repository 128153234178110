const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class DifferedSocket {
    constructor(
        url,
        onChange,
        onMessage,
        onDisconnect,
        onError,
        sessionId,
        token = null,
        parameters = {}
    ) {
        this.url = url;
        this.onChange = onChange;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
        this.onError = onError;
        this.socket = null;
        this.token = token;
        this.sessionId = sessionId;
        this.connectionInProgress = null;
        this.parameters = parameters;
    }

    registerHandlers = (resolve, reject) => {
        let self = this;
        const onError = (event) => {
            self.connectionInProgress = null;
            self.onChange(false);
            self.onError(event);
            if (reject) reject('Error: ', event);
        };

        this.socket.addEventListener('error', onError);

        this.socket.onopen = (event) => {
            self.onChange(true);
            if (resolve) resolve(true);
        };

        this.socket.onclose = (event) => {
            self.connectionInProgress = null;
            self.socket.removeEventListener('error', onError);
            self.onChange(false);
            if (reject) reject('Connection closed');
            self.onDisconnect();
        };

        this.socket.onmessage = (event) => {
            self.onMessage(JSON.parse(event.data));
        };
    };

    asyncConnect = async () => {
        let self = this;

        if (this.connectionInProgress) return this.connectionInProgress;

        this.connectionInProgress = new Promise(function (resolve, reject) {
            if (
                self.socket &&
                (self.socket.readyState === SOCKET_OPEN ||
                    self.socket.readyState === SOCKET_CONNECTING)
            ) {
                reject('Not connecting since connection in progress');
            }

            let url = `${self.url}?sessionId=${self.sessionId}`;

            if (self.token) url += `&token=${self.token}`;

            Object.keys(self.parameters).forEach((key) => {
                url += `&${key}=${self.parameters[key]}`;
            });

            if (!self.socket) {
                self.socket = new WebSocket(url);
                self.registerHandlers(resolve, reject);
            }
        });
        return this.connectionInProgress;
    };

    connect = () => {
        if (
            this.socket &&
            (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)
        ) {
            console.log('Not connecting since connection in progress');
            return;
        }

        let url = `${this.url}?sessionId=${this.sessionId}`;

        if (this.token) url += `&token=${this.token}`;

        Object.keys(this.parameters).forEach((key) => {
            url += `&${key}=${this.parameters[key]}`;
        });

        if (!this.socket) {
            this.socket = new WebSocket(url);
            this.registerHandlers();
        }
    };

    sendMessage = (message) => {
        if (this.socket && this.socket.readyState === SOCKET_OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('Cannot emit socket messages. WebSocket not connected.');
        }
    };

    disconnect = () => this.socket?.close();
}

export class DeadpollSocket {
    constructor(onChange, onMessage, onDisconnect, onError) {
        this.onChange = onChange;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
        this.onError = onError;
        this.socket = null;
        this.shouldConnect = false;
        this.timeout = 125;
        this.url = null;
    }

    _reconnectIfRequired = () => {
        if (!this.shouldConnect) return;
        const self = this;
        setTimeout(() => {
            if (!this.shouldConnect) return;
            this.connect(self.url);
        }, Math.min(5000, (this.timeout += this.timeout)));
    };

    connect = (url) => {
        if (!url) return;
        const self = this;
        this.shouldConnect = true;
        this.url = url;

        if (
            this.socket &&
            (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)
        )
            return;

        this.socket = new WebSocket(url);

        this.socket.onopen = (event) => {
            this.timeout = 125;
            self.onChange(true);
        };

        this.socket.onerror = (event) => {
            self.onChange(false);
            self.onError(event);
            self._reconnectIfRequired();
        };

        this.socket.onclose = (event) => {
            self.onChange(false);
            self.onDisconnect();
            self._reconnectIfRequired();
        };

        this.socket.onmessage = (event) => {
            self.onMessage(JSON.parse(event.data));
        };
    };

    sendMessage = (message) => {
        if (this.socket && this.socket.readyState === SOCKET_OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('Cannot emit socket messages. WebSocket not connected.');
        }
    };

    disconnect = () => {
        this.socket.close();
        this.shouldConnect = false;
        this.url = null;
    };
}
