import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import ProcedureDetailsCard from './ProcedureDetailsCard';
import ProcedureDetailsAccordion from './ProcedureDetailsAccordion';

const ConsultProcedureDetails = ({
    config,
    props: { procedures, consult_id, consult_status, editable = true },
}) => {
    const classes = useStyles();

    const renderProcedureCards = () => {
        return (
            <div className={classes.container}>
                {procedures.map((procedure) => (
                    <Paper
                        style={{ order: procedure.procedure_order }}
                        key={procedure.procedure_id}
                        className={classes.detailsContainer}
                    >
                        <ProcedureDetailsCard procedure={procedure} consult_id={consult_id} />
                    </Paper>
                ))}
            </div>
        );
    };

    const renderProcedureAccordions = () => {
        return (
            <div>
                {procedures.map((procedure) => (
                    <ProcedureDetailsAccordion
                        key={procedure.procedure_id}
                        procedure={procedure}
                        consult_id={consult_id}
                        editable={editable}
                    />
                ))}
            </div>
        );
    };

    return (
        <React.Fragment>
            {consult_status === 'awaiting_omp' || consult_status === 'completed'
                ? renderProcedureAccordions()
                : renderProcedureCards()}
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: theme.spacing(3),
    },
    detailsContainer: {
        height: '138px',
    },
}));

export default ConsultProcedureDetails;
