import React from 'react';

import Uppy, { UIPlugin } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import DropTarget from '@uppy/drop-target';
import { Dashboard as DashboardReact } from '@uppy/react';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import '@uppy/drop-target/dist/style.min.css';
import './uppyCssOverrides.css';

class AfterUpload extends UIPlugin {
    // Custom plugin for @a2d24/uppy to facilitate extending its behaviour

    constructor(uppy, opts) {
        const defaultOptions = {
            onAfterUpload: () => {},
        };
        super(uppy, { ...defaultOptions, ...opts });

        this.id = this.opts.id || 'AfterUpload';
        this.type = 'post-processor'; // This value can be anything
    }

    afterUpload = (fileIDs) => {
        const promises = fileIDs.map(async (fileID) => {
            const file = this.uppy.getFile(fileID);
            if (!file.meta.key)
                // Caused by a timeout on get_presigned_url
                return null;
            const result = await this.opts.onAfterUpload(file, this);
            this.uppy.emit('postprocess-complete', this.uppy.getFile(fileID));
            return result;
        });
        return Promise.all(promises.filter((p) => p !== null));
    };

    install() {
        this.uppy.addPostProcessor(this.afterUpload);
    }

    uninstall() {
        this.uppy.removePostProcessor(this.afterUpload);
    }
}

const UppyDashboard = ({
    initial,
    options = {},
    s3PreSignAdapter,
    onUploadedFilesChanged,
    onFilesChanged,
    onFileRemove,
    uppyForm,
    maxFileSize=5,
    onAfterUpload = () => {},
    ...props
}) => {
    const [uploadedFiles, setUploadedFiles] = React.useState({});

    // Possibly use for onChange use cases?
    React.useEffect(() => {
        onUploadedFilesChanged && onUploadedFilesChanged(uploadedFiles);
    }, [uploadedFiles]);


    const uppy = React.useMemo(() => {
        return new Uppy(options)
            .use(Dashboard, {})
            .use(AwsS3, {
                getUploadParameters: s3PreSignAdapter,
            })
            .use(DropTarget, {
                target: document.body,
            })
            .use(AfterUpload, {
                onAfterUpload: onAfterUpload,
            })
            .on('file-removed', async (file, data) => {
                setUploadedFiles(({ [file.id]: fileToRemove, ...files }) => files);
                if (onFileRemove && file?.meta?.key) {
                    const success = await onFileRemove(file.meta.key);
                    if (!success) {
                        engine.broadcastMessage('snackbar', {
                            type: 'toastError',
                            message: 'Failed to remove document. Please refresh page and try again',
                        });
                        uppy.addFile(file);
                    }
                }
            })
            .on('file-added', (file, data) => {
                if(props.metaFields && props.metaFields.length >0) {
                  setTimeout(() => {
                    uppy.getPlugin('react:Dashboard').toggleFileCard(true, file.id);
                  }, 0);
                }
            })
            .on('postprocess-complete', (file) => {
                uppy.setFileState(file.id, {
                    meta: {
                        ...file.meta,
                    },
                });
                setUploadedFiles((files) => ({ ...files, [file.id]: file }));
            })
            .on('dashboard:file-edit-complete', (file, ...rest) => {
                if (file) {
                    uppy.setFileState(file.id, {
                        meta: {
                            ...file.meta,
                            fileName: file.meta.name
                        },
                    });
                    uppy.upload(file);
                }
            });
    }, []); // Do not respond to prop updates after initial mount

    React.useEffect(() => {
        return () => uppy.close({ reason: 'unmount' });
    }, [uppy]);

    return (
        <DashboardReact
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            locale={{
                strings: {
                    myDevice: 'Click here to select a file',
                    browseFiles: 'browse',
                    dropPasteFiles:
                        `Drag and drop or %{browseFiles}\nto upload files from your computer \nMax file size: ${maxFileSize}MB`,
                    // dropPasteImportFiles: 'Drop files here, %{browseFiles} or import froms:',
                    editFile: 'Edit file',
                    // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
                    editing: 'Editing %{file}',
                    editFileWithFilename: 'Edit file %{file}',
                    saveChanges: 'Upload'
                },
            }}
            autoOpenFileEditor={true}
            {...props}
        />
    );
};

export default UppyDashboard;
