import React from 'react';

import { isValid, startOfDay, parseISO, isEqual, startOfMinute, startOfMonth } from 'date-fns';
import { DefaultInput, DesktopDateTimePicker, MobileDatePicker, MobileTimePicker } from './index';

const parseDate = (date) => {
    if (date instanceof Date) {
        return date;
    } else if (isValid(parseISO(date))) {
        return parseISO(date);
    }

    return null;
};

export const RFFDateTimePicker = ({ input, meta, InputProps, ...props }) => {
    const { onChange, value, ...restInput } = input;
    const [internalValue, setInternalValue] = React.useState(input.value);

    const handleChange = (value) => {
        setInternalValue(value || null);
        handleAccept(value);
    };

    const handleAccept = (value) => {
        if (isValid(value)) {
            onChange(startOfMinute(value));
        } else {
            onChange(undefined);
        }
    };

    React.useEffect(() => {
        const changedDate = parseDate(input.value);
        const requiredParsing = !(input.value instanceof Date);

        if (isValid(changedDate)) {
            if (!isEqual(changedDate, internalValue) || requiredParsing) {
                // Value was externally changed to a new valid value
                handleChange(changedDate);
            }
        } else {
            handleChange(undefined);
        }
    }, [input.value]);

    const hasError = !!(meta.error && meta.touched);

    return (
        <DesktopDateTimePicker
            value={internalValue || null}
            onChange={handleChange}
            onAccept={handleAccept}
            renderInput={DefaultInput}
            inputProps={restInput}
            InputProps={{
                error: hasError,
                helperText: hasError && meta.error,
                ...InputProps,
            }}
            {...props}
        />
    );
};

export const RFFDatePicker = ({ input, meta, InputProps, skipDay = false, ...props }) => {
    const { onChange, value, ...restInput } = input;
    const [internalValue, setInternalValue] = React.useState(parseDate(input.value));

    const handleChange = (value) => {
        setInternalValue(value || null);
        handleAccept(value);
    };

    const handleAccept = (value) => {
        if (isValid(value)) {
            onChange(skipDay ? startOfMonth(value) : startOfDay(value));
        } else {
            onChange(undefined);
        }
    };

    React.useEffect(() => {
        const changedDate = parseDate(input.value);
        const requiredParsing = !(input.value instanceof Date);

        if (isValid(changedDate)) {
            if (!isEqual(changedDate, internalValue) || requiredParsing) {
                // Value was externally changed to a new valid value
                handleChange(changedDate);
            }
        } else {
            handleChange(undefined);
        }
    }, [input.value]);

    const hasError = !!(meta.error && meta.touched);

    const config = skipDay
        ? {
              openTo: 'year',
              views: ['year', 'month'],
              inputFormat: 'MMM yyyy',
              mask: '___ ____',
          }
        : {};

    return (
        <MobileDatePicker
            value={internalValue}
            onChange={handleChange}
            onAccept={handleAccept}
            renderInput={DefaultInput}
            inputProps={restInput}
            minDate={parseISO('1900-01-01T00:00:00Z')}
            maxDate={parseISO('2099-12-31T00:00:00Z')}
            allowSameDateSelection={true}
            disableCloseOnSelect={false}
            InputProps={{
                error: hasError,
                helperText: hasError && meta.error,
                ...InputProps,
            }}
            componentsProps={{
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
              }}
            {...config}
            {...props}
        />
    );
};

export const RFFTimePicker = ({
    input,
    meta,
    InputProps: { variant = 'standard', size = 'small', ...restInputProps } = {},
    ...props
}) => {
    const { onChange, value, ...restInput } = input;
    const [internalValue, setInternalValue] = React.useState(parseDate(input.value));

    const handleChange = (value) => {
        setInternalValue(value || null);
        handleAccept(value);
    };

    const handleAccept = (value) => {
        if (isValid(value)) {
            onChange(startOfMinute(value));
        } else {
            onChange(undefined);
        }
    };

    React.useEffect(() => {
        const changedDate = parseDate(input.value);
        const requiredParsing = !(input.value instanceof Date);

        if (isValid(changedDate)) {
            if (!isEqual(changedDate, internalValue) || requiredParsing) {
                // Value was externally changed to a new valid value
                handleChange(changedDate);
            }
        } else {
            handleChange(undefined);
        }
    }, [input.value]);

    const hasError = !!(meta.error && meta.touched);

    return (
        <MobileTimePicker
            value={internalValue}
            onChange={handleChange}
            onAccept={handleAccept}
            inputProps={restInput}
            renderInput={DefaultInput}
            ampm={false}
            showTodayButton
            clearable
            todayText="Now"
            disableCloseOnSelect={false}
            cancelText={null}
            InputProps={{
                error: hasError,
                helperText: hasError && meta.error,
                variant,
                size,
                ...restInputProps,
            }}
            {...props}
        />
    );
};
