import { default as Grid } from '@mui/material/Unstable_Grid2';
import React from 'react';

const RffFieldLayout = ({ children }) => {
    return (
        <Grid
            wrap="nowrap"
            spacing={10}
            columnSpacing={'unset'}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item xs={8}>
                {children}
            </Grid>
        </Grid>
    );
};

export default RffFieldLayout;
