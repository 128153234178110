import { Button } from '@mui/material';
import styles from './styles';

const CellButton = ({ label, onClick }) => {
    const classes = styles();
    return (
        <div style={{ justifyContent: 'end' }}>
            <Button onClick={onClick} className={classes.cellButton} variant="contained">
                {label}
            </Button>
        </div>
    );
};

export default CellButton;
