import React from 'react';

const FieldError = ({ meta }) => {
    return (
        <div>
            {meta.error && meta.dirty && meta.modified && (
                <label
                    style={{
                        color: '#d29292',
                        fontSize: '12px',
                        display: 'block',
                        fontWeight: 400,
                        marginTop: '3px',
                        textAlign: 'left',
                    }}
                >
                    {meta.error}
                </label>
            )}
        </div>
    );
};

export default FieldError;
