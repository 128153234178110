import RffRadioButtonField from '../../ReactFinalFormFields/RffRadioButtonField';
import ConditionalField from '../../ReactFinalFormFields/RffConditionalWrapper';
import RffTextField from '../../ReactFinalFormFields/RffTextField';
import React from 'react';
import { evaluatePolishExpression } from '@a2d24/care-at-work-core/Recycle';

export const idTypeOptions = [
    {
        id: 'NNZAF',
        name: 'ID Number',
    },
    {
        id: 'PPN',
        name: 'Passport Number',
    },
];

const isValidSAID = (value) => {
    return evaluatePolishExpression(['valid_said', 'get', 'identifier'], { identifier: value })
        ? undefined
        : 'Invalid South African ID';
};

const IdentificationPage = ({ formValues }) => {
    return (
        <>
            <RffRadioButtonField
                id="identifier_type"
                name="Identity Type"
                options={idTypeOptions}
                optional={false}
            />
            <ConditionalField
                condition={(values) =>
                    values.identifier_type === idTypeOptions[1].id ||
                    values.identifier_type === idTypeOptions[0].id
                }
                fields={['identifier']}
            >
                <RffTextField
                    id="identifier"
                    name={
                        formValues.identifier_type === idTypeOptions[0].id
                            ? 'ID Number'
                            : 'Passport Number'
                    }
                    optional={false}
                    validate={(value, allValues) => {
                        if (allValues.identifier_type === idTypeOptions[0].id) {
                            return isValidSAID(value);
                        }
                        return null;
                    }}
                />
            </ConditionalField>
        </>
    );
};
export default IdentificationPage;
