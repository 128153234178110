import useStyles from '../styles';
import Button from '@mui/material/Button';
import React from 'react';
import { reFormat } from '../../utils';
import { deleteEventType, useServices } from '../../api';
import { useSnackbar } from 'notistack';

const getDurationFromSeconds = (seconds) => {
    let durationNum = 0;
    durationNum = seconds / 60;
    if (durationNum < 60) {
        return `${durationNum} min`;
    }
    durationNum = durationNum / 60;
    return `${durationNum} hr`;


};
const EventTypeView = ({ eventType }) => {
    const services = useServices();
    const unusedServices = (services.data ?? []).filter((service) => {
        return !(eventType?.meta_settings?.services ?? []).includes(service.value);
    });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    return (
        <div className={classes.EventTypeViewContainer}>
            <div className={classes.EventTypeName}>
                {eventType.name}
            </div>
            <ul className={classes.EventTypeServices}>
                {
                    (eventType?.meta_settings?.services ?? []).map(
                        (service, index) => <li key={index}>✓ {reFormat(service)}</li>
                    )
                }
                {
                    (unusedServices).map(
                        (service, index) => <li style={{ color: '#77858f' }}
                                                key={index}>☓ {reFormat(service.value)}</li>
                    )
                }

            </ul>
            <div className={classes.EventTypeAttributes}>
                <div className={classes.EventTypeAttribute}>
                    {`${getDurationFromSeconds(eventType.duration)}`}
                </div>
                <div className={classes.EventTypeAttribute}>
                    {`${eventType.limits.event_per_slot} slots`}
                </div>
                <div className={classes.EventTypeAttribute}>
                    {`${getDurationFromSeconds(eventType.limits.minimum_notice)} booking notice`}
                </div>
                <div className={classes.EventTypeAttribute}>
                    {`${getDurationFromSeconds(eventType.limits.cancellation_notice)} cancellation notice`}
                </div>
            </div>
            <Button
                onClick={() => {
                    deleteEventType({
                        calendar_id: eventType.calendar_id,
                        event_type_id: eventType.event_type_id
                    }).then(()=> {
                        window.location.reload();
                    }).catch(
                        ()=>{
                            enqueueSnackbar(`Failed to delete event type`, { variant: 'error' });
                        }
                    )
                }}
                style={{ margin: '5px' }}
                variant="contained"
                color={'primary'}
            >
                Delete
            </Button>
        </div>
    );
};

export default EventTypeView;
