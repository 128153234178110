import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import RffFieldLayout from './RffField';

const ReactSelectAdapter = ({ input, parentOnChange, ...rest }) => {
    const classes = useStyles();

    const { onChange, ...restInput } = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        const optionFromID = rest.options.find((o) => o.value === restInput.value);

        if (optionFromID !== undefined) {
            setSelectedOption(optionFromID);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options]);

    const internalOnChange = (e) => {
        onChange(e?.value !== undefined ? e?.value : null);
    };
    return (
        <>
            <div>
                <InputLabel size="small" shrink={true}>
                    {rest.label}
                </InputLabel>
                <Select
                    {...restInput}
                    {...rest}
                    styles={customStyles}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(newValue, actionMeta) => {
                        if (parentOnChange) {
                            parentOnChange(newValue);
                        }
                        internalOnChange(newValue);
                    }}
                    value={selectedOption}
                    isRequired
                />
                {
                    // Hack - Select does not support required
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={selectedOption?.value !== undefined ? selectedOption?.value : ''}
                        required={true}
                        // readOnly
                        onChange={() => {}}
                        testid={input.name}
                    />
                }
            </div>
        </>
    );
};

const RffReactSelectField = ({ id, name, options, optional = false, onChange = null }) => {
    const label = name;

    const mapped_options = options.map(({ id, name, disabled = false }) => ({
        label: name,
        value: id,
        isDisabled: disabled,
    }));

    return (
        <RffFieldLayout>
            <Field
                name={id}
                label={label}
                optional={optional}
                options={mapped_options}
                component={ReactSelectAdapter}
                parentOnChange={onChange}
                fullWidth
                isOptionDisabled={(option) => option.isDisabled === true}
            />
        </RffFieldLayout>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem',
        },
    };
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: state.isFocused ? '2px solid #000d1a' : '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            width: '100%',
            '&:hover': {
                borderBottom: state.isFocused ? '2px solid #000d1a' : '2px solid #000d1a',
            },
        };
    },
};

export default RffReactSelectField;
