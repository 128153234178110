import MaterialTable, { MTableBody, MTableToolbar } from '@material-table/core';
import { TableContainer } from '@mui/material';
import { RecommendedMedicalTypeCell } from './MedicalTypeCell';
import { RecommendedMedicalDescriptionCell } from './MedicalDescriptionCell';
import styles from './styles';
import CellButton from './CellButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { createConsult } from './Api/createConsult';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
const RecommendedSubTable = ({ booking, patient, data, activityId }) => {
    const classes = styles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleProceed = (rowData) => {
        createConsult(
            booking.booking_id,
            patient,
            rowData.tariff_code,
            rowData.medical_type,
            activityId
        )
            .then((res) => {
                enqueueSnackbar('Registration Successful', { variant: 'success' });
                navigate('/dashboard');
            })
            .catch((err) => {
                enqueueSnackbar('Failed to create consult', { variant: 'error' });
            });
    };
    const columns = [
        {
            field: 'consult_type',
            render: (rowData) => <RecommendedMedicalTypeCell rowData={rowData} />,
        },

        {
            field: 'medical_description',
            render: (rowData) => <RecommendedMedicalDescriptionCell rowData={rowData} />,
        },

        {
            field: 'action_proceed',
            render: (rowData) => (
                <CellButton label="Proceed" onClick={(event) => handleProceed(rowData)} />
            ),
        },
    ];

    return data.length > 0 ? (
        <MaterialTable
            title={<div className={classes.recommendedHeader}>Recommended Medical</div>}
            columns={columns}
            data={data}
            options={{
                showTitle: true,
                showEmptyDataSourceMessage: true,
                filtering: false,
                header: false,
                paging: false,
                search: false,
                selection: false,
                pageSize: data.length,
            }}
            components={{
                Container: (props) => (
                    <div>
                        <TableContainer {...props} className={classes.recommendedContainer} />
                    </div>
                ),
            }}
            h
        />
    ) : (
        <div>
            <>
                <div>No Recommended medicals found</div>
                <Button
                    onClick={() => navigate(`/dashboard`)}
                    sx={{
                        marginTop: '5px',
                        position: 'absolute',
                        right: 8,
                    }}
                    variant="contained"
                >
                    Go to Dashboard
                </Button>
            </>
        </div>
    );
};

export default RecommendedSubTable;
