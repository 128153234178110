import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import MedicalHistoryForm from './MedicalHistoryForm';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMedicalHistory, useVaccinationHistory } from '../../queries';

const MedicalHistory = ({ setClose, patient, consult_id, formRef }) => {
    const patient_id = patient.primary_identifier.value;
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const completedEmploymentHistoryItem = (item) => {
        const requiredFields = ["company_name", "job_description", "ppe", "start_date", "end_date", "hazard_exposure"];
        const itemKeys = Object.keys(item??{});
        if(requiredFields.every((requiredField)=>{ return itemKeys.includes(requiredField)})){
            return true
        }
        return false;
    }
    const completedIODItem = (item) => {
        const requiredFields = ["claim_finalised", "description", "date_of_injury", "claim_number"];
        const itemKeys = Object.keys(item??{});
        if(requiredFields.every((requiredField)=>{ return itemKeys.includes(requiredField)})){
            return true
        }
        return false;
    }

    const completedVaccinationItem = (item) => {
        const itemKeys = Object.keys(item??{});
        if(!itemKeys.includes('vaccination')){
            return false;
        }
        else if((!itemKeys.includes('vaccination_date_unknown') || item?.vaccination_date_unknown === false) && !itemKeys.includes('vaccination_date')){
            return false;
        }
        return true;
    }
    const validate = (formValues) => {
        const errors = {}
        const employmentHistoryItems = formValues.employment_history??[];
        const IODItems = formValues.iod??[];
        const vaccinationItems = formValues.vaccination_history??[];
        if(employmentHistoryItems.length>0 && !employmentHistoryItems.every(completedEmploymentHistoryItem)){
            errors.general = "Incomplete employment history item";
        }
        else if(IODItems.length>0 && !IODItems.every(completedIODItem)){
            errors.general = "Incomplete iod item";
        }
        else if(vaccinationItems.length>0 && !vaccinationItems.every(completedVaccinationItem)){
            errors.general = "Incomplete vaccination history item";
        }
        return errors;
    };
    const medicalHistoryQuery = useMedicalHistory(patient_id);
    const vaccinationHistoryQuery = useVaccinationHistory(patient_id);
    if (medicalHistoryQuery.isLoading || vaccinationHistoryQuery.isLoading) {
        return <span>Loading...</span>;
    }

    if (medicalHistoryQuery.isError) {
        return <span>Could not load history: {medicalHistoryQuery?.error?.message}</span>;
    }
    else if(vaccinationHistoryQuery.isError) {
        return <span>Could not load history: {vaccinationHistoryQuery?.error?.message}</span>;
    }

    return (
        <div style={{ display: 'contents', position: 'relative', overflow: 'hidden' }}>
            <Form
                initialValues={{...medicalHistoryQuery.data.medical_history,  vaccination_history: (vaccinationHistoryQuery.data?.vaccination_history)??[]}}
                mutators={{ ...arrayMutators }}
                onSubmit={(values) => {
                    Promise.all(
                        [
                            Recycle.rpcPostV2(
                                {
                                    controller: 'MedicalHistoryController',
                                    method: 'save_medical_history',
                                    kwargs: {
                                        patient_id,
                                        consult_id: consult_id,
                                        medical_history: values,
                                        version: medicalHistoryQuery.data.version || 0,
                                    },
                                },
                                false
                            ),
                            Recycle.rpcPostV2(
                                {
                                    controller: 'MedicalHistoryController',
                                    method: 'save_vaccination_history',
                                    kwargs: {
                                        patient_id,
                                        consult_id: consult_id,
                                        vaccination_history: values.vaccination_history,
                                        version: vaccinationHistoryQuery.data?.version || 0,
                                    },
                                },
                                false
                            )
                        ]
                    ).then((responses) => {
                        queryClient.invalidateQueries(['medicalHistory', patient_id]);
                        queryClient.invalidateQueries(['vaccinationHistory', patient_id]);
                        enqueueSnackbar('Medical History Saved', { variant: 'success' });
                        setClose();

                    }).catch((error)=>{
                        const api_method = (error?.config?.url??"").split('/').slice(-1)[0];
                        if(api_method === "save_medical_history"){
                            enqueueSnackbar('Failed to save Medical History', { variant: 'error' })
                        }
                        else if(api_method === "save_vaccination_history"){
                            enqueueSnackbar('Failed to save Vaccination History', { variant: 'error' })
                        }
                        else{

                        }

                    }
                    )
                }}
                validate={validate}
                component={MedicalHistoryForm}
                formRef={{ formRef }}
            />
        </div>
    );
};

export default MedicalHistory;
