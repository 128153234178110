import { Button, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from '@mui/icons-material/Delete';
import MedicalHistoryDatePicker from './MedicalHistoryDatePicker';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {vaccinations} from './Vaccinations'
import MedicalHistoryReactSelect from './MedicalHistoryReactSelect';
import MedicalHistoryCheckboxField from './MedicalHistoryCheckboxField';

const VaccinationHistoryFieldArray = ({ mutators: { push } }) => {
    const renderNoEntries = () => {
        return (
            <>
                <div style={{ margin: '3ch' }}>
                    <Typography variant="h8">
                        There are no recorded Vaccination History Items{' '}
                    </Typography>
                </div>
            </>
        );
    };

    return (
        <div>
            <div>
                <FieldArray name="vaccination_history">
                    {({ fields }) =>
                        fields.length === 0
                            ? renderNoEntries()
                            : fields.map((name, index) => {
                                console.log(name);
                                return (
                                    <Paper elevation={2} key={index}>
                                        <div
                                            style={{
                                                margin: '2ch',
                                                display: 'grid',
                                                columnGap: '1ch',
                                                gridTemplateColumns: '0.9fr 0.1fr',
                                                rowGap: '2ch',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingTop: '20px',
                                                    display: 'grid',
                                                    columnGap: '1ch',
                                                    gridTemplateColumns: '3fr 3fr 1.8fr',
                                                    rowGap: '1ch'
                                                }}
                                            >
                                                <MedicalHistoryReactSelect
                                                    name={'Vaccination'}
                                                    id={`${name}.vaccination`}
                                                    options={
                                                        vaccinations.map(
                                                            (vaccination) => {
                                                                return {
                                                                    id: vaccination,
                                                                    name: vaccination
                                                                };
                                                            }
                                                        )
                                                    }
                                                />
                                                {
                                                    (fields.value[index]?.vaccination_date_unknown ?? false) == false ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <MedicalHistoryDatePicker
                                                                name={'Vaccination Date'}
                                                                id={`${name}.vaccination_date`}
                                                                description={'Select a vaccination date'}
                                                                optional={false}
                                                            />
                                                        </div>
                                                        :
                                                        null

                                                }
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MedicalHistoryCheckboxField
                                                        id={`${name}.vaccination_date_unknown`}
                                                        description={'Select a vaccination date'}
                                                        optional={true}
                                                        data={{
                                                            label: 'Date unknown',
                                                            value: false
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <IconButton
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: 'transparent'
                                                }}
                                                onClick={() => fields.remove(index)}
                                                aria-label='delete'
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Paper>
                                )
                            })
                    }
                </FieldArray>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="text"
                    onClick={() => {
                        push('vaccination_history', undefined);
                    }}
                >
                    ADD VACCINATION HISTORY
                </Button>
            </div>
        </div>
    );
};

export default VaccinationHistoryFieldArray;
