import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';

const styles = makeStyles((theme) => ({
    MainHeadingDiv: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    SubHeadingDiv: {
        fontWeight: 'bold',
    },
    ItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    HorizontalCentreDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    FlexColumnContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '5px',
    },
    InlinesItemContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
    },
    ValuesDiv: {
        display: 'flex',
        fontWeight: 'normal',
        paddingLeft: '5px',
    },
    DemographicsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        fontSize: '1rem',
        padding: '10px',
        border: '1px solid #5A5A5A',
        borderStyle: 'none none solid none',
        fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
            "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
            '    sans-serif',
    },
    SummaryContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 8fr',
    },
    SummaryTableOfContents: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'pink',
        border: '1px solid #5A5A5A',
        borderStyle: 'none solid none none',
    },
    SummaryContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    SummaryFullContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.primary.dark,
    },
    SectionContainer: {
        display: 'flex',
        fontSize: '0.95rem',
        flexDirection: 'column',
        gap: '15px',
        padding: '10px',
        border: '1px solid #D3D3D3',
        borderStyle: 'none none solid none',
        paddingBottom: '30px',
    },
    PHCVisitsContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr 4fr',
    },
    PHCColumnContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    NoInformationDiv: {
        fontSize: '0.80rem',
        fontWeight: '100',
        paddingLeft: '5px',
    },
}));

export default styles;
