import styles from './styles';
import { medicalTypeMapping } from './Mappings/MedicalTypeMapping';
//NOTE: medical_name is not correct. would have alter the backend model
export const RecommendedMedicalDescriptionCell = ({ rowData }) => {
    const classes = styles();
    return (
        <div className={classes.recommendedCellDiv}>
            {`${medicalTypeMapping[rowData.medical_type]}: ${rowData.medical_name}`}
        </div>
    );
};

export const AlternativesMedicalDescriptionCell = ({ rowData }) => {
    const classes = styles();
    return (
        <div className={classes.alternativesCellDiv}>
            {`${medicalTypeMapping[rowData.medical_type]}: ${rowData.medical_name}`}
        </div>
    );
};
