import { Button, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from '@mui/icons-material/Delete';
import MedicalHistoryDatePicker from './MedicalHistoryDatePicker';
import MedicalHistoryTextboxField from './MedicalHistoryTextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const EmploymentHistoryFieldArray = ({ mutators: { push } }) => {
    const renderNoEntries = () => {
        return (
            <>
                <div style={{ margin: '3ch' }}>
                    <Typography variant="h8">
                        There are no recorded Employment History Items{' '}
                    </Typography>
                </div>
            </>
        );
    };

    return (
        <div>
            <div>
                <FieldArray name="employment_history">
                    {({ fields }) =>
                        fields.length === 0
                            ? renderNoEntries()
                            : fields.map((name, index) => (
                                  <Paper elevation={2} key={index}>
                                      <div
                                          style={{
                                              margin: '2ch',
                                              display: 'grid',
                                              columnGap: '1ch',
                                              gridTemplateColumns: '0.9fr 0.1fr',
                                              rowGap: '1ch',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  paddingTop: '20px',
                                                  display: 'grid',
                                                  columnGap: '1ch',
                                                  gridTemplateColumns: '1fr 1fr',
                                                  rowGap: '1ch',
                                              }}
                                          >
                                              <MedicalHistoryDatePicker
                                                  name={'Start Date'}
                                                  id={`${name}.start_date`}
                                                  description={'Start Date'}
                                              />
                                              <MedicalHistoryDatePicker
                                                  name={'End Date'}
                                                  id={`${name}.end_date`}
                                                  description={'End Date'}
                                              />
                                              <MedicalHistoryTextboxField
                                                  name={'Company Name'}
                                                  id={`${name}.company_name`}
                                              />

                                              <MedicalHistoryTextboxField
                                                  name={'Job Description'}
                                                  id={`${name}.job_description`}
                                              />

                                              <MedicalHistoryTextboxField
                                                  name={'Hazard Exposure'}
                                                  id={`${name}.hazard_exposure`}
                                              />

                                              <MedicalHistoryTextboxField
                                                  name={'PPE'}
                                                  id={`${name}.ppe`}
                                              />
                                          </div>
                                          <IconButton
                                              style={{
                                                  cursor: 'pointer',
                                                  backgroundColor: 'transparent',
                                              }}
                                              onClick={() => fields.remove(index)}
                                              aria-label="delete"
                                          >
                                              <DeleteIcon />
                                          </IconButton>
                                      </div>
                                  </Paper>
                              ))
                    }
                </FieldArray>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="text"
                    onClick={() => {
                        push('employment_history', undefined);
                    }}
                >
                    ADD EMPLOYMENT HISTORY
                </Button>
            </div>
        </div>
    );
};

export default EmploymentHistoryFieldArray;
