import React, { createContext, useEffect, useState } from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import Base from './Base';
import AppTheme from './theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { observer } from 'mobx-react-lite';
import LoadingScreen from '@a2d24-ui/loading-screen';
import { ErrorBoundary } from './Components/ErrorBoundary';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { Navigate, Route } from 'react-router-dom';
import './index.css';
const queryClient = new QueryClient();
const theme = createTheme(AppTheme);
export const AppConfigContext = createContext({});
export const SharedStateContext = createContext({});
const Logo = engine.providers('Logo', () => () => null)();
const App = observer(() => {
    const [timedOut, setTimedOut] = React.useState(false);
    const { authStore, mfaStore } = useStores();
    React.useEffect(() => {
        const timer = setTimeout(() => setTimedOut(true), 15 * 1000);
        return () => clearTimeout(timer);
    });
    const [appConfig, setAppConfig] = useState({
        setState: (state) => {
            setAppConfig(state);
        },
        authenticated: false,
        sites: [],
        pages: [],
        menu: [],
        user: null,
        within_days: 30,
        rpc_loading: false,
        initialized: false,
        pendingRpcCalls: 0,
    });

    const [sharedState, setSharedState] = useState({
        setState: (state) => {
            setSharedState(state);
        },
    });

    const loadSitePreference = () => {
        try {
            const preferredSite = localStorage.getItem('preferredSite');
            if (preferredSite === null) {
                return undefined;
            }
            return `sites:${preferredSite}`;
        } catch (err) {
            return undefined;
        }
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        Recycle.onRpcError((err) => {
            if (err.response.status === 401) {
                Recycle.logout();
                setAppConfig((ac) => {
                    return { ...ac, authenticated: false, initialized: false, user: null };
                });
            }
        });

        Recycle.pendingRpcCalls.subscribe((count) => {
            setAppConfig((oldConfig) => {
                return { ...oldConfig, pendingRpcCalls: count };
            });
        });

        if (Recycle.isAuthenticated()) {
            setAppConfig((ac) => {
                return { ...ac, authenticated: true };
            });
        }
    }, []);

    const classes = useStyles();

    const renderApp = () => {
        if (authStore.authenticated === false) return <Navigate to="/login" />;

        if (authStore.authorized === false)
            return (
                <LoadingScreen
                    loading={authStore.authorizing}
                    text={getTextToShow()}
                    action={authStore.authorizingErrorMessage || timedOut ? 'Log out' : null}
                    onAction={() => {
                        authStore.logout();
                    }}
                    image={
                        <Logo
                            css={{
                                width: '150px',
                                padding: '1ch 0ch 3ch',
                                transform: 'translateX(11.7%)',
                            }}
                        />
                    }
                />
            );

        return <Base pages={authStore.pages} menu={authStore.menu} />;
    };

    const getTextToShow = () => {
        if (authStore.authorizingErrorMessage) {
            return authStore.authorizingErrorMessage;
        } else if (timedOut) {
            return 'This appears to be taking longer than usual. Please log out and try again if this persists.';
        }

        return 'Authorizing your user profile...';
    };

    return (
        <AppConfigContext.Provider value={appConfig}>
            <SharedStateContext.Provider value={sharedState}>
                <>
                    <Backdrop className={classes.backdrop} open={appConfig.pendingRpcCalls > 0}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {renderApp()}
                </>
            </SharedStateContext.Provider>
        </AppConfigContext.Provider>
    );
});

const RootApp = () => {
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                                <App />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#cbc5c5',
        backdropFilter: 'blur(5px)',
    },
}));

export const CareRootRoutes = <Route path="*" element={<RootApp />} key="care-root-routes" />;
