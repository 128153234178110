import React from 'react';
import { Field } from 'react-final-form';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import FieldLayout from './FieldLayout';
import InputLabel from '@mui/material/InputLabel';

const ReactButtonToggleAdapter = ({ input, ...rest }) => {
    const classes = useStyles();
    const StyledToggleButton = withStyles({
        root: {
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.32)',
            color: 'black',
            fontWeight: 500,
            paddingTop: '5px',
            paddingBottom: '5px',
            '&$selected': {
                backgroundColor: 'rgba(33, 137, 214)',
                color: 'rgb(255,255,255)',
                '&:hover': {
                    backgroundColor: 'rgba(33, 137, 214)',
                    color: 'rgb(255,255,255)',
                },
            },
        },
        selected: {},
    })(ToggleButton);
    const StyledToggleButtonGroup = withStyles({
        root: {
            width: '100%',
        },
    })(ToggleButtonGroup);

    const { onChange, ...restInput } = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        if (!(restInput.value === null && rest.optional === false)) {
            const optionFromID = rest.options.find((o) => o.value === restInput.value);
            if (optionFromID !== undefined) {
                setSelectedOption(restInput.value);
            } else {
                setSelectedOption(null);
            }
        }
    }, [restInput.value, rest.options]);

    const internalOnChange = (event, value, optional) => {
        if (!(value === null && optional === false)) {
            onChange(value);
        }
    };
    // console.log(JSON.stringify(restInput))

    return (
        <>
            <InputLabel className={classes.heading} shrink={true}>
                {rest.label}
            </InputLabel>
            <StyledToggleButtonGroup
                {...rest}
                optional={rest.optional ? rest.optional : 'false'}
                styles={customStyles}
                value={selectedOption}
                exclusive
                onChange={(event, value) => internalOnChange(event, value, rest.optional)}
                aria-label="text alignment"
            >
                {rest.options.map((option) => {
                    return (
                        <StyledToggleButton
                            key={option.value}
                            value={option.value}
                            aria-label="left aligned"
                        >
                            {option.label}
                        </StyledToggleButton>
                    );
                })}
            </StyledToggleButtonGroup>
            {
                // Hack - Does not support required
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={selectedOption !== null ? selectedOption : ''}
                    required={rest.optional === false}
                    // readOnly
                    onChange={() => {}}
                />
            }
        </>
    );
};

const ToggleButtonField = ({ id, name, optional, options, unit, description, warning }) => {
    const label = name;
    const adornment = unit ? ' '.concat('(', unit, ')') : '';
    const mapped_options = options.map(({ id, name }) => ({
        label: name.concat(adornment),
        value: id,
    }));

    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                label={label}
                optional={optional}
                options={mapped_options}
                component={ReactButtonToggleAdapter}
                adornment={adornment}
                testid={id}
            ></Field>
        </FieldLayout>
    );
};

const useStyles = makeStyles((theme) => ({
    heading: {
        color: 'rgba(0, 0, 0, 0.54)',
        whiteSpace: 'break-spaces',
        paddingBottom: '5px',
        fontSize: '1rem',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        lineHeight: '1.3em',
        letterSpacing: '0.01em',
        overflow: 'visible',
    },
}));

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
        };
    },
};

export default ToggleButtonField;
