import { styled } from '@a2d24-ui/theme';

const Input = styled('input', {
    all: 'unset',
    width: '100%',
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$2',
    padding: '0 10px',
    fontSize: '$6',
    lineHeight: '1.3em',
    height: '2em',

    color: '$darkText',
    '&::placeholder': {
        color: '$placeholderText',
    },

    cursor: 'default',
    boxShadow: `0 0 0 1px $lightText`,

    '&:focus': {
        cursor: 'text',
        boxShadow: `0 0 0 2px $primary`,
    },

    variants: {
        state: {
            invalid: {
                boxShadow: 'inset 0 0 0 1px $colors$warning',
                '&:focus': {
                    boxShadow: 'inset 0px 0px 0px 2px $colors$warning',
                },
                '&::placeholder': {
                    color: '$warning',
                },
            },
        },
    },
});

export { Input };
