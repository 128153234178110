import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const ServiceCell = ({ service }) => {
    const classes = useStyles();

    return <div className={classes.root}>{service}</div>;
};

const useStyles = makeStyles({
    root: {
        textTransform: 'capitalize',
        fontSize: '12px',
    },
});

export default ServiceCell;
