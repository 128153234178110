import React from 'react';
import Widget from './index';

const Row = ({ config, props }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {config.children
                ? config.children.map((child) => (
                      <Widget key={config.id} config={child} props={props} />
                  ))
                : null}
        </div>
    );
};

export default Row;
