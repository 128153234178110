//This validation is custom built for react-phone-input-2
import { parsePhoneNumber } from 'libphonenumber-js';

export const reFormat = (text) => {
    const allUpper = ['cof', 'bmi', 'hiv', 'tb'];
    const allLower = ['and', 'the', 'on', 'of', 'with', 'without'];
    const textArray = text.split('_');
    const reformattedText = textArray.map((word) => {
        if (allUpper.includes(word.toLowerCase())) {
            return word.toUpperCase();
        }
        if (allLower.includes(word.toLowerCase())) {
            return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return reformattedText.join(' ');
};


const isValidPhoneNumber = (formattedValue) => {
    if (formattedValue === undefined) {
        return 'Phone number cannot be unfilled';
    }
    if (formattedValue === '+') {
        return 'Invalid contact number.';
    }
    try {
        const parsedNumber = parsePhoneNumber(formattedValue);
        if (!parsedNumber.isValid()) {
            return 'Invalid contact number.';
        }
    } catch (ParseError) {
        if (ParseError.message === 'TOO_SHORT') {
            return 'Invalid contact number.';
        }
    }

    return undefined;
};

export default isValidPhoneNumber;
