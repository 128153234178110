import React from 'react';
import { useState, useEffect } from 'react';
import AdhocProcedureSelector from './AdhocProcedurelSelector';
import AdhocProceduresView from './AdhocProceduresView';
import theme from '../../theme';
import makeStyles from '@mui/styles/makeStyles';

const AdhocConsult = ({ options }) => {
    const styles = useStyles(theme);

    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    //used for setting procedures for table view
    const setSelections = (selectedOptions) => {
        setSelectedProcedures(selectedOptions);
    };

    //used for setting values for react-select
    const select = (selection, selectMeta) => {
        if (selectMeta.action === 'select-option') {
            const selections = [];
            setSelectedOptions(selection);
            selection.forEach((selection) => {
                const selectionValue = {
                    procedure_client: selection.value.client,
                    procedure_id: selection.value.procedure_id,
                    procedure_name: selection.value.name,
                    type: selection.value.type,
                };
                selections.push(selectionValue);
            });
            setSelections(selections);
        }
    };

    const removeOneSelection = (procedureName) => {
        const newSelectedProcedures = selectedProcedures.filter((procedure) => {
            return procedure.procedure_name !== procedureName;
        });
        const newSelectedOptions = selectedOptions.filter((option) => {
            return option.label !== procedureName;
        });
        setSelectedProcedures(newSelectedProcedures);
        setSelectedOptions(newSelectedOptions);
    };

    return (
        <div className={styles.gridContainer}>
            <div
                style={{
                    gridColumnStart: 'c1',
                    width: '100%',
                    gridColumnEnd: 'span',
                    gridRowStart: '1',
                    gridRowEnd: 'span',
                }}
            >
                <AdhocProcedureSelector
                    id="procedures"
                    options={options}
                    onSelect={select}
                    values={selectedOptions}
                />
            </div>
            <div
                style={{
                    gridColumnStart: 'c2',
                    gridColumnEnd: 'span',
                    gridRowStart: '1',
                    gridRowEnd: 'span',
                }}
            >
                <AdhocProceduresView
                    tableData={selectedProcedures}
                    removeOneSelection={removeOneSelection}
                    maxHeight={'350px'}
                />
            </div>
        </div>
    );
};
//TODO: Add remove functionality that removes from the two states
const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        columnGap: '25px',
        gridTemplateColumns: '[c1] 30% [c2] 70%',
        gridTemplateRows: '[r1] 500px',
        padding: '10px',
    },
}));

export default AdhocConsult;
