import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';

const styles = makeStyles((theme) => ({
    tableDiv: {
        marginBottom: '0px',
        marginLeft: '15px',
        marginRight: '15px',
        justifyContent: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },

    recommendedContainer: {
        backgroundColor: theme.palette.success.main,
        height: 'fit-content',
        borderRadius: '5px 5px 0px 0px',
    },
    recommendedHeader: {
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    alternativesContainer: {
        backgroundColor: 'white',
        height: 'fit-content',
        borderRadius: '0px 0px 5px 5px',
    },
    alternativesHeader: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '24px',
        fontWeight: 'bold',
    },
    mainHeader: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '15px',
    },
    recommendedCellDiv: {
        justifyContent: 'flex-start',
        marginTop: '10px',
        paddingLeft: '15px',
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        outline: 'none',
    },
    alternativesCellDiv: {
        justifyContent: 'flex-start',
        marginTop: '10px',
        paddingLeft: '15px',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '16px',
        fontWeight: '700',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        outline: 'none',
    },
    cellButton: {
        color: theme.palette.primary.dark,
        backgroundColor: 'white',
        fontSize: '14px',
        fontWeight: '800',
        textTransform: 'uppercase',
        border: `1px solid ${theme.palette.primary.dark}`,
        borderRadius: '8px',
        margin: '10px',
        height: '40px',
        width: '40%',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
            cursor: 'pointer',
        },
    },
}));

export default styles;
