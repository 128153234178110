import styles from './styles';
import theme from '../../theme';
import { reFormat } from '../utils';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
//NOTE: THIS component has been built to accommodate a max of 2 states
//TODO: Add toggle functionality
const SubPathwayCell = ({
    rowData,
    overridable,
    onSubPathwayChange,
    isDefault,
    pathwayOptions = [],
    originalPlan,
    variant,
    enabled,
}) => {
    const classes = styles();
    let className;
    switch (variant) {
        case 'dark':
            className = classes.subPathwayDivDark;
            break;
        case 'white':
            className = classes.subPathwayDivWhite;
            break;
        default:
            className = classes.normalTextDivDark;
    }

    const [subPathway, setSubPathway] = useState(rowData.pathway);
    const [overrode, setOverrode] = useState(rowData.isOverrode);

    const handleChange = (event) => {
        const newPathway = event.target.value;
        const updatedPlan = rowData;
        setSubPathway(newPathway);
        updatedPlan['pathway'] = reFormat(newPathway);
        if (overridable) {
            updatedPlan['isOverrode'] = newPathway !== originalPlan['pathway'];
        }
        onSubPathwayChange(updatedPlan);
    };

    return enabled ? (
        <div className={className}>
            {overridable || !isDefault ? (
                <Tooltip
                    title={rowData.isOverrode ? 'Revert' : 'Override'}
                    disableHoverListener={!overridable}
                >
                    <FormControl>
                        <RadioGroup
                            value={subPathway}
                            onChange={handleChange}
                            sx={{
                                color: variant === 'white' ? 'white' : theme.palette.primary.main,
                                '&.Mui-checked': {
                                    color: theme.palette.primary.main,
                                },
                            }}
                        >
                            {!isDefault
                                ? pathwayOptions.map((pathwayOption) => {
                                      return (
                                          <FormControlLabel
                                              key={pathwayOption}
                                              control={<Radio key={pathwayOption} />}
                                              value={pathwayOption}
                                              label={pathwayOption}
                                          />
                                      );
                                  })
                                : null}
                        </RadioGroup>
                    </FormControl>
                </Tooltip>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {reFormat(subPathway) === 'Default' ? null : subPathway}
                </div>
            )}
        </div>
    ) : null;
};

export default SubPathwayCell;
