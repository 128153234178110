import initPolyfills from './Polyfills';

export default class EmrTemplateModule {
    constructor() {
        initPolyfills();
    }

    provides() {
        return {
            'root-routes': [() => require('./App').CareRootRoutes],
            Logo: () => require('./Logo').default,
            LogoIcon: () => require('./Logo').Icon,
            SecondaryLogo: () => require('./Logo').SecondaryLogo,
        };
    }

    components() {
        return [
            {
                id: 'care-at-work-template-module.App',
                generator: () => require('./App').default,
                componentType: ['App'],
            },
        ];
    }
}
