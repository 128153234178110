import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const OverrideStatus = ({ config, props }) => {
    const [updatedCaseStatus, setUpdatedCaseStatus] = React.useState({ value: '', label: '' });
    const [notes, setNotes] = React.useState('');
    const [statusOptions, setStatusOptions] = React.useState([]);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.override_options !== undefined && props.override_options.length > 0) {
            const options = props.override_options.map((option) => {
                return { value: option, label: option };
            });
            setStatusOptions(options);
            setUpdatedCaseStatus(options[0]);
        }
    }, [props.override_options]);

    const handleChange = (event) => {
        setUpdatedCaseStatus(event);
    };

    const handleUpdateCase = () => {
        Recycle.rpcPostV2({
            controller: 'ConsultController',
            method: 'override_consult_result',
            kwargs: {
                consult_id: props.consult_id,
                updated_result_status: updatedCaseStatus.value,
                notes: notes,
            },
        })
            .then((res) => {
                navigate(`/consults/${props.consult_id}/signoffcomplete`);
                enqueueSnackbar('Consult Result Successfully Overridden', { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar('Failed to set Override Result', { variant: 'error' });
            });
    };
    return (
        <div>
            <Paper className={classes.root}>
                <InputLabel shrink={true}>Select Case Management Update</InputLabel>
                <FormControl fullWidth>
                    <Select
                        styles={customStyles}
                        value={updatedCaseStatus}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="case_status"
                        options={statusOptions}
                        onChange={handleChange}
                    />
                    <TextField
                        onChange={(e) => setNotes(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        id="status-notes"
                        label="Status Notes"
                        multiline={true}
                        fullWidth
                        rows={4}
                    />

                    <Button
                        color="primary"
                        disabled={updatedCaseStatus.value === '' || notes === ''}
                        variant="contained"
                        onClick={handleUpdateCase}
                    >
                        {' '}
                        Update Case
                    </Button>
                </FormControl>
            </Paper>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
    },
}));
const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
        };
    },
};
export default OverrideStatus;
