export const reFormat = (text) => {
    const allUpper = ['cof', 'bmi', 'hiv', 'tb'];
    const allLower = ['and', 'the', 'on', 'of', 'with', 'without'];
    const textArray = text.split('_');
    const reformattedText = textArray.map((word) => {
        if (allUpper.includes(word.toLowerCase())) {
            return word.toUpperCase();
        }
        if (allLower.includes(word.toLowerCase())) {
            return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return reformattedText.join(' ');
};
