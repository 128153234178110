import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircleIcon from '@mui/icons-material/Circle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { reFormat } from '../utils';
import styles from './styles';
import theme from '../../theme';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useSnackbar } from 'notistack';

const careTypeMapping = {
    chronic_care: 'Chronic Care',
    workplace_disease: 'Workplace Disease',
};

const ConfirmDiseaseManagementPlanDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    isUpdated,
    patient_id,
    consult_id,
    plans,
}) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const handleConfirmation = () => {
        Recycle.rpcPostV2({
            controller: 'MedicalConditionsController',
            method: 'update_patient_medical_conditions',
            kwargs: {
                patient_identifier: patient_id,
                consult_id: consult_id,
                added_patient_conditions: plans['newSelectedPlans'],
                removed_patient_conditions: plans['removedPlans'],
            },
        })
            .then((res) => {
                console.log(res);
                enqueueSnackbar('Disease Management Plan updated.', { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar('Failed to update Disease Management Plan.', { variant: 'error' });
                console.log(err);
            });
        onConfirm();
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="md"
            sx={{ maxHeight: 'fit-content' }}
        >
            <DialogTitle sx={{ fontSize: '22px', fontWeight: '900' }}>{title}</DialogTitle>
            <DialogContent>
                <div className={classes.dialogPrimaryTextDiv}>
                    {isUpdated
                        ? 'Updates have been made to the current disease management plan.'
                        : 'No updates have been made to the disease management plan.'}
                </div>
                {plans['currentPreviousPlans'].length !== 0 ? (
                    <div className={classes.dialogNormalTextDiv}>
                        The patient <strong>will still be enrolled</strong> in the following plans:
                        {plans['currentPreviousPlans'].map((plan) => {
                            return (
                                <div
                                    key={plan.disease}
                                    style={{ display: 'flex', justifyContent: 'start' }}
                                >
                                    <div
                                        style={{ fontWeight: '900' }}
                                        className={classes.dialogListText}
                                    >
                                        <CircleIcon fontSize="small" sx={{ width: '8px' }} />
                                    </div>
                                    <div className={classes.dialogListText}>
                                        {`${reFormat(plan.disease)}: ${careTypeMapping[plan.type]}`}
                                        {plan.pathway !== 'Default' ? (
                                            <strong> ({plan.pathway})</strong>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {plans['newSelectedPlans'].length !== 0 ? (
                    <div className={classes.dialogSecondaryTextDiv}>
                        The patient <strong>will be enrolled</strong> in the following additional
                        plans:
                        {plans['newSelectedPlans'].map((plan) => {
                            return (
                                <div
                                    key={plan.disease}
                                    style={{ display: 'flex', justifyContent: 'start' }}
                                >
                                    <div
                                        style={{ fontWeight: '900' }}
                                        className={classes.dialogListText}
                                    >
                                        <CircleIcon fontSize="small" sx={{ width: '8px' }} />
                                    </div>
                                    <div className={classes.dialogListText}>
                                        {`${reFormat(plan.disease)}: ${careTypeMapping[plan.type]}`}
                                        {plan.pathway !== 'Default' ? (
                                            <strong> ({plan.pathway})</strong>
                                        ) : null}
                                    </div>
                                    {plans['overrodePlans']
                                        .map((plan) => {
                                            return JSON.stringify(plan);
                                        })
                                        .includes(JSON.stringify(plan)) ? (
                                        <div
                                            className={classes.dialogListDetail}
                                            style={{
                                                backgroundColor: '#e4ccb4',
                                                color: 'white',
                                                fontWeight: '400',
                                            }}
                                        >
                                            Overrode
                                        </div>
                                    ) : null}
                                    {plans['recommendedPlans']
                                        .map((plan) => {
                                            return JSON.stringify(plan);
                                        })
                                        .includes(JSON.stringify(plan)) ? (
                                        <div
                                            className={classes.dialogListDetail}
                                            style={{
                                                backgroundColor: theme.palette.success.main,
                                                color: 'white',
                                                fontWeight: '400',
                                            }}
                                        >
                                            Recommended
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {plans['removedPlans'].length !== 0 ? (
                    <div className={classes.dialogNormalTextDiv}>
                        The patient <strong>will no longer be enrolled</strong> in the following
                        plans:
                        {plans['removedPlans'].map((plan) => {
                            return (
                                <div
                                    key={plan.disease}
                                    style={{ display: 'flex', justifyContent: 'start' }}
                                >
                                    <div
                                        style={{ fontWeight: '900' }}
                                        className={classes.dialogListText}
                                    >
                                        <CircleIcon fontSize="small" sx={{ width: '8px' }} />
                                    </div>
                                    <div className={classes.dialogListText}>
                                        {`${reFormat(plan.disease)}: ${careTypeMapping[plan.type]}`}
                                        {plan.pathway !== 'Default' ? (
                                            <strong> ({plan.pathway})</strong>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                <div className={classes.dialogNormalTextDiv} style={{ fontStyle: 'italic' }}>
                    If you are satisfied with your selection(s) click on confirm If you would like
                    to make further changes, click on cancel and modify your selection.
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleConfirmation} variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDiseaseManagementPlanDialog;
