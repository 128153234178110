import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from 'mui-rff';

const MedicalHistoryTextboxField = ({ id, meta, name, optional = false }) => {
    const label = name;

    return (
        <Field name={id} placeholder={label}>
            {(fieldProps) => {
                return (
                    <TextField
                        variant="standard"
                        label={fieldProps.placeholder}
                        name={fieldProps.input.name}
                        testid={id}
                        margin="none"
                        InputProps={{}}
                        style={{ marginBottom: '1rem' }}
                        autoComplete="off"
                        required={optional === false}
                    />
                );
            }}
        </Field>
    );
};

export default MedicalHistoryTextboxField;
