import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const getUploadedDocument = (document_key)=>{
    return axios
        .post(`${API_URL}/DocumentController/get_presigned_url_for_consult_document`, {
            key: document_key
        })
        .then((r) => {
            return r.data
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                return null;
            }
            else{
                throw err;
            }
        });
}


export const getReportDocument = (document_key)=>{
    return axios
        .post(`${API_URL}/DocumentController/get_presigned_url_for_report_document`, {
            key: document_key
        })
        .then((r) => {
            return r.data
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                return null;
            }
            else{
                throw err;
            }
        });
}
