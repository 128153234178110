import React from 'react';
import { Field } from 'react-final-form';
import { Radios } from 'mui-rff';
import RffFieldError from './RffFieldError';
import RffFieldLayout from './RffField';

const RffRadioButtonField = ({ id, name, options, optional, unit, validate }) => {
    const label = name;
    const adornment = unit ? ' '.concat('(', unit, ')') : '';
    const mapped_options = options.map(({ id, name }) => ({
        label: name.concat(adornment),
        value: id,
    }));

    return (
        <RffFieldLayout>
            <Field name={id} type="radio" validate={validate ? validate : null}>
                {({ input, meta }) => {
                    return (
                        <div>
                            <br />
                            <div>
                                <Radios
                                    name={input.name}
                                    label={label}
                                    formControlProps={{ margin: 'none' }}
                                    radioGroupProps={{ row: true }}
                                    required={optional === false}
                                    data={mapped_options}
                                />
                            </div>
                        </div>
                    );
                }}
            </Field>
        </RffFieldLayout>
    );
};

export default RffRadioButtonField;
