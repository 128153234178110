import React from 'react';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            message: null,
        };
    }

    static getDerivedStateFromError({ message }) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, message };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <h1>{this.state.message}</h1>
                    <p>{this.state.errorInfo}</p>
                </>
            );
        }

        return this.props.children;
    }
}
