import MaterialTable from '@material-table/core';
import styles from './styles';
import { useEffect, useState } from 'react';
import { TableContainer } from '@mui/material';
import DiseaseCell from './DiseaseCell';
import DiseaseTypeCell from './DiseaseTypeCell';
import SubPathwayCell from './SubPathwayCell';
import theme from '../../theme';
import CheckBoxCell from './CheckBoxCell';
import { reFormat } from '../utils';
import { updatePlanOnChange } from './commonFunctions';

const RecommendedPlanSection = ({ plans, onSelectionsChange }) => {
    const classes = styles();
    const [recommendedPlans, setRecommendedPlans] = useState(plans);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const onSubPathwayChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setRecommendedPlans(
            updatePlanOnChange(recommendedPlans, tempPlan, [
                { attribute: 'disease', isEqual: true },
                { attribute: 'isOverrode', isEqual: false },
            ])
        );
    };
    const onSelectPlanChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setRecommendedPlans(
            updatePlanOnChange(recommendedPlans, tempPlan, [
                { attribute: 'disease', isEqual: true },
                { attribute: 'isSelected', isEqual: false },
            ])
        );
    };
    useEffect(() => {
        setSelectedPlans(
            recommendedPlans.filter((plan) => {
                return plan.isSelected;
            })
        );
    }, [recommendedPlans]);
    useEffect(() => {
        onSelectionsChange(selectedPlans);
    }, [selectedPlans]);
    const columns = [
        {
            field: 'disease',
            render: (rowData) => <DiseaseCell rowData={rowData} variant={'darkMode'} />,
        },
        {
            field: 'type',
            render: (rowData) => <DiseaseTypeCell rowData={rowData} variant={'darkMode'} />,
        },
        {
            field: 'pathway',
            render: (rowData) => (
                <SubPathwayCell
                    rowData={rowData}
                    overridable={true}
                    onSubPathwayChange={onSubPathwayChange}
                    originalPlan={
                        plans.filter((plan) => {
                            return plan.disease === rowData.disease;
                        })[0]
                    }
                    isDefault={reFormat(rowData.pathway).toLowerCase() === 'default'}
                    pathwayOptions={rowData.pathway_options}
                    variant={'white'}
                    enabled={rowData.isSelected ? rowData.isSelected : false}
                />
            ),
        },
        {
            field: 'action_select',
            render: (rowData) => (
                <CheckBoxCell
                    rowData={rowData}
                    onCheckBoxChange={onSelectPlanChange}
                    checkedMessage="Deselect"
                    uncheckedMessage="Select"
                    variant={'white'}
                />
            ),
        },
    ];
    return (
        <MaterialTable
            title={<div className={classes.mainTextDiv}>Recommended Plans</div>}
            columns={columns}
            data={recommendedPlans}
            options={{
                padding: 'dense',
                showTitle: true,
                showEmptyDataSourceMessage: true,
                filtering: false,
                header: false,
                paging: false,
                search: false,
                selection: false,
                pageSize: recommendedPlans.length,
                rowStyle: {
                    backgroundColor: theme.palette.success.main,
                    borderRadius: '5px',
                },
            }}
            components={{
                Container: (props) => (
                    <TableContainer {...props} className={classes.tableContainer} />
                ),
            }}
        />
    );
};

export default RecommendedPlanSection;
