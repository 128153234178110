import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { appointmentStatusColorMapping } from '../const';

const PatientCell = ({ appointment }) => {
    const classes = useStyles(appointment);

    return (
        <div className={classes.root}>
            <div className={classes.statusRectangle}></div>
            <div>
                <div className={classes.fullName}>{`${appointment.patient.name}`}</div>
                <div className={classes.contactNumber}>{appointment.patient.contact_number}</div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    statusRectangle: (props) => {
        const backgroundColor = appointmentStatusColorMapping(theme)[props.status].main;

        return {
            width: '4px',
            height: '24px',
            position: 'absolute',
            left: 0,
            backgroundColor: backgroundColor,
        };
    },
    fullName: {
        fontSize: '14px',
        fontWeight: 600,
    },
    contactNumber: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
    },
}));

export default PatientCell;
