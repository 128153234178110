import styles from './styles';
import { reFormat } from '../utils';

const DiseaseTypeCell = ({ rowData, variant }) => {
    const classes = styles();
    let className = null;
    switch (variant) {
        case 'standard':
            className = classes.normalTextDivDark;
            break;
        case 'darkMode':
            className = classes.normalTextWhite;
            break;
        default:
            className = classes.normalTextDivDark;
    }
    return <div className={className}>{reFormat(rowData.type)}</div>;
};

export default DiseaseTypeCell;
