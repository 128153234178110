import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import RffFieldLayout from './RffField';

const ReactSelectAdapter = ({
    id,
    input,
    optional,
    isClearable,
    controlShouldRenderValue,
    onSelect,
    selectedValues,
    ...rest
}) => {
    const classes = useStyles();

    const { onChange, ...restInput } = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        const optionsFromIDs = getObjectsfromValues(restInput.value, rest.options);
        setSelectedOption(null);
        if (optionsFromIDs !== undefined) {
            setSelectedOption(optionsFromIDs);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options]);

    React.useEffect(() => {
        if (!selectedValues) {
            return;
        }
        const values = selectedValues.map((Val) => {
            return Val.value;
        });
        onChange(values);
    }, [selectedValues]);

    const internalOnChange = (val) => {
        const values = Array.isArray(val) ? val.map((x) => x.value) : [];
        onChange(values);
    };

    const getObjectsfromValues = (values, options) => {
        const value_object = [];
        if (Array.isArray(values)) {
            for (const value of values) {
                value_object.push(options.find((o) => o.value === value));
            }
        }
        return value_object;
    };
    return (
        <>
            <InputLabel shrink={true}>{rest.label}</InputLabel>
            <Select
                {...restInput}
                {...rest}
                styles={customStyles}
                isClearable={isClearable}
                controlShouldRenderValue={controlShouldRenderValue}
                isSearchable={true}
                onChange={(values, selectMeta) => {
                    if (onSelect && selectedValues) {
                        onSelect(values, selectMeta);
                    } else {
                        onSelect(values, selectMeta);
                        internalOnChange(values);
                    }
                }}
                value={selectedValues}
                isMulti
            />
            {
                // Hack - Select does not support required
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={Array.isArray(selectedOption) ? selectedOption : []}
                    required={!optional}
                    // readOnly
                    onChange={() => {}}
                    testid={input.name}
                />
            }
        </>
    );
};

const RffReactMultiSelectField = ({
    id,
    name,
    options,
    optional,
    isClearable = true,
    controlShouldRenderValue = true,
    onSelect,
    values = undefined,
}) => {
    const label = name;

    return (
        <RffFieldLayout>
            <Field
                name={id}
                label={label}
                optional={optional}
                options={options}
                component={ReactSelectAdapter}
                isClearable={isClearable}
                controlShouldRenderValue={controlShouldRenderValue}
                onSelect={onSelect}
                selectedValues={values}
            ></Field>
        </RffFieldLayout>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem',
        },
    };
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: state.isFocused ? '2px solid #000d1a' : '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            width: '100%',
            '&:hover': {
                borderBottom: state.isFocused ? '2px solid #000d1a' : '2px solid #000d1a',
            },
        };
    },
};

export default RffReactMultiSelectField;
