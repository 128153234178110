import axios from 'axios';
import { saveAs } from 'file-saver';

export const DownloadFromUrl = (url, fileName, options = {}) => {
    const { headers } = options;

    return axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers,
    })
        .then((response) => {
            saveAs(response.data, fileName);
        })
        .catch((err) => {
        });
};


export const saveBase64 = function (b64Data, contentType, sliceSize, fileName) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    saveAs(blob, fileName)
};
