import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { appointmentStatusColorMapping } from '../const';

const BookingSourceCell = ({ source }) => {
    const classes = useStyles({ source });
    let sourceName = "Manual";
    switch (source){
        case "AppointLogic":
            sourceName =  "Booking Platform";
            break;
        case "Booking API":
            sourceName =  "NOH Portal";
            break;
    }
    return <div className={classes.root}>{(sourceName??"Manual").replace(/_/g, ' ')}</div>;
};

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        return {
            color: 'black',
            textTransform: 'capitalize',
            fontSize: '12px',
        };
    },
}));

export default BookingSourceCell;
