import MaterialTable from '@material-table/core';
import styles from './styles';
import { useEffect, useState } from 'react';
import { TableContainer } from '@mui/material';
import DiseaseCell from './DiseaseCell';
import DiseaseTypeCell from './DiseaseTypeCell';
import SubPathwayCell from './SubPathwayCell';
import CheckBoxCell from './CheckBoxCell';
import { reFormat } from '../utils';
import { updatePlanOnChange } from './commonFunctions';

const PreviousPlansSection = ({ plans, onSelectionsChange }) => {
    const classes = styles();
    const [previousPlans, setPreviousPlans] = useState(plans);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const onSubPathwayChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setPreviousPlans(
            updatePlanOnChange(previousPlans, tempPlan, [
                { attribute: 'disease', isEqual: true },
                { attribute: 'isOverrode', isEqual: false },
            ])
        );
    };
    const onSelectPlanChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setPreviousPlans(
            updatePlanOnChange(previousPlans, tempPlan, [
                { attribute: 'disease', isEqual: true },
                { attribute: 'isSelected', isEqual: false },
            ])
        );
    };
    useEffect(() => {
        setSelectedPlans(
            previousPlans.filter((plan) => {
                return plan.isSelected;
            })
        );
    }, [previousPlans]);
    useEffect(() => {
        onSelectionsChange(selectedPlans);
    }, [selectedPlans]);
    const columns = [
        {
            field: 'disease',
            render: (rowData) => <DiseaseCell rowData={rowData} variant={'standard'} />,
        },
        {
            field: 'type',
            render: (rowData) => <DiseaseTypeCell rowData={rowData} variant={'standard'} />,
        },
        {
            field: 'pathway',
            render: (rowData) => (
                <SubPathwayCell
                    rowData={rowData}
                    overridable={true}
                    onSubPathwayChange={onSubPathwayChange}
                    originalPlan={
                        plans.filter((plan) => {
                            return plan.disease === rowData.disease;
                        })[0]
                    }
                    isDefault={reFormat(rowData.pathway).toLowerCase() === 'default'}
                    pathwayOptions={rowData.pathway_options}
                    variant={'dark'}
                    enabled={rowData.isSelected ? rowData.isSelected : false}
                />
            ),
        },
        {
            field: 'action_select',
            render: (rowData) => (
                <CheckBoxCell
                    rowData={rowData}
                    onCheckBoxChange={onSelectPlanChange}
                    checkedMessage="Deselect"
                    uncheckedMessage="Select"
                    variant={'dark'}
                />
            ),
        },
    ];

    return (
        <MaterialTable
            title={<div className={classes.mainTextDiv}>Previously Enrolled Plans</div>}
            columns={columns}
            data={previousPlans}
            options={{
                padding: 'dense',
                showTitle: true,
                showEmptyDataSourceMessage: true,
                filtering: false,
                header: false,
                paging: false,
                search: false,
                selection: false,
                pageSize: previousPlans.length,
                rowStyle: {
                    backgroundColor: 'white',
                    borderRadius: '5px',
                },
            }}
            components={{
                Container: (props) => (
                    <TableContainer {...props} className={classes.tableContainer} />
                ),
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: (
                        <div className={classes.noDataDiv}>No previously enrolled plan.</div>
                    ),
                },
            }}
        />
    );
};

export default PreviousPlansSection;
