import React from 'react';
import dayjs from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';

const CreatedByOrDateCell = ({ type, history }) => {
    const classes = useStyles();
    const value =
        type && type === 'history_table'
            ? dayjs(history.created_at).format('DD/MM/YYYY')
            : history.created_by;
    return (
        <div>
            <div className={classes.description}>{value}</div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    description: {
        textTransform: 'capitalize',
    },
    date: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
        textTransform: 'capitalize',
    },
}));

export default CreatedByOrDateCell;
