export const vaccinations = [
    'SARS CoV-2 (COVID)',
    'Hepatitis A',
    'Hepatitis B',
    'Influenza',
    'Pertussis - Whooping cough',
    'Tetanus',
    'Poliomyelitis',
    'Measles',
    'Meningitis',
    'Mumps',
    'Rubella (German Measles)',
    'Varicella (Chicken Pox)',
    'BCG',
    'Yellow Fever',
    'Stamaril',
    'Yellow Fever Card',
    'Yellow Fever exemption',
    'TDaPPolio ‐ Adacel/Boostrix',
    'Typhoid ‐ Typhim VI',
    'Meningitis ACW135Y ‐ Menactra/Mencevax',
    'Twinrix',
    'Twinrix: Dose 2',
    'Twinrix : Dose 3',
    'Twinrix : Booster',
    'Hepatitis A ‐ Havrix/Avaxim',
    'Hepatitis A ‐ Havrix/Avaxim: Dose 2',
    'Hepatitis A ‐ Havrix/Avaxim: Booster',
    'Hepatitis B ‐ Engerix B/Heberbiovac',
    'Hepatitis B ‐ Engerix B/Heberbiovac: Dose 2',
    'Hepatitis B ‐ Engerix B/Heberbiovac: Dose 3',
    'Hepatitis B ‐ Engerix B/Heberbiovac: Booster',
    'Vivaxim',
    'Vivaxim: Dose 2',
    'Vivaxim: Booster',
    'Tetanus',
    'MMR ‐ Priorix/Trimovax',
    'Rouvax',
    'Pneumo 23',
    'Polio Drops',
    'Verorab',
    'Verorab: Dose 2',
    'Verorab: Dose Booster',
    'Dukoral',
    'Dukoral: Dose 2',
    'Varilrix',
    'Varilrix: Dose 2',
    'Varilrix: Dose Booster',
    'HPV ‐ Cervarix/Gardasil',
    'HPV ‐ Cervarix/Gardasil: Dose 2',
    'HPV ‐ Cervarix/Gardasil: Dose 3',
    'Influenza/Vaxigrip/lnfluvac',
    'Prevenar 13',
    'Acetazolamide/Diamox/Azomid',
    'Utin/Ciprofloxin/Cifran/Ciprogen'
]
