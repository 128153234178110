import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ConsultCompleteDocumentTable from './ConsultCompleteDocumentTable';
import Button from '@mui/material/Button';

const PatientHistoryTableDialog = (props) => {
    const data = {
        consult: {
            consult_id: props.consult?.consult_id,
        },
        type: 'history_table',
    };
    return (
        <div>
            <div>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={props.open}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <ConsultCompleteDocumentTable
                            config={{ title: props.consult?.tariff_description }}
                            props={data}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleCloseConsultDocumentTable} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default PatientHistoryTableDialog;
