import { v4 as uuidv4 } from 'uuid';
import DifferedSocket from '@a2d24/socket';

const sessionId = uuidv4();

class DifferedTask {
    constructor(task_id, websocketEndpoint, token) {
        this.task_id = task_id;
        this.connected = false;
        this.shouldConnect = true;
        this.callbacks = new Map();
        this.socket = new DifferedSocket(
            websocketEndpoint,
            this.onChange.bind(this),
            this.onMessage.bind(this),
            this.onDisconnect.bind(this),
            this.onError.bind(this),
            sessionId,
            token
        );
    }

    reSubscribe() {
        this._sendAction('subscribe');
        this._sendAction('status');
    }

    onChange(state) {
        this.connected = state;
        if (state === true) this.reSubscribe();
    }

    onMessage(data) {
        this.callbacks.forEach((value, callback) => callback(data));
    }

    _reconnectSocketIfRequired() {
        if (this.shouldConnect) this.socket.connect();
    }

    onDisconnect() {
        this._reconnectSocketIfRequired();
    }

    onError() {
        this._reconnectSocketIfRequired();
    }

    _sendAction(action) {
        this.socket.sendMessage({
            action,
            identifier: this.task_id,
            session_id: sessionId,
        });
    }

    off(callback) {
        this.callbacks.delete(callback);
        if (this.callbacks.size < 1) {
            this.shouldConnect = false;
            this.socket.disconnect();
        }
    }

    on(callback) {
        if (!this.connected) this.socket.connect();

        this.callbacks.set(callback, true);
    }

    status() {
        this._sendAction('status');
    }
}

export default DifferedTask;
