import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import Recycle from '@a2d24/care-at-work-core/Recycle';

const AgendaEvents = ({ config, props }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedEventId, setSelectedEventId] = useState(null);

    const columns = [
        {
            title: 'Due Date',
            render: (rowData) => <div>{dayjs(rowData.event_date).format('DD MMMM YYYY')}</div>,
        },
        {
            field: 'name',
            title: 'Event',
        },
        {
            field: 'description',
            title: 'Description',
        },
        {
            render: (rowData) => (
                <Radio
                    value={rowData.event_id}
                    checked={selectedEventId === rowData.event_id}
                    onChange={handleChange}
                />
            ),
        },
    ];

    const handleChange = (event) => {
        setSelectedEventId(event.target.value);
    };

    const handleNone = () => {
        Recycle.rpcPostV2({
            controller: 'AgendaManager',
            method: 'complete_agenda_event',
            kwargs: { event_id: 'none', consult_id: props.consult_id },
        }).then((res) => {
            navigate('/dashboard');
        });
    };

    const handleProceed = () => {
        Recycle.rpcPostV2({
            controller: 'AgendaManager',
            method: 'complete_agenda_event',
            kwargs: { event_id: selectedEventId, consult_id: props.consult_id },
        }).then((res) => {
            navigate('/dashboard');
        });
    };

    return (
        <div className={classes.container}>
            <MaterialTable
                title="Please select the relevant scheduled consult for the employee"
                columns={columns}
                data={props.agenda_events || []}
                options={{ search: false }}
            />
            <div className={classes.buttonsContainer}>
                <Button variant="outlined" color="primary" onClick={handleNone}>
                    none of the above
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleProceed}
                    disabled={!selectedEventId}
                >
                    proceed
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(3),
    },
    buttonsContainer: {
        justifySelf: 'end',
        display: 'grid',
        gridAutoFlow: 'column',
        gap: theme.spacing(3),
    },
}));

export default AgendaEvents;
