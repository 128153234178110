import * as React from 'react';

import TextField from '@mui/material/TextField';

import { MobileTimePicker as MuiMobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker as MuiDesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import { DesktopDatePicker as MuiDesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker as MuiMobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { StaticDatePicker as MuiStaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker as MuiDesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { MobileDateTimePicker as MuiMobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export const DefaultInput = ({
    InputProps: { helperText, fullWidth, variant = 'standard', ...restInputProps },
    inputProps,
    ...props
}) => (
    <TextField
        helperText={helperText}
        fullWidth={fullWidth}
        variant={variant}
        InputProps={restInputProps}
        FormHelperTextProps={{
            error: restInputProps.error,
        }}
        inputProps={{
            autoComplete: 'off',
            ...inputProps,
        }}
        {...props}
    />
);

const withMuiProviders = (WrappedComponent) => {
    return (props) => <WrappedComponent {...props} />;
};

// Helper method that copies propTypes
const wrapMuiComponent = (Base) => {
    const Component = withMuiProviders(Base);
    Component.propTypes = Base.propTypes;
    return Component;
};

export const MobileTimePicker = wrapMuiComponent(MuiMobileTimePicker);
export const DateTimePicker = wrapMuiComponent(MuiDateTimePicker);
export const DesktopDatePicker = wrapMuiComponent(MuiDesktopDatePicker);
export const MobileDatePicker = wrapMuiComponent(MuiMobileDatePicker);
export const MobileDateTimePicker = wrapMuiComponent(MuiMobileDateTimePicker);
export const StaticDatePicker = wrapMuiComponent(MuiStaticDatePicker);
export const DesktopDateTimePicker = wrapMuiComponent(MuiDesktopDateTimePicker);
export const DesktopTimePicker = wrapMuiComponent(MuiDesktopTimePicker);
