import InfoText from './InfoText';
import { calculateAge } from '../shared/utils';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { sharedStyles } from '../shared/styles';

export const BasicInformation = ({ patient, booking }) => {
    const classes = useStyles();
    const sharedClasses = sharedStyles();

    return (
        <div>
            <div className={classes.top} style={{ padding: '15px' }}>
                <div>
                    <img
                        className={classes.profileImage}
                        src={`/${booking.client_id}_logo.png`}
                        alt="profile"
                    />
                </div>
                <div className={classes.consultDetails}>
                    <div className={sharedClasses.heading}>
                        {`${patient.first_name} ${patient.last_name}`}
                    </div>
                    <div className={clsx([classes.identifier, sharedClasses.helperText])}>
                        {patient.alternative_identifiers[0].type}:{' '}
                        {patient.alternative_identifiers[0].value}
                    </div>
                </div>
            </div>

            <div className={classes.heading}>Basic Information</div>

            <div className={classes.infoContainer}>
                <InfoText
                    className={classes.infoText}
                    placeholder="Gender"
                    capitalize={true}
                    text={patient.gender}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Age"
                    text={calculateAge(patient.date_of_birth)}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Contact No."
                    text={patient.contact_number}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Email ID"
                    text={patient.email}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    top: {
        display: 'flex',
        textTransform: 'capitalize',
    },
    consultDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    profileImage: {
        height: '82px',
        // width: '82px'
    },
    identifier: {
        textAlign: 'end',
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    infoText: {
        padding: theme.spacing(3),
        '&:not(:first-child)': {
            borderLeft: '1px solid #EEEEEE',
        },
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    service: {
        display: 'flex',
    },
    verticalDivider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    notesHeading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        paddingRight: '6px',
    },
    notesInfo: {
        marginTop: '6px',
        color: 'rgba(0, 0, 0, 0.36)',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            color: 'black',
        },
    },
    notesTooltipText: {
        color: 'rgba(0, 0, 0, 0.46)',
        fontSize: '15px',
        fontStyle: 'italic',
    },
}));
