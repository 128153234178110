import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MetricCard from './MetricCard';
import { useStores } from '@a2d24/care-at-work-state/stores';
import useDeadpollList from '@a2d24/care-at-work-core/Deadpoll/useDeadpollList';

const MetricGroup = ({ config, props }) => {
    const classes = useStyles();
    const {authStore} = useStores();
    const isGlobal = props.role === "global";
    const { data:consults, loading:isLoadingConsults } = useDeadpollList(`${authStore?.occhealthPracticeClient}#${props?.deadpoll_list_id}`)
    let cards = [];
    if (!props.metrics && !props.role && !props.deadpoll_list_id) {
        return null;
    }
    if(!isGlobal && !props.deadpoll_list_id){
        for (const status in props.metrics.statuses) {
            cards.push({ status: status, count: props.metrics.statuses[status] });
        }
    }
    else{

        cards.push(
            { status: 'awaiting_omp', count: (consults??[]).length }
        )
    }

    return (
        <div className={classes.container}>
            {cards.map((card) => {
                return (
                    <MetricCard
                        key={card.status}
                        count={card.count}
                        status={card.status}
                        type={isGlobal?'consult':props.metrics.type}
                    />
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridAutoFlow: 'column',
        gap: theme.spacing(3),
    },
}));

export default MetricGroup;
