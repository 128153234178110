import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DynamicForm from './DynamicForm';

const GenerateDocumentDialog = (props) => {
    const handleSubmitDocument = (values) => {
        props.handleSubmitClinicalDocument(values.title, props.clinicalDocumentType, values);
    };
    return (
        <Dialog fullScreen={true} open={props.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Generate{' '}
                {typeof props.clinicalDocumentType === 'string'
                    ? props.clinicalDocumentType.replaceAll('_', ' ').toLowerCase()
                    : ''}
            </DialogTitle>
            <DialogContent>
                <DynamicForm
                    onSubmit={handleSubmitDocument}
                    config={{
                        fields: generateDocumentFields[[props.clinicalDocumentType]],
                        defaultValues: {},
                    }}
                    actions={[
                        ({ submitting }) => (
                            <Button
                                key="cancel"
                                disabled={submitting}
                                onClick={() => props.closeDialog()}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        ),
                        ({ submitting }) => (
                            <Button
                                key="save"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >
                                Create
                            </Button>
                        ),
                    ]}
                />
            </DialogContent>
        </Dialog>
    );
};
const customStyles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '100vh',
    },
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
        };
    },
};
export default GenerateDocumentDialog;

const generateDocumentFields = {
    REFERRAL_NOTE: {
        id: 'REFERRAL_NOTE',
        name: 'Referral Note',
        groups: [
            {
                id: 'part_1',
                name: 'Capture Your Notes Below:',
                fields: [
                    {
                        id: 'title',
                        name: 'Title',
                        type: 'text',
                        default: 'Referral Note',
                        optional: false,
                        display_view: 'text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                    {
                        id: 'referral_type',
                        name: 'Referral Type',
                        type: 'drop_down',
                        default: 'None',
                        optional: false,
                        display_view: 'drop_down',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [
                            { id: 'Own Healthcare Provider', name: 'Own Healthcare Provider' },
                            { id: 'Specialist', name: 'Specialist' },
                            {
                                id: 'Optometrist/Ophthalmologist',
                                name: 'Optometrist/Ophthalmologist',
                            },
                            { id: 'Audiologist', name: 'Audiologist' },
                            { id: 'Hospital/Municipal Clinic', name: 'Hospital/Municipal Clinic' },
                            {
                                id: 'Social Worker/EAP/Psychologist',
                                name: 'Social Worker/EAP/Psychologist',
                            },
                            { id: 'X-ray/Radiologist', name: 'X-ray/Radiologist' },
                            { id: 'OMP', name: 'OMP' },
                            { id: 'Other', name: 'Other' },
                        ],
                    },
                    {
                        id: 'notes',
                        name: 'Referral Notes',
                        type: 'multi_line_text',
                        default: 'None',
                        optional: false,
                        display_view: 'multi_line_text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                ],
            },
        ],
    },
    SICK_NOTE: {
        id: 'SICK_NOTE',
        name: 'Sick Note',
        groups: [
            {
                id: 'part_1',
                name: 'Capture Sick Note Below:',
                fields: [
                    {
                        id: 'title',
                        name: 'Title',
                        type: 'text',
                        default: 'Sick Note',
                        optional: false,
                        display_view: 'text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                    {
                        id: 'from_date',
                        name: 'From',
                        type: 'date',
                        default: 'None',
                        optional: false,
                        display_view: 'date',
                        options: [],
                    },
                    {
                        id: 'to_date',
                        name: 'To and Including',
                        type: 'date',
                        default: 'None',
                        optional: false,
                        display_view: 'date',
                        options: [],
                    },
                    {
                        id: 'comment',
                        name: 'Comments',
                        type: 'multi_line_text',
                        default: 'None',
                        optional: false,
                        display_view: 'multi_line_text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                ],
            },
        ],
    },
    SCRIPT: {
        id: 'SCRIPT',
        name: 'Script',
        groups: [
            {
                id: 'part_1',
                name: 'Capture Your Prescription Below:',
                fields: [
                    {
                        id: 'title',
                        name: 'Title',
                        type: 'text',
                        default: 'Script',
                        optional: false,
                        display_view: 'text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                    {
                        id: 'prescription',
                        name: 'Please write the prescription here',
                        type: 'multi_line_text',
                        default: 'None',
                        optional: false,
                        display_view: 'multi_line_text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                    {
                        id: 'additional_comments',
                        name: 'Any additional comments for the pharmacist?\n',
                        type: 'multi_line_text',
                        default: 'None',
                        optional: false,
                        display_view: 'multi_line_text',
                        render_condition: [],
                        calculation_precondition: [],
                        calculation: [],
                        options: [],
                    },
                ],
            },
        ],
    },
};
