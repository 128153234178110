import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SharedStateContext } from '../../App';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { useMedicalHistory } from '../../queries';
import CompleteConsultAction from './SignOffActions/CompleteConsultAction';
import ProceedToSignoffAction from './SignOffActions/ProceedToSignoffAction';
import RequestSignoffAction from './SignOffActions/RequestSignoffAction';
import ProceedToDiseaseMng from './SignOffActions/ProceedToDiseaseMng';

const SignoffBanner = ({ config, props }) => {
    const classes = useStyles();
    const sharedState = useContext(SharedStateContext);
    const [submitting, setSubmitting] = useState(false);
    const { authStore } = useStores();
    const is_stock_module_site = authStore.dsp_details?.is_stock_module_site;
    const patient_id = `${props.consult.patient.primary_identifier.value}`;
    const { isLoading, isError, data, error } = useMedicalHistory(patient_id);
    const medicalHistoryData = data;
    const consultCofStatus = props.consult?.rules_result?.cof_result?.status?.name;
    const renderAction = () => {
        switch (props.consult.consult_status) {
            case 'procedures_completed':
                return (
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                            <RequestSignoffAction
                                consult={props.consult}
                                setSubmitting={setSubmitting}
                                submitting={submitting}
                                role={authStore.selectedRole.role}
                            />
                        </div>
                        <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {consultCofStatus === 'Fit for duty' ? (
                                <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                                    <ProceedToSignoffAction
                                        consult={props.consult}
                                        submitting={submitting}
                                        is_quick_signoff={true}
                                        text={'finalise examination'}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {'signoff_required' in props.consult &&
                            !props.consult.signoff_required ? (
                                <>
                                    <CompleteConsultAction
                                        consult={props.consult}
                                        setSubmitting={setSubmitting}
                                        submitting={submitting}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                );

            case 'awaiting_omp':
                if (['dsp_omp', 'global_omp'].includes(authStore.selectedRole.role)){
                    return (
                        <ProceedToSignoffAction consult={props.consult} submitting={submitting} />
                    );
                } else {
                    return null;
                }
            case 'completed':
                return <ProceedToDiseaseMng consult={props.consult} submitting={submitting} />;
            default:
                return null;
        }
    };

    const medicationListValid = () => {
        const medications = sharedState.medications || [];
        const checkMedicationIsInvalid = (item) => {
            return item.is_batch_item === true && item.batch_stock_moves_captured !== true;
        };

        return !medications.some(checkMedicationIsInvalid);
    };

    const renderBanner = () => {
        if (!medicalHistoryData) {
            return (
                <div className={classes.containerYellow}>
                    <div className={classes.text}>
                        Please capture Medical History, including vaccination history, for patient
                        in Medical and Contextual History widget
                    </div>
                </div>
            );
        }
        if (is_stock_module_site) {
            if (!medicationListValid()) {
                return (
                    <div className={classes.containerYellow}>
                        <div className={classes.text}>
                            Please Capture the Batch Details for Medication and Consumables
                        </div>
                    </div>
                );
            } else {
                return renderConsultBasedBanner();
            }
        } else {
            return renderConsultBasedBanner();
        }
    };

    const renderConsultBasedBanner = () => {
        return (
            <>
                {['procedures_completed', 'awaiting_omp'].indexOf(props.consult.consult_status) >
                -1 ? (
                    <div className={classes.container}>
                        <div className={classes.text}>All Medical Analysis Completed</div>
                        {renderAction()}
                    </div>
                ) : null}
                {['completed'].indexOf(props.consult.consult_status) > -1 ? (
                    <div className={classes.container}>
                        <div className={classes.text}>Sign Off Successful</div>
                        {renderAction()}
                    </div>
                ) : null}
            </>
        );
    };
    return <React.Fragment>{renderBanner()}</React.Fragment>;
};

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.success.main,
        borderRadius: '3px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
    containerYellow: {
        backgroundColor: theme.palette.warning.main,
        borderRadius: '3px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
    text: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
}));

export default SignoffBanner;
