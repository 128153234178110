import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const EditIcon = styled(
    React.forwardRef(({ color = 'currentColor', ...props }, forwardedRef) => (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            {...props}
            ref={forwardedRef}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color} fillRule="nonzero">
                <path d="M16.814 4.593c.316-.298.494-.702.494-1.124 0-.422-.178-.827-.494-1.125l-1.296-1.225a1.758 1.758 0 0 0-2.381 0l-.831.785 3.678 3.473.83-.784zM5.538 8.565v2.55c0 .361.31.654.693.654h2.7c.183 0 .36-.069.49-.191l5.583-5.274-3.68-3.473-5.583 5.277a.636.636 0 0 0-.203.457z" />
                <path d="M17.308 7.846c-.383 0-.693.293-.693.654v5.135c-.001 1.135-.976 2.056-2.178 2.057H3.563c-1.202-.001-2.177-.922-2.178-2.057V3.365c.001-1.135.976-2.056 2.178-2.057H9c.382 0 .692-.293.692-.654C9.692.293 9.382 0 9 0H3.563C1.596.003.003 1.508 0 3.365v10.27C.003 15.492 1.596 16.997 3.563 17h10.874c1.967-.003 3.56-1.508 3.563-3.365V8.5c0-.361-.31-.654-.692-.654z" />
            </g>
        </svg>
    )),
    {}
);
