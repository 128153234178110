import React, { useContext, useState } from 'react';
import DynamicForm from '../../DynamicForm';
import { useNavigate } from 'react-router-dom';
import { AppConfigContext } from '../../../App';
import GoodsReceivingItems from './GoodsReceivingItems';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import { useStores } from '@a2d24/care-at-work-state/stores';

const ReceiveSupplierStock = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const { enqueueSnackbar } = useSnackbar();
    const { authStore } = useStores();
    const [supplierName, setSupplierName] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [invoiceDocument, setInvoiceDocument] = useState(null);

    const handleReceiveSupplierStock = (values) => {
        setSupplierName(values.supplierName);
        setInvoiceNumber(values.invoiceNumber);
        setInvoiceDocument(values.invoice);
    };

    let site_name = authStore.dsp_details.dsp_reference?.replace(/\//g, '_');

    let receive_supplier_stock = {
        id: 'RECEIVE_SUPPLIER_STOCK',
        name: 'Receive Supplier Stock',
        groups: [
            {
                id: 'part_1',
                fields: [
                    {
                        id: 'supplierName',
                        name: 'Supplier Name',
                        type: 'text',
                        default: 'None',
                        optional: false,
                        display_view: 'text',
                    },
                    {
                        id: 'invoiceNumber',
                        name: 'Invoice Number',
                        type: 'text',
                        default: 'None',
                        optional: false,
                        display_view: 'text',
                    },
                    {
                        id: 'invoice',
                        name: 'Upload Invoice Document',
                        type: 'universal_upload',
                        default: 'None',
                        optional: true,
                        metadata: {
                            key: `stock_management/invoices/${site_name}`,
                        },
                    },
                ],
            },
        ],
    };

    const renderReceiveSupplierStock = () => (
        <DynamicForm
            onSubmit={handleReceiveSupplierStock}
            config={{ fields: receive_supplier_stock, defaultValues: {} }}
            actions={[
                ({ submitting }) => (
                    <Button
                        key="next"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                    >
                        Next
                    </Button>
                ),
            ]}
        />
    );

    const supplierDetailsView = () => {
        return (
            <Paper className={classes.rootContainer}>
                <Typography className={classes.helperText}>Receive Supplier Stock</Typography>
                {renderReceiveSupplierStock()}
            </Paper>
        );
    };

    return (
        <>
            {supplierName && invoiceNumber ? (
                <GoodsReceivingItems
                    getStockItems={props.getStockItems}
                    getGRVStockMoves={props.getGRVStockMoves}
                    saveStockReceived={props.saveStockReceived}
                    supplierName={supplierName}
                    invoiceNumber={invoiceNumber}
                    invoiceDocument={invoiceDocument}
                />
            ) : (
                supplierDetailsView()
            )}
        </>
    );
};

export default ReceiveSupplierStock;

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    updateButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    rootContainer: {
        padding: '40px 20px 80px 20px',
    },
    helperText: {
        fontSize: '34px',
        paddingBottom: '40px',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
}));
