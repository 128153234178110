import React from 'react';
import RffReactMultiSelectField from '../ReactFinalFormFields/RffReactMultiSelectField';

const AdhocProcedureSelector = ({ id, options, onSelect, values }) => {
    return (
        <RffReactMultiSelectField
            id={id}
            options={options}
            optional={false}
            onSelect={(values, meta) => {
                onSelect(values, meta);
            }}
            isClearable={false}
            controlShouldRenderValue={false}
            values={values}
        />
    );
};

export default AdhocProcedureSelector;
