import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CheckCirlceIcon from '@mui/icons-material/CheckCircle';
import DynamicForm from './DynamicForm';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import Divider from './Divider';
import { AppConfigContext } from '../App';

const RegisterPatient = ({ config, props }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const appConfig = useContext(AppConfigContext);

    const handleRegister = async (values) => {
        await Recycle.rpcPostV2({
            controller: 'PatientController',
            method: 'save_patient',
            kwargs: { patient: values },
        })
            .then((res) => {
                // setOpen(true);
                appConfig.setState({ ...appConfig, currentBookingID: props.booking.booking_id });
                navigate(
                    `/booking/${props.booking.booking_id}/consent/${res['patient'].primary_identifier.value}`
                );
            })
            .catch((err) => {
                console.log(err.response.data.message);
            });
    };

    const handleClose = () => {
        setOpen(false);
        Recycle.rpcPostV2({
            controller: 'BookingController',
            method: 'update_booking_status',
            kwargs: { new_status: 'arrived', booking_id: props.booking.booking_id },
        })
            .then((res) => {
                navigate('/dashboard');
            })
            .catch((err) => {});
    };

    const handleCancel = () => {
        navigate(`/appointments/${props.booking.booking_id}`);
    };

    const handleBackdropClick = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleClose();
        }
    };

    return (
        <React.Fragment>
            <DynamicForm
                onSubmit={handleRegister}
                config={{ fields: registerForm, defaultValues: config.defaultValues }}
                actions={[
                    <Button key={1} variant="outlined" color="primary" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key={2} variant="contained" color="primary" type="submit">
                        Proceed to register
                    </Button>,
                ]}
            />
            <Modal
                onClose={handleBackdropClick}
                className={classes.modal}
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Paper elevation={1} className={classes.paper}>
                        <CheckCirlceIcon className={classes.checkIcon} />
                        <div className={classes.title}>Registration Successful</div>
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                        <Button color="primary" onClick={handleClose}>
                            Done
                        </Button>
                    </Paper>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    divider: {
        width: '100%',
    },
    checkIcon: {
        color: theme.palette.success.main,
    },
}));

const registerForm = {
    id: 'PATIENT_REGISTER',
    name: 'Register Patient',
    groups: [
        {
            id: 'part_1',
            name: 'Personal Particulars of Applicant',
            fields: [
                {
                    id: 'first_name',
                    name: 'First Name',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                },
                {
                    id: 'last_name',
                    name: 'Last Name',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                },
                {
                    id: 'contact_number',
                    name: 'Contact Number',
                    type: 'phonenumber_field',
                    validation: ['valid_phone_number', 'get', 'contact_number'],
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                },
                {
                    id: 'email',
                    name: 'Email',
                    type: 'text',
                    default: 'None',
                    validation: [
                        '|',
                        'regex',
                        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
                        'get',
                        'email',
                        '!',
                        'get',
                        'email',
                    ],
                    optional: true,
                    display_view: 'text',
                },
                {
                    id: 'gender',
                    name: 'Gender',
                    type: 'radio',
                    default: 'None',
                    optional: false,
                    display_view: 'radio',
                    options: [
                        {
                            id: 'male',
                            name: 'Male',
                        },
                        {
                            id: 'female',
                            name: 'Female',
                        },
                    ],
                },
                {
                    id: 'date_of_birth',
                    name: 'Date of Birth',
                    type: 'date',
                    default: '1990/01/01',
                    optional: false,
                    display_view: 'text',
                    validation: ['!=', 'get', 'date_of_birth', 'undefined'],
                    render_condition: ['=', 'get', 'identifier_type', 'PPN'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
                {
                    id: 'identifier_type',
                    name: 'Identity Type',
                    type: 'radio',
                    default: 'None',
                    optional: false,
                    display_view: 'radio',
                    options: [
                        {
                            id: 'NNZAF',
                            name: 'ID Number',
                        },
                        {
                            id: 'PPN',
                            name: 'Passport Number',
                        },
                    ],
                },
                {
                    id: 'identifier',
                    name: 'ID Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    validation: ['valid_said', 'get', 'identifier'],
                    render_condition: ['=', 'get', 'identifier_type', 'NNZAF'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
                {
                    id: 'identifier',
                    name: 'Passport Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    render_condition: ['=', 'get', 'identifier_type', 'PPN'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
            ],
        },
    ],
};

export default RegisterPatient;
