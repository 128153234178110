export const allergiesMapping = {
  "none": "None",
  "abacavir_sulphate": "Abacavir Sulphate",
  "ace_inhibitors__eg_enalapril_captropril_perindopril": "ACE inhibitors ( eg enalapril, captropril, perindopril)",
  "acriflavine": "Acriflavine",
  "acyclovir": "Acyclovir",
  "allopurinol": "Allopurinol",
  "amikacin_sulphate": "Amikacin Sulphate",
  "ammonium_chloride": "Ammonium Chloride",
  "amoxicillin": "Amoxicillin",
  "ampicillin": "Ampicillin",
  "animal_dander_and_cockroaches": "Animal Dander And Cockroaches",
  "aspirin": "Aspirin",
  "aspirin_acetylsalicylic_acid": "Aspirin (Acetylsalicylic Acid)",
  "atracurium": "Atracurium",
  "azithromycin": "Azithromycin",
  "benzathine_penicillin": "Benzathine Penicillin",
  "benzoyl_peroxide": "Benzoyl Peroxide",
  "benzyl_penicillin": "Benzyl Penicillin",
  "betamethasone_dipropionate": "Betamethasone Dipropionate",
  "betamethasone_valerate": "Betamethasone Valerate",
  "bleomycin_sulphate": "Bleomycin Sulphate",
  "brompheniramine_maleate": "Brompheniramine Maleate",
  "cabazitaxel": "Cabazitaxel",
  "carbamazepine": "Carbamazepine",
  "cefaclor": "Cefaclor",
  "cefadroxil": "Cefadroxil",
  "cefazolin_sodium": "Cefazolin Sodium",
  "cefepime_hcl": "Cefepime Hcl",
  "cefixime": "Cefixime",
  "cefotaxime_sodium": "Cefotaxime Sodium",
  "cefoxitin_sodium": "Cefoxitin Sodium",
  "cefpodoxime_proxetil": "Cefpodoxime Proxetil",
  "cefprozil": "Cefprozil",
  "ceftaroline_fosamil": "Ceftaroline Fosamil",
  "ceftazidime": "Ceftazidime",
  "ceftolozane": "Ceftolozane",
  "ceftriaxone_sodium": "Ceftriaxone Sodium",
  "cefuroxime_axetil": "Cefuroxime Axetil",
  "cefuroxime_sodium": "Cefuroxime Sodium",
  "celecoxib": "Celecoxib",
  "cephalexin": "Cephalexin",
  "cephalosporins": "Cephalosporins",
  "cephradine": "Cephradine",
  "cetuximab": "Cetuximab",
  "chloramphenicol": "Chloramphenicol",
  "chlorpheniramine_maleate": "Chlorpheniramine Maleate",
  "ciclosporin": "Ciclosporin",
  "ciprofloxacin_hydrochloride": "Ciprofloxacin Hydrochloride",
  "clarithromycin": "Clarithromycin",
  "clindamycin_hcl": "Clindamycin Hcl",
  "clindamycin_phosphate": "Clindamycin Phosphate",
  "clopidogrel_hydrogen_sulphate": "Clopidogrel Hydrogen Sulphate",
  "cloxacillin_sodium": "Cloxacillin Sodium",
  "codeine": "Codeine",
  "codeine_phosphate": "Codeine Phosphate",
  "dapsone": "Dapsone",
  "dexamethasone": "Dexamethasone",
  "diclofenac": "Diclofenac",
  "diclofenac_diethylammonia": "Diclofenac Diethylammonia",
  "diclofenac_free_acid": "Diclofenac Free Acid",
  "diclofenac_hydroxyethylpyrrolidine": "Diclofenac Hydroxyethylpyrrolidine",
  "diclofenac_potassium": "Diclofenac Potassium",
  "diclofenac_sodium": "Diclofenac Sodium",
  "dihydrocodeine_tartrate": "Dihydrocodeine Tartrate",
  "diphenhydramine_hcl": "Diphenhydramine Hcl",
  "dipyridamole": "Dipyridamole",
  "docetaxel": "Docetaxel",
  "dolutegravir": "Dolutegravir",
  "doxycycline": "Doxycycline",
  "dpenicillamine": "D-Penicillamine",
  "dust_mites": "Dust Mites",
  "eggs": "Eggs",
  "erythromycin": "Erythromycin",
  "erythromycin_estolate": "Erythromycin Estolate",
  "erythromycin_lactobionate": "Erythromycin Lactobionate",
  "ethambutol_hcl": "Ethambutol Hcl",
  "euphorbium": "Euphorbium",
  "fish": "Fish",
  "flucloxacillin": "Flucloxacillin",
  "gatifloxacin": "Gatifloxacin",
  "gemifloxacin_mesylate": "Gemifloxacin Mesylate",
  "gentamicin_sulphate": "Gentamicin Sulphate",
  "guaifenesin_g_guaiacolate": "Guaifenesin (G Guaiacolate)",
  "honey": "Honey",
  "hydralazine_hcl": "Hydralazine Hcl",
  "hydrocortisone_acetate": "Hydrocortisone Acetate",
  "hydroxychloroquine": "Hydroxychloroquine",
  "ibuprofen": "Ibuprofen",
  "infliximab": "Infliximab",
  "insect_sting": "Insect Sting",
  "insulin_aspart": "Insulin Aspart",
  "insulin_glargine": "Insulin Glargine",
  "insulin_glargine_biosimilar": "Insulin Glargine Biosimilar",
  "insulin_lispro": "Insulin Lispro",
  "iodine": "Iodine",
  "isoniazid": "Isoniazid",
  "kanamycin_sulphate": "Kanamycin Sulphate",
  "lamivudine": "Lamivudine",
  "lamotrigine": "Lamotrigine",
  "latex": "Latex",
  "leflunomide": "Leflunomide",
  "levofloxacin": "Levofloxacin",
  "lincomycin_hcl": "Lincomycin Hcl",
  "lomefloxacin_hcl": "Lomefloxacin Hcl",
  "loracarbef": "Loracarbef",
  "lymecycline": "Lymecycline",
  "methotrexate_sodium": "Methotrexate Sodium",
  "metoclopramide": "Metoclopramide",
  "metronidazole_flagyl": "metronidazole (Flagyl)",
  "milk": "Milk",
  "minocycline_hcl": "Minocycline Hcl",
  "mold": "Mold",
  "morphine": "Morphine",
  "morphine_sulphate": "Morphine Sulphate",
  "moxifloxacin_hcl": "Moxifloxacin Hcl",
  "mycophenolate_mofetil": "Mycophenolate Mofetil",
  "nalidixic_acid": "Nalidixic Acid",
  "naproxen": "Naproxen",
  "netilmicin": "Netilmicin",
  "nevirapine": "Nevirapine",
  "nitrofurantoin": "Nitrofurantoin",
  "nonsteroidal_antiinflammatory": "Non-Steroidal Anti-Inflammatory",
  "norfloxacin": "Norfloxacin",
  "nuts": "Nuts",
  "ofloxacin": "Ofloxacin",
  "oxaliplatin": "Oxaliplatin",
  "oxytetracycline_hcl": "Oxytetracycline Hcl",
  "paclitaxel_albuminbound": "Paclitaxel Albumin-Bound",
  "paclitaxel_proteinbound": "Paclitaxel Protein-Bound",
  "paracetamol": "Paracetamol",
  "paracetamolacetaminophen": "Paracetamol/Acetaminophen",
  "penicillin": "Penicillin",
  "perfumeshoushold_chemicals": "Perfumes/houshold chemicals",
  "pethidine": "Pethidine",
  "phenoxymethyl_penicillin": "Phenoxymethyl Penicillin",
  "pholcodine": "Pholcodine",
  "pimecrolimus": "Pimecrolimus",
  "pipemidic_acid": "Pipemidic Acid",
  "piperacillin": "Piperacillin",
  "pollen": "Pollen",
  "prochlorperazine_maleate": "Prochlorperazine Maleate",
  "protamine_sulph": "Protamine Sulph",
  "pyrazinamide": "Pyrazinamide",
  "pyrimethamine": "Pyrimethamine",
  "quinolones": "Quinolones",
  "rifampicin_rifampin": "Rifampicin (Rifampin)",
  "rituximab": "Rituximab",
  "roxithromycin": "Roxithromycin",
  "salicylamide": "Salicylamide",
  "scoline": "Scoline",
  "shellfish": "Shellfish",
  "sirolimus": "Sirolimus",
  "soy": "Soy",
  "streptomycin": "Streptomycin",
  "succinylcholine": "Succinylcholine",
  "sulfadiazine": "Sulfadiazine",
  "sulfonamide_sulfa": "sulfonamide (sulfa)",
  "sulphamethoxazole": "Sulphamethoxazole",
  "sulphonamides": "Sulphonamides",
  "surgical_appliances_and_products": "Surgical Appliances And Products",
  "tacrolimus": "Tacrolimus",
  "telithromycin": "Telithromycin",
  "temafloxacin": "Temafloxacin",
  "tetracycline": "Tetracycline",
  "tigecycline": "Tigecycline",
  "tobramycin": "Tobramycin",
  "triprolidine_hcl": "Triprolidine Hcl",
  "vancomycin_hcl": "Vancomycin Hcl",
  "vecuronium": "Vecuronium",
  "wheat": "Wheat",
  "zidovudine_azt": "Zidovudine (Azt)",
  "other": "Other"
}
