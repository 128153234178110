import React from 'react';
import { Field } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';
import InputLabel from '@mui/material/InputLabel';

const FreeTextField = ({ id, label, description= null, variant='standard', optional, unit, validate }) => {

    return (
        <FieldLayout>

            <Field name={id} type="text" validate={validate ? validate : null}>
                {({ input, meta }) => {
                    return (
                        <div>
                        <InputLabel style={{color: 'black'}} size="normal">
                            <b>{label}</b>
                        </InputLabel>
                        <InputLabel size="small" shrink={true}>
                            {description}
                        </InputLabel>

                            <div>
                                <TextField
                                    variant={variant}
                                    name={input.name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment key={`${id}-Adornment`} position="end">
                                                <label>{unit}</label>
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{ marginBottom: '1rem' }}
                                    autoComplete="off"
                                    required={optional === false}
                                />
                            </div>
                        </div>
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default FreeTextField;
