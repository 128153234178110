import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MedicalHistory from './MedicalHistory';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

const MedicalHistoryDialog = ({ open, handleMedicalHistoryDialog, patient, consult_id }) => {
    const classes = useStyles();
    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                Medical and Contextual History
                <IconButton
                    aria-label="close"
                    onClick={handleMedicalHistoryDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div>
                    <MedicalHistory
                        setClose={handleMedicalHistoryDialog}
                        patient={patient}
                        consult_id={consult_id}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },
}));

export default MedicalHistoryDialog;
