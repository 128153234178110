import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Icon } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        height: '100%',
        backgroundColor: '#e3e3e3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timelineContainer: {
        backgroundColor: '#e3e3e3',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
    },
    title: {
        fontSize: '24px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    description: {
        fontSize: '16px',
        color: theme.palette.primary.main,
    },
    loader: {
        color: theme.palette.primary.main,
    },
}));

const CareActivitiesTimeline = ({ config, props }) => {
    const classes = useStyles();

    const { activities, disease, plan_pathway } = props.care_plan;
    const consult = props.consult;
    const current_care_activity = props.current_care_activity;

    const getVisitMonthFromCareActivity = (careActivity) => {
        console.log(careActivity);
        return careActivity.instantiates
            ? ` - Month: ${careActivity.instantiates.plan_action_identifier.substring(
                  careActivity.instantiates.plan_action_identifier.lastIndexOf('A') + 1
              )}`
            : '';
    };
    return (
        <div>
            <Typography style={{ textTransform: 'capitalize' }} variant="h4">
                {disease} Care Plan for {consult.patient.first_name} {consult.patient.last_name}
            </Typography>
            <div className={classes.timelineContainer}>
                <VerticalTimeline layout="1-column">
                    <VerticalTimelineElement
                        key={current_care_activity.activity_id}
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        date={`Current: ${format(
                            parseISO(current_care_activity.activity_date),
                            'dd MMMM yyyy'
                        )}`}
                        icon={
                            <Icon>
                                {current_care_activity.goal.type === 'chronic_condition'
                                    ? 'favorite'
                                    : 'fact_check'}
                            </Icon>
                        }
                        iconClassName={classes.icon}
                    >
                        <div className={classes.title}>
                            {current_care_activity.name}{' '}
                            {getVisitMonthFromCareActivity(current_care_activity)}
                        </div>
                        {/*<div className={classes.description}>{item.description}</div>*/}
                    </VerticalTimelineElement>
                    {activities.map((item) => (
                        <VerticalTimelineElement
                            key={item.activity_id}
                            date={`Scheduled: ${format(
                                parseISO(item.activity_date),
                                'dd MMMM yyyy'
                            )}`}
                            icon={
                                <Icon>
                                    {item.goal.type === 'chronic_condition'
                                        ? 'favorite'
                                        : 'fact_check'}
                                </Icon>
                            }
                            iconClassName={classes.icon}
                        >
                            <div className={classes.title}>
                                {item.name} {getVisitMonthFromCareActivity(item)}
                            </div>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </div>
        </div>
    );
};

export default CareActivitiesTimeline;
