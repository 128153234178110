import MaterialTable from '@material-table/core';
import styles from './styles';
import { useEffect, useState } from 'react';
import { TableContainer } from '@mui/material';
import DiseaseCell from './DiseaseCell';
import DiseaseTypeCell from './DiseaseTypeCell';
import SubPathwayCell from './SubPathwayCell';
import CheckBoxCell from './CheckBoxCell';
import { reFormat } from '../utils';
import { updatePlanOnChange } from './commonFunctions';

const AdditionalPlanSection = ({ plans, onSelectionsChange }) => {
    const classes = styles();
    const [additionalPlans, setAdditionalPlans] = useState(plans);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const onSubPathwayChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setAdditionalPlans(
            updatePlanOnChange(additionalPlans, tempPlan, [{ attribute: 'disease', isEqual: true }])
        );
    };
    const onSelectPlanChange = (updatedPlan) => {
        const tempPlan = { ...updatedPlan };
        delete tempPlan['tableData'];
        setAdditionalPlans(
            updatePlanOnChange(additionalPlans, tempPlan, [
                { attribute: 'disease', isEqual: true },
                { attribute: 'pathway', isEqual: true },
                { attribute: 'isSelected', isEqual: false },
            ])
        );
    };
    useEffect(() => {
        setSelectedPlans(
            additionalPlans.filter((plan) => {
                return plan.isSelected;
            })
        );
    }, [additionalPlans]);
    useEffect(() => {
        onSelectionsChange(selectedPlans);
    }, [selectedPlans]);
    const columns = [
        {
            field: 'disease',
            render: (rowData) => <DiseaseCell rowData={rowData} variant={'standard'} />,
        },
        {
            field: 'type',
            render: (rowData) => <DiseaseTypeCell rowData={rowData} variant={'standard'} />,
        },
        {
            field: 'pathway',
            render: (rowData) => (
                <SubPathwayCell
                    rowData={rowData}
                    overridable={false}
                    onSubPathwayChange={onSubPathwayChange}
                    originalPlan={null}
                    isDefault={reFormat(rowData.pathway).toLowerCase() === 'default'}
                    pathwayOptions={rowData.pathway_options}
                    variant={'dark'}
                    enabled={rowData.isSelected ? rowData.isSelected : false}
                />
            ),
        },
        {
            field: 'action_select',
            render: (rowData) => (
                <CheckBoxCell
                    rowData={rowData}
                    onCheckBoxChange={onSelectPlanChange}
                    checkedMessage="Deselect"
                    uncheckedMessage="Select"
                    variant={'dark'}
                />
            ),
        },
    ];
    return (
        <MaterialTable
            title={<div className={classes.mainTextDiv}>Additional Plans</div>}
            columns={columns}
            data={additionalPlans}
            options={{
                padding: 'dense',
                showTitle: true,
                showEmptyDataSourceMessage: true,
                filtering: false,
                header: false,
                paging: false,
                search: false,
                selection: false,
                pageSize: additionalPlans.length,
                rowStyle: {
                    backgroundColor: 'white',
                    borderRadius: '5px',
                },
            }}
            components={{
                Container: (props) => (
                    <TableContainer {...props} className={classes.tableContainer} />
                ),
            }}
        />
    );
};

export default AdditionalPlanSection;
