import { useQuery } from '@tanstack/react-query';
import { fetchMedicalHistory, fetchVaccinationHistory } from '../Apis/MedicalHistory';
import {
    fetchAdditionalConditions,
    fetchCareActivity,
    fetchPatientConditions,
} from '../Apis/CarePlanner';

import {
    fetchClients,
    fetchDivisionOptions,
    fetchMedicalRoles,
    fetchSiteOptions,
    fetchClientDivisionSiteOptions,
    fetchDiseases,
    fetchServices,
    fetchConsultTypes,
    fetchClientProcedures,
} from '../Apis/ManualBooking';
import { fetchPatientSummary } from '../Apis/PatientSummary';
import { fetchConsultDocuments } from '../Apis/Consult';

export const useMedicalHistory = (patient_id) => {
    const queryIndex = ['medicalHistory', patient_id];

    const optionsWhenNotReady = {
        cacheTime: 0,
        enabled: false,
    };

    return useQuery(queryIndex, () => fetchMedicalHistory(patient_id), {
        staleTime: 1000 * 60 * 5,
        // ...(readyToFetch ? {} : optionsWhenNotReady),
    });
};

export const useVaccinationHistory = (patient_id) => {
    const queryIndex = ['vaccinationHistory', patient_id];

    const optionsWhenNotReady = {
        cacheTime: 0,
        enabled: false,
    };

    return useQuery(queryIndex, () => fetchVaccinationHistory(patient_id), {
        staleTime: 1000 * 60 * 5,
        // ...(readyToFetch ? {} : optionsWhenNotReady),
    });
};

export const useCommonProcedure = (patient_id) => {
    const queryIndex = ['commonProcedure', patient_id];

    const optionsWhenNotReady = {
        cacheTime: 0,
        enabled: false,
    };

    return useQuery(queryIndex, () => fetchMedicalHistory(patient_id), {
        staleTime: 1000 * 60 * 5,
        // ...(readyToFetch ? {} : optionsWhenNotReady),
    });
};

export const useCareActivity = (patient_id, care_activity_id) => {
    const queryIndex = ['careActivity', patient_id, care_activity_id];

    return useQuery(queryIndex, () => fetchCareActivity(patient_id, care_activity_id), {
        cacheTime: 300000,
        retry: false,
    });
};

export const useCurrentPatientConditions = (patient_id) => {
    const queryIndex = ['patientMedicalCondition', patient_id];

    return useQuery(queryIndex, () => fetchPatientConditions(patient_id), {
        cacheTime: 0,
    });
};

export const useAdditionalConditions = (current_plans) => {
    const queryIndex = ['additionalConditions', { current_plans: current_plans }];

    return useQuery(queryIndex, () => fetchAdditionalConditions(current_plans), {
        cacheTime: 0,
    });
};

export const useServices = () => {
    const queryIndex = ['walkInServices'];
    return useQuery(queryIndex, () => fetchServices(), { retry: 5, retryDelay: 3000 });
};

export const useClients = () => {
    const queryIndex = ['clients'];
    return useQuery(queryIndex, () => fetchClients(), { retry: 5, retryDelay: 3000 });
};

export const useConsultTypes = () => {
    const queryIndex = ['consultTypes'];
    return useQuery(queryIndex, () => fetchConsultTypes(), { retry: 5, retryDelay: 3000 });
};

export const useDiseases = () => {
    const queryIndex = ['diseases'];
    return useQuery(queryIndex, () => fetchDiseases(), { retry: 5, retryDelay: 3000 });
};

export const useDivisionOptions = (client_id) => {
    const queryIndex = ['divisionOptions', client_id];
    return useQuery(queryIndex, () => fetchDivisionOptions(client_id), {
        retry: 5,
        retryDelay: 3000,
        enabled: !!client_id,
        staleTime: 0,
    });
};

export const useSiteOptions = (client_id, are_divisions, division_id) => {
    let queryIndex;
    let queryFn;
    let queryOptions;
    if (are_divisions) {
        queryIndex = ['siteOptions', client_id, division_id];
        queryFn = () => fetchClientDivisionSiteOptions(client_id, division_id);
        queryOptions = {
            retry: 5,
            retryDelay: 3000,
            enabled: !!client_id && !!division_id,
            staleTime: 0,
        };
    } else {
        queryIndex = ['siteOptions', client_id];
        queryFn = () => fetchSiteOptions(client_id);
        queryOptions = {
            retry: 5,
            retryDelay: 3000,
            enabled: !!client_id,
            staleTime: 0,
        };
    }

    return useQuery(queryIndex, queryFn, queryOptions);
};

export const useClientProcedures = (client) => {
    const queryIndex = ['clientProcedures', client];
    return useQuery(queryIndex, () => fetchClientProcedures(client), {
        retry: 5,
        retryDelay: 3000,
        enabled: !!client,
    });
};
export const useMedicalRoles = () => {
    const queryIndex = ['medicalRoles'];
    return useQuery(queryIndex, () => fetchMedicalRoles(), { retry: 5, retryDelay: 3000 });
};

export const usePatientSummary = (patient_id) => {
    const queryIndex = ['patientSummary', patient_id];

    return useQuery(queryIndex, () => fetchPatientSummary(patient_id), {
        staleTime: 1000 * 60 * 5,
        // ...(readyToFetch ? {} : optionsWhenNotReady),
    });
};


export const useConsultDocuments = (consult_id) => {
    const queryIndex = ['consult_documents', consult_id];

    return useQuery(queryIndex, () => fetchConsultDocuments(consult_id), {
        refetchInterval: 1000*10
        // ...(readyToFetch ? {} : optionsWhenNotReady),
    });
};
