import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const getPresignedUrl = (consult_id, procedure_detail_id, content_type, media_extension) => {
    return axios
        .post(`${API_URL}/ProcedureController/get_presigned_post_url_for_procedure_details`, {
            consult_id: consult_id,
            procedure_detail_id: procedure_detail_id,
            content_type: content_type,
            file_extension: media_extension,
        })
        .then((r) => {
          return r.data[0];
        })
};

