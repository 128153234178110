import { default as Grid } from '@mui/material/Unstable_Grid2';
import React from 'react';

const RffFieldError = ({ meta }) => {
    return (
        <div>
            {meta.error && meta.dirty && meta.modified && (
                <label
                    style={{
                        color: '#d29292',
                        fontSize: '12px',
                        display: 'block',
                        fontWeight: 400,
                        marginTop: '3px',
                        textAlign: 'left',
                    }}
                >
                    {meta.error}
                </label>
            )}
        </div>
    );
};

export default RffFieldError;
