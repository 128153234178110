import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import CalendarConfigForm from './CalendarConfigForm';
import AdvancedSettingsDialog from '../AdvancedSettingsDialog';
import {
    updateEventType,
    updateBlockedSlots,
    updateCalendar,
    useCalendar,
    saveClientsServiced,
} from '../../api';
import { useSnackbar } from 'notistack';
import { useStores } from '@a2d24/care-at-work-state/stores';
import useStyles from '../styles';

// NOTE: ALL DURATIONS WILL BE IN SECONDS

const CalendarConfig = ({props}) => {
    const classes = useStyles();
    const { authStore } = useStores();
    authStore.setCalendarId(props.calendar_id);
    const { enqueueSnackbar } = useSnackbar();
    const calendar = useCalendar(props.calendar_id);
    const [viewAdvancedSettings, setViewAdvancedSettings] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const eventTypes = (calendar.data??{}).event_types??[];
    const saveCalendarConfig = (values)=>{
        setSubmitting(true);
        const workingHourKeys = Object.keys(values).filter((valueKey)=>{
            return valueKey.endsWith('_working_hours')
        })
        const calendarAvailability = [];
        const eventTypeAvailability = {};
        const eventTypeBlockedSlots = {};
        for(const eventType of eventTypes){
            eventTypeAvailability[eventType.event_type_id]=[];
            eventTypeBlockedSlots[eventType.event_type_id]=[];
        }
        for(const workingHourKey of workingHourKeys){
            for(const workingHours of values[workingHourKey]){
                for(const event_type of workingHours.event_types??[]){
                    eventTypeAvailability[event_type].push(
                        {
                            day:workingHourKey.split('_')[0],
                            ...workingHours
                        }
                    )
                }
            }
        }

        (values.unavailability??[]).forEach((unavailability)=>{
            (unavailability.event_types??[]).forEach((event_type)=>{
                eventTypeBlockedSlots[event_type].push(unavailability)
            })
        })

        Promise.all(
            [
                updateCalendar(
                    {
                        calendar_id:props.calendar_id,
                        calendar_version:(calendar.data??{}).version,
                        availability:calendarAvailability,
                        meta_settings:{
                           clients_serviced: values.clients_serviced,
                           contact_number: values.contact_number
                        },
                        block_public_holidays:values.block_public_holidays
                    }
                ),
                ...Object.keys(eventTypeBlockedSlots).map((event_type)=>{
                    return updateBlockedSlots(
                        {
                            calendar_id:props.calendar_id,
                            event_type_id:event_type,
                            blocked_slots: eventTypeBlockedSlots[event_type]
                        }
                    )
                }),
                ...eventTypes.map((eventType)=>{
                    return updateEventType(
                    {
                        calendar_id: props.calendar_id,
                        event_type_id: eventType.event_type_id,
                        event_type_version: eventType.version,
                        availability: eventTypeAvailability[eventType.event_type_id]
                    })
                }),
                saveClientsServiced({clients_serviced: values.clients_serviced})
            ]
        ).then((values)=>{
            enqueueSnackbar(`Calendar updated`, { variant: 'success' })
            calendar.refetch();
        }).catch(()=>{
            enqueueSnackbar(`Failed to update calendar`, { variant: 'error' })
        }).finally(()=>{
            setSubmitting(false)
        })
    }
    return (
        <>
            <div className={classes.pageContainer} style={{ width: '100%' }}>
            <Form
                mutators={{ ...arrayMutators }}
                onSubmit={(values) => {
                    saveCalendarConfig(values);
                }}
                setViewAdvanced={setViewAdvancedSettings}
                disableSave={submitting}
                component={CalendarConfigForm}
                initialValues={calendar.data ?? {}}
                eventTypes={eventTypes}
            />
            </div>
            <AdvancedSettingsDialog
                open={viewAdvancedSettings}
                handleDialogClose={()=>{setViewAdvancedSettings(false)}}
                calendar_id={props.calendar_id}
                eventTypes={eventTypes}
            />
        </>

    );
};


export default CalendarConfig;
