import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaterialPaper from '@mui/material/Paper';
import Widget from './index';

const Paper = ({ children, config, props }) => {
    const classes = useStyles();
    return (
        // If the component was used directly via children, render that, else use config to render
        <MaterialPaper className={classes.container}>
            {config.widgets
                ? config.widgets.map((child) => (
                      <Widget key={child.id} config={child} props={props} />
                  ))
                : null}
        </MaterialPaper>
    );
};

export const PaperContainer = ({ children, config = {}, props }) => {
    const classes = useStyles();
    return (
        <MaterialPaper className={classes.container}>
            {children ? children : null}
            {config.widgets
                ? config.widgets.map((widget) => (
                      <Widget config={widget} props={props} key={widget.id} />
                  ))
                : null}
        </MaterialPaper>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
    },
}));

export default Paper;
