import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';

const ReactMultiSelectAdapter = ({ input, isRequired = true, ...rest }) => {
    const classes = useStyles();

    const { onChange, ...restInput } = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    const getObjectsfromValues = (values, options) => {
        const value_object = [];
        if (Array.isArray(values)) {
            for (const value of values) {
                value_object.push(options.find((o) => o.value === value));
            }
        }
        return value_object;
    };

    React.useEffect(() => {
        const optionsFromIDs = getObjectsfromValues(restInput.value, rest.options);
        if (optionsFromIDs !== undefined) {
            setSelectedOption(optionsFromIDs);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options]);

    const internalOnChange = (val) => {
        const values = Array.isArray(val) ? val.map((x) => x.value) : [];
        onChange(values);
    };
    return (
        <>
            <div>
                {
                    rest.label?
                    <InputLabel style={{color: 'black'}} size="normal">
                        <b>{rest.label}</b>
                    </InputLabel>
                    :
                    null
                }
                <InputLabel size="small" shrink={true}>
                    {rest.description}
                </InputLabel>
                <Select
                    {...restInput}
                    {...rest}
                    styles={customStyles}
                    isClearable={true}
                    isSearchable={true}
                    onChange={internalOnChange}
                    value={selectedOption}
                    isRequired={isRequired}
                    isMulti
                />
                {
                    // Hack - Select does not support required
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={(selectedOption ?? []).length !== 0 ? selectedOption : []}
                        required={isRequired}
                        // readOnly
                        onChange={() => {}}
                    />
                }
            </div>
        </>
    );
};

const MultiSelector = ({ id, label, description=null, options, optional = false }) => {
    return (
        <Field
            name={id}
            label={label}
            description={description}
            optional={optional}
            options={options}
            component={ReactMultiSelectAdapter}
            isRequired={!optional}
            fullWidth
        ></Field>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem',
        },
    };
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            width: '100%',
        };
    },
};

export default MultiSelector;
