import styles from './styles';
import { AllergiesAndDiseases } from './Sections/AllergiesAndDiseases';
import { VitalSigns } from './Sections/VitalSigns';
import { PrimaryHealthCare } from './Sections/PrimaryHealthCare';
import { VisualAcuity } from './Sections/VisualAcuity';
import { FitnessStatus } from './Sections/FitnessStatus';
import { Spirometry } from './Sections/Spirometry';
import { Audiometry } from './Sections/Audiometry';
import TableOfContents from './TableOfContents';

const PatientSummary = ({patient_summary, ...props})=>{
    const classes = styles();
    return(
        <div className={classes.SummaryFullContainer}>
                <TableOfContents
                    items={
                        [
                            {
                                title: 'Allergies & diseases',
                                content: <AllergiesAndDiseases demographics={patient_summary?.patient_summary??{}}/>
                            },
                            {
                                title: 'Vital signs',
                                content: <VitalSigns vital_signs={patient_summary?.vital_signs??{}}/>
                            },
                            {
                                title: 'Primary healthcare',
                                content: <PrimaryHealthCare primary_health_care={patient_summary?.primary_healthcare??{}}/>
                            },
                            {
                                title: 'Spirometry',
                                content: <Spirometry spirometry={patient_summary?.occupational_health_history_spirometry??{}}/>
                            },
                            {
                                title: 'Audiometry',
                                content: <Audiometry audiometry={patient_summary?.occupational_health_history_audiometry??{}}/>
                            },
                            {
                                title: 'Visual Acuity',
                                content: <VisualAcuity visual_acuity={patient_summary?.occupational_health_history_visual_acuity??{}}/>
                            },
                            {
                                title: 'Fitness Status',
                                content: <FitnessStatus fitness_status={patient_summary?.fitness_status??{}}/>
                            },

                        ]
                    }
                />
        </div>
    )

}

export default PatientSummary;
