import React, { createRef, useEffect, useRef, useState } from 'react';
import { styled } from '@a2d24-ui/theme';

const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

const scrollTo = (ele) => {
    ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });
};

function TableOfContents({ items, children }) {
    const [visibleSection, setVisibleSection] = useState();
    const [sectionRefs, setSectionRefs] = useState([]);

    const headerRef = useRef(null);

    useEffect(() => {
        let newSectionRefs = [];

        items.forEach((item) => {
            newSectionRefs.push({ sectionName: item.title, ref: createRef() });
        });
        setSectionRefs(newSectionRefs);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = headerRef.current.scrollTop;

            const selected = sectionRefs.find(({ ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition >= offsetTop && scrollPosition < offsetBottom;
                }
            });

            if (selected && selected.sectionName !== visibleSection) {
                setVisibleSection(selected.sectionName);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [visibleSection, sectionRefs]);

    const renderContent = () => {
        return items.map((item, index) => {
            return (
                <React.Fragment key={index}>
                    <SectionDiv
                        id={item.title}
                        ref={
                            sectionRefs.find(({ sectionName }) => sectionName === item.title)
                                ?.ref || null
                        }
                    >
                        {item.content}
                    </SectionDiv>
                </React.Fragment>
            );
        });
    };

    return (
        <TableOfContentsDiv>
            <HeaderDiv>
                <Headings>
                    {items.map((item, index) => (
                        <HeaderLink
                            key={index}
                            type="button"
                            selected={visibleSection === item.title}
                            onClick={() => {
                                let section = sectionRefs.find(
                                    ({ sectionName }) => sectionName === item.title
                                );
                                section && scrollTo(section.ref.current);
                            }}
                        >
                            {item.title}
                        </HeaderLink>
                    ))}
                </Headings>
                {children}
            </HeaderDiv>
            <Line />
            <ContentDiv ref={headerRef}>
                {renderContent()}

            </ContentDiv>
        </TableOfContentsDiv>
    );
}

export default TableOfContents;

const TableOfContentsDiv = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    display: 'grid',
    height: '100%',
    gridTemplateAreas: '"content"',
    gridTemplateColumns: 'auto',

    '@bp3': {
        gridTemplateAreas: '"menu spacer content"',
        gridTemplateColumns: '250px 25px auto',
    },
});


const ContentDiv = styled('div', {
    gridArea: 'content',
    overflowY: 'auto',
});

const HeaderDiv = styled('div', {
    display: 'none',
    backgroundColor: '#fff',
    '@bp3': {
        gridArea: 'menu',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
});

const Headings = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'auto',
});

const SectionDiv = styled('div', {
    margin: '0',
    '&:nth-last-child(1)': {
        minHeight: '100%',
    },
});


const HeaderLink = styled('div', {
    padding: '10px 20px',
    cursor: 'pointer',
    color: '$darkText',
    outline: 'none',
    variants: {
        selected: {
            true: {
                fontWeight: 'bold',
            },
        },
    },
    '@bp5': {
        padding: '15px 20px',
    },
});

const Line = styled('div', {
    width: '1px',
    height: '100%',
    margin: 'auto 0',
    backgroundColor: '$separatorColor',

    display: 'none',
    '@bp3': {
        display: 'inherit',
        gridArea: 'spacer',
    },
});
