import React from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DynamicForm from './DynamicForm';

const ConsultsSearch = ({ config, props }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const handleSearch = (formValues) => {
        const { identifier_type, identifier } = formValues;

        navigate(`/consult_search_results/${identifier_type}/${identifier}`);
    };

    const renderSearchButton = ({ submitting }) => (
        <Button
            key="search"
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitting}
        >
            Search
        </Button>
    );

    return (
        <Paper>
            <div className={classes.imageContainer}>
                <img src="/search.png" alt="search" />
            </div>
            <div className={classes.formContainer}>
                <DynamicForm
                    onSubmit={handleSearch}
                    config={{ fields: searchForm, defaultValues: {} }}
                    actions={[renderSearchButton]}
                />
            </div>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3),
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#EBEEF0',
    },
}));

const searchForm = {
    id: 'CONSULTS_SEARCH',
    name: 'Search Consults',
    groups: [
        {
            id: 'part_1',
            name: 'Search Patient',
            fields: [
                {
                    id: 'identifier_type',
                    name: 'Identity Type',
                    type: 'radio',
                    default: 'NNZAF',
                    optional: false,
                    display_view: 'radio',
                    render_condition: [],
                    calculation_precondition: [],
                    calculation: [],
                    options: [
                        {
                            id: 'NNZAF',
                            name: 'ID Number',
                        },
                        {
                            id: 'PPN',
                            name: 'Passport Number',
                        },
                    ],
                },
                {
                    id: 'identifier',
                    name: 'ID Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    validation: ['valid_said', 'get', 'identifier'],
                    render_condition: ['=', 'get', 'identifier_type', 'NNZAF'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
                {
                    id: 'identifier',
                    name: 'Passport Number',
                    type: 'text',
                    default: 'None',
                    optional: false,
                    display_view: 'text',
                    render_condition: ['=', 'get', 'identifier_type', 'PPN'],
                    calculation_precondition: [],
                    calculation: [],
                    options: [],
                },
            ],
        },
    ],
};

export default ConsultsSearch;
