import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import PatientSummary from './index';
import { SummaryDemographics } from './Sections/Demographics';
import Button from '@mui/material/Button';
import { downloadPatientSummaryReport } from '../../Apis/PatientSummary';
import { saveBase64 } from '@a2d24/downloader/helpers';
import { useSnackbar } from 'notistack';


const PatientSummaryDialog = ({ open, handlePatientSummaryDialog, patient, patient_summary}) => {
    const classes = useStyles();
    const [downloading, setDownloading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleDownload = ()=>{
        if(downloading){
            return;
        }
        setDownloading(true);
        downloadPatientSummaryReport(patient.primary_identifier.value).then(
            (summaryResult)=>{
                saveBase64(summaryResult.b64_patient_summary, 'application/pdf', null, `${patient.first_name}_${patient.last_name}_patient_summary`);
            }
        ).catch(
            (error)=>{
                enqueueSnackbar('Failed to download patient summary', { variant: 'error' });
            }
        ).finally(
            ()=>{
                setDownloading(false);
            }
        )
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handlePatientSummaryDialog}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <SummaryDemographics demographics={patient_summary.patient_summary}/>
                <Button
                    variant="contained"
                    onClick={handleDownload}
                    sx={{
                        position: 'absolute',
                        right: 50,
                        top: 50,
                        '&:hover': {
                            cursor: downloading?'wait':'pointer'
                        }
                    }}
                >
                    Download
                </Button>

                <IconButton
                    aria-label="close"
                    onClick={handlePatientSummaryDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{overflowY: 'hidden'}}>
                <PatientSummary
                    patient_summary={patient_summary}
                />

            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '80vh',
        minWidth: '80vw',
        maxHeight: '80vh',
    },
}));

export default PatientSummaryDialog;
