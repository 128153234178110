import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProceedToSignoffAction = ({
    consult,
    submitting,
    is_quick_signoff = false,
    text = 'proceed to signoff',
}) => {
    const navigate = useNavigate();
    const proceedToSignoff = () => {
        navigate(`/consults/${consult.consult_id}/${is_quick_signoff ? 'quick_signoff' : 'signoff'}`);
    };

    return (
        <Button variant="contained" onClick={proceedToSignoff} disabled={submitting}>
            {text}
        </Button>
    );
};

export default ProceedToSignoffAction;
