import Button from '@mui/material/Button';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

const CompleteConsultAction = ({ consult, setSubmitting, submitting }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const signoffComplete = () => {
        setSubmitting(true);
        axios.post(`${API_URL}/ConsultController/early_consult_complete`, {
            consult_id: consult.consult_id,
        })
            .then((res) => {
                setSubmitting(false);
                enqueueSnackbar('Consult completed', { variant: 'success' });
                navigate(`/consults/${consult.consult_id}/signoffcomplete`);
            })
            .catch((err) => {
                setSubmitting(false);
                enqueueSnackbar('Failed to complete consult', { variant: 'error' });
            });
    };

    return (
        <Button variant="contained" onClick={signoffComplete} disabled={submitting}>
            close case
        </Button>
    );
};

export default CompleteConsultAction;
