export const riskMapping = {
    'Very high vulnerability': {
        acronym: 'VHV',
        color: '#c37070',
    },
    'High vulnerability': {
        acronym: 'HV',
        color: '#d79b67',
    },
    'Medium vulnerability': {
        acronym: 'MV',
        color: '#d5c56d',
    },
    'Low vulnerability': {
        acronym: 'LV',
        color: '#8dae67',
    },
    'No vulnerability': {
        acronym: 'NV',
        color: 'white',
    },
};
