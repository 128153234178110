import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Icon from '@mui/material/Icon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import DocumentViewerDialog from './DocumentViewer/DocumentViewerDialog';
import ImageViewer from '@a2d24/image-viewer';
import { getUploadedDocument } from '../Apis/Uploads';
import { useSnackbar } from 'notistack';

const ProcedureDetailsAccordion = ({ procedure, consult_id, editable = true }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [procedureValues, setProcedureValues] = React.useState({});
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [filePresignedURL, setFilePresignedURL] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');
    const [imageType, setImageType] = React.useState('');
    const viewDocumentElement = (docNumber, document)=>{
        const docTitle = document.original_name || document.document_upload;
        const docKey = document.document_upload;
        return(
            <div key={docNumber} className={classes.uploadDetailsDiv}>
                <div className={classes.uploadDetailTextDiv}>
                    {
                        Object.keys(document).map(
                            (key)=> keyValuePair({key:key, value:document[key]})
                        )
                    }
                </div>
                <div className={classes.uploadDetailButtonDiv}>
                    <Button
                    style={{ height: '18px', padding: '10px', width: '20px' }}
                    size={'small'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => {
                    handleOpenDocument(docTitle, docKey);
                    }}
                    >
                    {'View'}
                </Button>
                </div>
            </div>
        )
    }

    const keyValuePair = ({key, value})=>{
    return value && !value.startsWith('Consult_Documents/')?
        (<div key={key} className={classes.subDetailsPair}>
          <div className={classes.subDetailsKey}>
            {key.replaceAll('_', ' ').replace(/^./, key[0].toUpperCase())}
          </div>
          <div className={classes.subDetailsValue}>
            {value}
          </div>
        </div>
        )
          :
        null
  }


    useEffect(() => {
        getProcedureDetails();

    }, []);

    const getProcedureDetails = () => {
        Recycle.rpcPostV2({
            controller: 'ProcedureController',
            method: 'get_procedure_details',
            kwargs: { consult_id, procedure_detail_id: procedure.procedure_id },
        })
            .then((res) => {
                setProcedureValues(res.values);
            })
            .catch((err) => {});
    };

    const handleEdit = () => {
        navigate(`/consults/${consult_id}/procedure/${procedure.procedure_id}`);
    };

    const handleOpenPDF = () => {
        setOpenPdfViewer(true);
    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false);
    };

    const handleOpenImageViewer = () => {
        setOpenImageViewer(true);
    };

    const setCloseImageViewer = () => {
        setOpenImageViewer(false);
    };

    const handleOpenDocument = (procedure_field, key) => {
        getUploadedDocument(key)
        .then((res) => {
          if(!res){
            enqueueSnackbar("Upload does not exist", { variant: 'error' });
              return;
          }
            setFilePresignedURL(res);
            setDocumentTitle(procedure_field);
             if (key.endsWith('.pdf')) {
                       handleOpenPDF();
              }
              else {
                  setImageType(key.split('.')[-1]);
                  handleOpenImageViewer();
              }
        })
        .catch((err) => {
          enqueueSnackbar("Failed to fetch upload", { variant: 'error' });
        });
    };

    const getValue = (value, field = null) => {
        if (Array.isArray(value)) {
            // Assumption: all elements in the array are of the same typ
            if(
              value.length >0
              && typeof(value[0]) ==='object'
              && Object.values(value[0]).filter((val)=> val.toString().startsWith('Consult_Documents/'))
            ){
                return(
                  <div style={{display:'flex', flexDirection: 'column'}}>
                      {
                          value.map((obj, idx)=> {
                              return viewDocumentElement(idx, obj);
                          })

                      }
                    </div>
                )
            }
            else{
                return(
                    <div className={classes.uploadDetailTextDiv}>
                        No uploads
                    </div>
                )
            }
        }
        if (typeof value === 'string') {
            if (value.startsWith('Consult_Documents/')) {
                return (
                    <Button
                        style={{ height: '18px', padding: '1px' }}
                        size={'small'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            handleOpenDocument(field, value);
                        }}
                    >
                        {' '}
                        Open Document{' '}
                    </Button>
                );
            }
        }
        return value;
    };

    return (
        <Accordion square={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.accordionSummary}>
                    <div className={classes.accordionSummaryLeft}>
                        <Icon className={classes.accordionSummaryIcon}>
                            {procedure.icon === 'Questionnaire' ? 'quiz' : 'content_paste'}
                        </Icon>
                        <div className={classes.accordionSummaryText}>
                            {procedure.procedure_name}
                        </div>
                    </div>
                    <div className={classes.accordionSummaryStatus}>
                        {procedure.procedure_status}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {Object.keys(procedureValues).map((key) => {
                    return (
                    <React.Fragment key={key}>
                        <div>{key.replace(/_/g, ' ')}</div>
                        <div style={{ marginLeft: '7px' }}>
                            {getValue(procedureValues[key], key.replace(/_/g, ' '))}
                        </div>
                    </React.Fragment>
                )
                }
                )
                }
            </AccordionDetails>
            {editable === true && (
                <AccordionActions>
                    <Button color="primary" variant="contained" onClick={handleEdit}>
                        Edit
                    </Button>
                </AccordionActions>
            )}
            <DocumentViewerDialog
                title={documentTitle}
                open={openPdfViewer}
                isUrl={true}
                data={filePresignedURL}
                setClose={setClosePdfViewer}
            />
            <ImageViewer
                title={documentTitle}
                open={openImageViewer}
                isBase64Image={false}
                imgSrc={filePresignedURL}
                imageType={imageType}
                setClose={setCloseImageViewer}
            />
        </Accordion>
    );
};

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    accordionSummaryLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    accordionSummaryIcon: {
        marginRight: theme.spacing(3),
        color: theme.palette.success.main,
    },
    accordionSummaryStatus: {
        color: theme.palette.success.main,
        textTransform: 'capitalize',
    },
    accordionSummaryText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '12px',
    },
    accordionDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        textTransform: 'capitalize',
        rowGap: theme.spacing(2),
    },
    uploadDetailsDiv:{
        display: 'grid',
        gridTemplateColumns: '4fr 1fr',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '14px',
        margin: '10px 0px',
        outline: 'none',
    },
    uploadDetailTextDiv:{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        justifyContent: 'flex-start',
    },
    uploadDetailButtonDiv:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    subDetailsPair:{
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      color:'black',
      fontSize: '16px',
    },
    subDetailsKey:{
      fontWeight: '400'
    },
    subDetailsValue:{
      fontWeight: '300'
    },
}));

export default ProcedureDetailsAccordion;
