import { Button, Dialog, DialogTitle, MenuItem, Select, Typography } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import TextField from '@mui/material/TextField';
import MedicalHistoryDatePicker from './MedicalHistoryDatePicker';
import MedicalHistoryTextboxField from './MedicalHistoryTextField';
import MedicalHistoryReactSelect from './MedicalHistoryReactSelect';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import MedicalHistorySelectField from './MedicalHistorySelectField';

const IODFieldArray = ({ values, mutators: { push } }) => {
    //validator functions
    const composeValidators =
        (...validators) =>
        (value) =>
            validators.reduce((error, validator) => error || validator(value), undefined);
    const requiredValidator = (value) => (value ? undefined : 'This field is required');

    const renderNoEntries = () => {
        return (
            <>
                <div style={{ margin: '3ch' }}>
                    <Typography variant="h8">There are no recorded IOD Items </Typography>
                </div>
            </>
        );
    };

    return (
        <div>
            <div>
                <FieldArray name="iod">
                    {({ fields }) =>
                        fields.length === 0
                            ? renderNoEntries()
                            : fields.map((name, index) => (
                                  <Paper disabled elevation={2} key={index}>
                                      <div
                                          style={{
                                              margin: '2ch',
                                              display: 'grid',
                                              columnGap: '1ch',
                                              gridTemplateColumns: '0.9fr 0.1fr',
                                              rowGap: '1ch',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  paddingTop: '20px',
                                                  display: 'grid',
                                                  columnGap: '1ch',
                                                  gridTemplateColumns: '1fr 1fr',
                                                  rowGap: '1ch',
                                              }}
                                          >
                                              <MedicalHistoryDatePicker
                                                  name={'Date of Injury'}
                                                  id={`${name}.date_of_injury`}
                                                  description={'Date of Injury'}
                                              />

                                              <MedicalHistoryTextboxField
                                                  name={'Claim Number'}
                                                  id={`${name}.claim_number`}
                                              />

                                              <MedicalHistoryReactSelect
                                                  name={'Claim Finalised'}
                                                  id={`${name}.claim_finalised`}
                                                  options={[
                                                      { id: 'Yes', name: 'Yes' },
                                                      { id: 'No', name: 'No' },
                                                  ]}
                                              />

                                              <MedicalHistoryTextboxField
                                                  name={'Description'}
                                                  id={`${name}.description`}
                                              />
                                          </div>
                                          <IconButton
                                              style={{
                                                  cursor: 'pointer',
                                                  backgroundColor: 'transparent',
                                              }}
                                              onClick={() => fields.remove(index)}
                                              aria-label="delete"
                                          >
                                              <DeleteIcon />
                                          </IconButton>
                                      </div>
                                  </Paper>
                              ))
                    }
                </FieldArray>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="text"
                    onClick={() => {
                        push('iod', undefined);
                    }}
                >
                    ADD INJURED ON DUTY HISTORY
                </Button>
            </div>
        </div>
    );
};

export default IODFieldArray;
