import React from 'react';
import { useForm, useFormState } from 'react-final-form';

const removeConditionalFields = (form, fields) => {
    fields.forEach((field) => {
        form.change(field, undefined);
    });
};

const ConditionalField = ({ condition, children, fields = [], removeOnUnmount = true }) => {
    const { values } = useFormState();
    const form = useForm();
    const [conditionMet, setConditionMet] = React.useState(condition(values));

    React.useEffect(() => {
        if (fields.length <= 0) {
            console.warn(
                'ConditionalField used without any fields specified. The conditional field will not work.'
            );
        }
    }, []);

    // When the component is unmounted, remove any children from the form
    React.useEffect(() => {
        if (removeOnUnmount) return () => removeConditionalFields(form, fields);
    }, [removeOnUnmount]);

    React.useEffect(() => {
        const newValue = condition(values);
        const prevValue = conditionMet;

        if (prevValue === true && newValue === false) {
            // Conditionally removing field or fields
            removeConditionalFields(form, fields);
        }

        if (newValue !== prevValue) setConditionMet(newValue);
    }, [condition, values]);

    if (!conditionMet) return null;

    return children;
};

export default ConditionalField;
