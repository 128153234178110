import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProceedToDiseaseMng = ({ consult, submitting }) => {
    const navigate = useNavigate();
    const returnToDashboard = () => {
        navigate(`/disease-management-selector/${consult.consult_id}`);
    };

    return (
        <Button variant="contained" onClick={returnToDashboard} disabled={submitting}>
            Proceed
        </Button>
    );
};

export default ProceedToDiseaseMng;
