import RffRadioButtonField from '../../ReactFinalFormFields/RffRadioButtonField';
import { idTypeOptions } from './IdentificationPage';
import ConditionalField from '../../ReactFinalFormFields/RffConditionalWrapper';
import RffTextField from '../../ReactFinalFormFields/RffTextField';
import React from 'react';
import { evaluatePolishExpression } from '@a2d24/care-at-work-core/Recycle';
import RffPhoneNumberField from '../../ReactFinalFormFields/RffPhoneNumberField';
import isValidPhoneNumber from '../../ReactFinalFormFields/Validators/ValidPhoneNumberValidation';
import RffDateField from '../../ReactFinalFormFields/RffDateField';

const genderOptions = [
    {
        id: 'male',
        name: 'Male',
    },
    {
        id: 'female',
        name: 'Female',
    },
];

const isValidEmail = (value) => {
    return evaluatePolishExpression(
        [
            '|',
            'regex',
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
            'get',
            'email',
            '!',
            'get',
            'email',
        ],
        { email: value }
    )
        ? undefined
        : 'Invalid Email Address';
};

const PatientDetailsPage = ({ formValues }) => {
    return (
        <>
            <RffTextField id="first_name" name="First Name" optional={false} />
            <RffTextField id="last_name" name="Last Name" optional={false} />
            <RffPhoneNumberField
                id="contact_number"
                name="Contact Number*"
                optional={false}
                validate={isValidPhoneNumber}
            />
            <RffTextField id="email" name="Email" optional={true} validate={isValidEmail} />
            <RffRadioButtonField
                id="gender"
                name="Gender"
                options={genderOptions}
                optional={false}
            />
            <ConditionalField
                condition={(values) => values.identifier_type === idTypeOptions[1].id}
                fields={['date_of_birth']}
            >
                <RffDateField id="date_of_birth" name="Date of Birth" optional={false} />
            </ConditionalField>
        </>
    );
};
export default PatientDetailsPage;
