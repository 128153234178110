import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import clsx from 'clsx';
import { AppConfigContext } from '../App';
import { useStores } from '@a2d24/care-at-work-state/stores';
import SidebarNotifications from '../Components/SidebarNotifications'

const SideNavBar = ({ menu, className }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { authStore } = useStores();
    const is_stock_module_site = authStore.dsp_details?.is_stock_module_site;
    const appointmentExclusionRoles = ['global_omp'];
    const selectedRole = authStore.selectedRole.role;

    const isActivePath = (path) => {
        const basePath = location.pathname.split('/')[1];
        return `/${basePath}` === path;
    };

    const renderNavigations = () => {
        // Added in to only show stock Management Menu if the site is listed as a stock management module
        const menu_filtered_stock_management = menu.filter((item) => {
            if (is_stock_module_site) {
                return true;
            } else {
                return item.id !== 'stock-management';
            }
        });

        return menu_filtered_stock_management.map((item) => {
            const activePath = isActivePath(item.to);
            const classNames = clsx({ [classes.inactive]: true, [classes.active]: activePath });
            if(item.id === 'appointments' && appointmentExclusionRoles.includes(selectedRole)){
                return null;
            }
            return (
                <ListItem key={item.id} onClick={() => navigate(item.to)} button>
                    {activePath ? <div className={classes.activeBox} /> : null}
                    <ListItemIcon className={classNames}>
                        <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText className={classNames} primary={item.name} />
                </ListItem>
            );
        });
    };

    return (
        <div className={className}>
            <div className={classes.sideBar}>
                <List component="nav">{renderNavigations()}</List>
                <div>
                    <SidebarNotifications/>
                </div>

            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {},
    sideBar: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    activeBox: {
        height: '30px',
        width: '5px',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
        borderRadius: '0 3px 3px 0',
    },
    inactive: {
        color: '#666666',
    },
    active: {
        color: theme.palette.primary.main,
    },
}));

export default SideNavBar;
