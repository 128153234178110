import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ScheduleDetails from './ScheduleDetails';
import BookingDetails from './BookingDetails';
import Divider from '../Divider';

const AppointmentDetails = ({ config, props }) => {
    const classes = useStyles();

    return (
        <div>
            <ScheduleDetails className={classes.scheduleContainer} config={config} props={props} />
            <Divider config={{ marginBottom: 4 }} />
            <BookingDetails config={config} props={props} />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    scheduleContainer: {
        marginBottom: theme.spacing(5),
    },
}));

export default AppointmentDetails;
