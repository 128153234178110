import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './styles';

const RecommendationText = ({ medicalType, disease = null }) => {
    const classes = styles();
    const getMedicalTypeText = () => {
        const exclusion_list = ['of', 'the', 'with', 'and'];
        const full_upper_list = ['cof'];
        let medicalTYpeParsed = medicalType.replaceAll('_', ' ').split(' ');

        for (var i = 0; i < medicalTYpeParsed.length; i++) {
            if (full_upper_list.includes(medicalTYpeParsed[i])) {
                medicalTYpeParsed[i] = medicalTYpeParsed[i].toUpperCase();
            }
            if (!exclusion_list.includes(medicalTYpeParsed[i])) {
                medicalTYpeParsed[i] =
                    medicalTYpeParsed[i].charAt(0).toUpperCase() + medicalTYpeParsed[i].slice(1);
            }
        }
        medicalTYpeParsed = medicalTYpeParsed.join(' ');
        return !disease ? medicalTYpeParsed : `${disease} ${medicalTYpeParsed}`;
    };
    return (
        <div className={classes.mainHeader}>
            Care Planner Recommendations for {getMedicalTypeText()}
        </div>
    );
};

export default RecommendationText;
