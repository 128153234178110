import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CheckCirlceIcon from '@mui/icons-material/CheckCircle';

const ConsultSuccessCard = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.container}>
            <CheckCirlceIcon fontSize="large" className={classes.checkIcon} />
            <div className={classes.title}>Sign Off Successful</div>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
    },
    checkIcon: {
        color: theme.palette.success.main,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        // fontWeight: 'bold'
    },
}));

export default ConsultSuccessCard;
