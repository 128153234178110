import React, { useEffect } from 'react';
import MaterialTable from '@material-table/core';
import makeStyles from '@mui/styles/makeStyles';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import DocumentViewerDialog from '../DocumentViewer/DocumentViewerDialog';
import CreatedByOrDateCell from './CreatedByOrDateCell';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getReportDocument } from '../../Apis/Uploads'
import { useConsultDocuments } from '../../queries';

const ConsultCompleteDocumentTable = ({ config, props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [tableData, setTableData] = React.useState([]);
    // const [tableLoading, setTableLoading] = React.useState(false);
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [filePresignedURL, setFilePresignedURL] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');
    const consultDocumentsQuery = useConsultDocuments(props.consult.consult_id)
    useEffect(() => {
        setTableData(consultDocumentsQuery.data)
    }, [consultDocumentsQuery.data]);

    const handleOpenPDF = () => {
        setOpenPdfViewer(true);
    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false);
    };

    const handleOpenDocument = (rowData) => {
        getReportDocument(rowData.file_location)
            .then(
                (res)=>{
                    if(!res){
                        enqueueSnackbar("Upload does not exist", { variant: 'error' });
                        return;
                    }
                    setFilePresignedURL(res);
                    setDocumentTitle(rowData.document_title);
                    handleOpenPDF();
                }
            ).catch(
                (err)=>{
                   enqueueSnackbar("Failed to fetch upload", { variant: 'error' });
                }
            )
    };

    const columns = [
        {
            field: 'document_title',
            title: 'TITLE',
        },
        {
            field: props.type && props.type === 'history_table' ? 'created_at' : 'created_by',
            title: props.type && props.type === 'history_table' ? 'CREATED ON' : 'CREATED BY',
            render: (rowData) => <CreatedByOrDateCell type={props.type} history={rowData} />,
        },
        {
            field: 'document_type',
            title: 'DOCUMENT TYPE',
        },
    ];
    return (
        <>
            <MaterialTable
                data={tableData}
                columns={columns}
                title={config.title ? config.title : 'Documents'}
                // isLoading={tableLoading}
                actions={[
                    (rowData) => ({
                        icon: () => (
                            <Button color="primary" variant="contained" style={{ marginRight: 15 }}>
                                {' '}
                                View{' '}
                            </Button>
                        ),
                        tooltip: 'View document',
                        onClick: (event, rowData) => handleOpenDocument(rowData),
                        disabled: rowData.document_status !== 'generated',
                    }),
                ]}
                options={{
                    headerStyle: {
                        color: '#999999',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '15px',
                    },
                    search: false,
                    actionsColumnIndex: -1,
                }}
            />
            <DocumentViewerDialog
                title={documentTitle}
                open={openPdfViewer}
                isUrl={true}
                data={filePresignedURL}
                setClose={setClosePdfViewer}
            />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(1),
        width: '224px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default ConsultCompleteDocumentTable;
