import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from '../styles';
import EventTypesViewDialogContent from './EventTypesViewDialogContent';
import NewEventTypeDialogContent from './NewEventTypeDialogContent';
import { useServices } from '../../api';
import { useSnackbar } from 'notistack';

const AdvancedSettingsDialog = ({ open, handleDialogClose, eventTypes, calendar_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [viewNewEventType, setViewNewEventType] = useState(false);
    const services = useServices();
    const excludedServices = [];
    for (const eventType of eventTypes) {
        excludedServices.push(...((eventType?.meta_settings?.services) ?? []));
    }
    const filteredServices = (services.data ?? []).filter((
        (serviceOption) => {
            return !(excludedServices.includes(serviceOption.value));
        }
    ));

    const openNewEventType = () => {
        if (filteredServices.length === 0) {
            enqueueSnackbar(`You cannot create a new event type as all consult types have been selected. Please delete one or more event types to create a new one.`, { variant: 'warning' });
        } else {
            setViewNewEventType(true);
        }
    };
    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleDialogClose}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="form-dialog-title"
        >
            {
                viewNewEventType ?
                    <NewEventTypeDialogContent calendar_id={calendar_id} filteredServices={filteredServices}
                                               closeNewEventTypeInput={() => {
                                                   setViewNewEventType(false);
                                               }} />
                    :
                    <EventTypesViewDialogContent handleDialogClose={handleDialogClose} eventTypes={eventTypes}
                                                 openNewEventType={openNewEventType} />

            }

        </Dialog>
    );
};


export default AdvancedSettingsDialog;
