import { AlertDialog } from '@a2d24-ui/alert-dialog';

export const ReloadDialog = ({ updatesAvailable, setUpdatesAvailable, applyUpdates }) => {
    const message = (
        <>
            <p>A new version of the software has been detected.</p>
            <p>
                Please click the <b>'Reload'</b> button below to update to the latest version. Any
                unsaved changes will need to be recaptured.
            </p>
            <p>Apologies for the inconvenience.</p>
        </>
    );

    return (
        <AlertDialog
            open={updatesAvailable}
            setOpen={setUpdatesAvailable}
            hideCancel={true}
            actionButtonText={'Reload'}
            title="Care@Work Update Required"
            onAction={() => applyUpdates()}
            content={message}
            descriptionProps={{ css: { p: { paddingTop: 10 } } }}
        />
    );
};

export const promptUpdate = (authStore) => {
    const shouldUpdate = authStore.shouldUpdateFrontend;

    return (
        shouldUpdate && (
            <ReloadDialog
                updatesAvailable={shouldUpdate}
                setUpdatesAvailable={(value) => {
                    authStore.shouldUpdateFrontend = value;
                }}
                applyUpdates={() => {
                    window.location.reload();
                }}
            />
        )
    );
};
