//This validation is custom built for react-phone-input-2
import { parsePhoneNumber } from 'libphonenumber-js';
const isValidPhoneNumber = (formattedValue) => {
    if (formattedValue === undefined) {
        return 'Phone number cannot be unfilled';
    }
    if (formattedValue === '+') {
        return 'Invalid contact number.';
    }
    try {
        const parsedNumber = parsePhoneNumber(formattedValue);
        if (!parsedNumber.isValid()) {
            return 'Invalid contact number.';
        }
    } catch (ParseError) {
        if (ParseError.message === 'TOO_SHORT') {
            return 'Invalid contact number.';
        }
    }

    return undefined;
};

export default isValidPhoneNumber;
