import React from 'react';
import { Field } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';

const MultiLineTextboxField = ({ id, name, optional, unit, description, warning }) => {
    const label = name;

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id} placeholder={label} optional={optional} unit={unit}>
                {(fieldProps) => {
                    return (
                        <TextField
                            label={fieldProps.placeholder}
                            name={fieldProps.input.name}
                            margin="none"
                            multiline
                            rows={4}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment key={`${id}-Adornment`} position="end">
                                        <label>{unit}</label>
                                    </InputAdornment>
                                ),
                            }}
                            required={optional === false}
                        />
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default MultiLineTextboxField;
