import React from 'react';
import AdhocConsult from '../../AdhocConsult';

const AdhocDetailsPage = ({ formValues, options }) => {
    return (
        <>
            <AdhocConsult options={options} />
        </>
    );
};
export default AdhocDetailsPage;
