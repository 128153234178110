import React from 'react';
import { TextField as MuiTextField } from 'mui-rff';
import InputAdornment from '@mui/material/InputAdornment';
import { Field } from 'react-final-form';
import { showErrorOnChange } from 'mui-rff';
import FieldLayout from './FieldLayout';

export default function NumericField({
    id,
    name,
    optional,
    unit,
    description,
    warning,
    integer = false,
}) {
    const label = name;

    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                placeholder={label}
                optional={optional}
                unit={unit}
                integer={integer}
                render={({ input, meta }) => {
                    // Based of TextField in mui-rff. Almost the same except it customizes the behaviour of the
                    // onChange handler from react-final-form's <Field />'s render method.
                    // Not production ready, needs edge case handling, just a POC.
                    // Note, react-final-form's onChange handler can take a SyntheticInputEvent or a raw value.
                    // We take the SyntheticInputEvent from material-ui and just pass the raw value to rff.
                    // Have a look at https://final-form.org/docs/react-final-form/types/FieldRenderProps
                    const internalInput = {
                        ...input,
                        onChange: (e) => {
                            // Will have to do handling of edge cases etc here
                            const value = parseFloat(e.target.value);
                            if (isNaN(value)) {
                                return input.onChange();
                            }
                            input.onChange(value);
                        },
                    };
                    return (
                        <NumericFieldWrapper
                            input={internalInput}
                            meta={meta}
                            name={name}
                            placeholder={label}
                            optional={optional}
                            integer={integer}
                            unit={unit}
                        />
                    );
                }}
            />
        </FieldLayout>
    );
}

export function NumericFieldWrapper(props) {
    const {
        input: { name, value, type = 'Number', onChange, ...restInput },
        meta,
        placeholder,
        required,
        optional,
        unit,
        integer,
        fullWidth = true,
        // helperText,
        showError = showErrorOnChange,
    } = props;
    const { error, submitError } = meta;
    const isError = showError({ meta });
    return (
        <MuiTextField
            fullWidth={fullWidth}
            // helperText={isError ? error || submitError : helperText}
            error={isError}
            onChange={onChange}
            name={name}
            label={placeholder}
            value={value}
            type={type}
            required={optional === false}
            InputProps={{
                required,
                inputMode: 'numeric',
                pattern: '/^\\d*\\.?\\d*$/',
                endAdornment: (
                    <InputAdornment position="end">
                        <label>{unit}</label>
                    </InputAdornment>
                ),
            }}
        />
    );
}
