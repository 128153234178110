import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const StaticField = ({ ...props }) => {
    const classes = useStyles();
    switch (props?.display_view) {
        case 'hyperlink':
            return (
                <a href={props.default} target={'_blank'} className={classes.hyperlinkBadge}>
                    {props.name}
                </a>
            );
        default:
            return null;
    }
};

const useStyles = makeStyles((theme) => ({
    hyperlinkBadge: {
        borderRadius: '20px',
        backgroundColor: '#e9bf7a',
        padding: '5px',
        width: 'fit-content',
        margin: '5px',
        height: '30px',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export default StaticField;
