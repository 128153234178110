import React from 'react';
import AdjustStock from '../StockManagement/AdjustStock';
import { getStockMoves, recordDamages } from '../../StockManagerConfig';

const AdjustStockBase = ({ config, props }) => {
    return (
        <>
            <AdjustStock getStockMoves={getStockMoves} recordDamages={recordDamages} />
        </>
    );
};

export default AdjustStockBase;
