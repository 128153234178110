import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const checkConsultsWithCommonProcedures = (consult_id) => {
    return axios
        .post(`${API_URL}/ConsultController/check_consults_with_common_procedures`, {
            consult_id: consult_id,
        })
        .then((r) => r.data);
};

export const copyConsultProceduresFromPreviousConsult = (consult_id) => {
    return axios
        .post(`${API_URL}/ConsultController/copy_consult_procedures_from_previous_consult`, {
            consult_id: consult_id,
        })
        .then((r) => r.data);
};

export const fetchConsultDocuments = (consult_id) => {
    return axios
        .post(`${API_URL}/ConsultController/get_documents_for_consult`, {
            consult_id: consult_id,
        })
        .then((r) => r.data);
};

export const deleteConsult = (consult_id) => {
    return axios
        .post(`${API_URL}/ConsultController/delete_consult`, {
            consult_id: consult_id,
        })
        .then((r) => r.data)
        .catch((err) => {
            console.log(err);
            if (err?.response?.status === 400) {
                throw 'Consult has already started and cannot be deleted';
            } else if (err?.response?.status === 403) {
                throw 'User does not have permission to delete consult';
            } else if (err?.response?.status === 500) {
                throw 'Internal error: Consult could not be deleted. Please log a support ticket.';
            } else {
                throw 'Consult could not be deleted';
            }
        });
};
