import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useStores } from '@a2d24/care-at-work-state/stores';

const AgendaConsultLogic = ({ config, props }) => {
    const navigate = useNavigate();
    const { authStore } = useStores();

    useEffect(() => {
        if (authStore.dsp_details?.is_agenda_site) {
            const { consult_details } = props;

            if (
                consult_details &&
                consult_details.message &&
                consult_details.message === 'Consult details not found'
            ) {
                navigate(`/agenda-search/${props.consult_id}/${props.patient_identifier}`);
            } else if (consult_details.employee_exists) {
                Recycle.rpcPostV2({
                    controller: 'AgendaManager',
                    method: 'get_employee',
                    kwargs: { patient_identifier: consult_details.patient_identifier },
                }).then((employee) => {
                    if (
                        employee.employment_site_id === consult_details.employment_site_id &&
                        employee.employee_role_id === consult_details.employee_role_id &&
                        !consult_details.selected_event
                    ) {
                        navigate(
                            `/agenda-events/${consult_details.consult_id}/${consult_details.patient_identifier}`
                        );
                    }
                });
            }
        }
        // Checks sms?
    }, []);
    return null;
};

export default AgendaConsultLogic;
