import RecommendedSubTable from '../RecommendedMedicalsTable/RecommendedSubTable';
import AlternativeSubTable from '../RecommendedMedicalsTable/AlternativeSubTable';
import { BasicInformation } from '../BasicInformation';
import { useState } from 'react';
import styles from './styles';
import RecommendationText from './RecommendationText';

const RecommendedMedicalsTable = ({ config, props }) => {
    const { booking, patient, care_activity_recommendation } = props;
    const { recommended_medicals, alternate_medical_services, services, care_activity } =
        care_activity_recommendation;
    const classes = styles();

    return (
        <>
            <div style={{ padding: '15px' }}>
                <BasicInformation patient={patient} booking={booking} />
            </div>
            <div>
                <RecommendationText
                    medicalType={booking.consult_type}
                    disease={booking.metadata?.disease_type}
                />
            </div>
            <div className={classes.tableDiv}>
                <RecommendedSubTable
                    booking={booking}
                    patient={patient}
                    activityId={care_activity?.activity_id}
                    data={recommended_medicals || []}
                />
                <AlternativeSubTable
                    booking={booking}
                    patient={patient}
                    activityId={care_activity?.activity_id}
                    data={alternate_medical_services || []}
                />
            </div>
        </>
    );
};

export default RecommendedMedicalsTable;
