import React from 'react';
import { Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import MedicalHistoryReactSelect from './MedicalHistoryReactSelect';
import MedicalHistoryReactMultiSelect from './MedicalHistoryReactMultiSelect';
import { allergiesMapping } from './Allergies';
import EmploymentHistoryFieldArray from './EmploymentHistoryFieldArray';
import IODFieldArray from './IODFieldArray';
import VaccinationHistoryFieldArray from './VaccinationHistoryFieldArray';

const SaveAndExit = (submitting) => (
    <div style={{ margin: '1ch' }}>
        <Button disabled={!submitting} style={{ float: 'right' }} variant="contained" type="submit">
            Save & exit
        </Button>
    </div>
);

const useFormStateRef = ({ formRef, form }) => {
    React.useEffect(() => {
        if (!form || !formRef) return;
        formRef.current = form;
    }, [formRef, form]);
};
const Test = styled.button`
    padding: 32px;
    background-color: hotpink;
    font-size: 24px;
    border-radius: 4px;
    color: black;
    font-weight: bold;

    &:hover {
        color: white;
    }
`;

const ErrorDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 5px;
    color: #d29292;
    font-weight: 400;
`;

function TabPanel(props) {
    const { children, value, index, mutators, disabled = false, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === 0 && <VaccinationHistoryFieldArray mutators={mutators} />}
            {value === 1 && <EmploymentHistoryFieldArray mutators={mutators} />}
            {value === 2 && <IODFieldArray mutators={mutators} />}
        </div>
    );
}

const MedicalHistoryForm = ({
    handleSubmit,
    form: {
        mutators: { push, pop },
    }, // injected from final-form-arrays above
    dirty,
    form,
    submitting,
    values,
    errors
}) => {
    // useFormStateRef({ formRef, form });
    const { mutators } = form;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <form onSubmit={handleSubmit} style={{ display: 'grid', columnGap: '1ch' }}>
            <MedicalHistoryReactSelect
                name={'Blood Type'}
                id={`blood_type`}
                options={[
                    { id: 'a+', name: 'A+' },
                    { id: 'a-', name: 'A-' },
                    { id: 'b+', name: 'B+' },
                    { id: 'b-', name: 'B-' },
                    { id: 'o+', name: 'O+' },
                    { id: 'o-', name: 'O-' },
                    { id: 'ab+', name: 'AB+' },
                    { id: 'ab-', name: 'AB-' },
                    { id: 'unknown', name: 'Unknown' },
                ]}
            />
            <MedicalHistoryReactMultiSelect
                name={'Allergies'}
                id={`allergies`}
                options={
                    (Object.entries(allergiesMapping)).map(
                         (allergy)=> {
                             return {
                                 id: allergy[1],
                                 name:allergy[1]
                             }
                         }

                     )
                }
                optional={true}
            />
            <div>
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Vaccinations" />
                    <Tab label="Employment History" />
                    <Tab label="IOD/Occupational Diseases" />
                </Tabs>
                <TabPanel mutators={mutators} value={value} index={0}>
                    Vaccinations
                </TabPanel>
                <TabPanel mutators={mutators} value={value} index={1}>
                    Employment History
                </TabPanel>
                <TabPanel mutators={mutators} value={value} index={2}>
                    IOD/Occupational Diseases
                </TabPanel>

            </div>
            {dirty && <ErrorDiv>{errors.general}</ErrorDiv>}
            {dirty && <SaveAndExit submitting={submitting} />}
        </form>
    );
};

export default MedicalHistoryForm;
