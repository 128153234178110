import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaterialDivider from '@mui/material/Divider';

const Divider = ({ config, props }) => {
    const classes = useStyles(config);
    return <MaterialDivider className={classes.divider} variant="middle" />;
};

const useStyles = makeStyles((theme) => ({
    divider: (config) => ({
        marginTop: config.marginTop ? theme.spacing(config.marginTop) : theme.spacing(2),
        marginBottom: config.marginBottom ? theme.spacing(config.marginBottom) : theme.spacing(2),
    }),
}));

export default Divider;
