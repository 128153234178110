import logo from './public/logo.svg';
import secondary_logo from './public/logo.png';
// import icon_white from './public/assets/images/netcare-digital-icon-white.svg';
import icon from './public/netcare-digital-icon.svg';

import { styled } from '@a2d24-ui/theme';

const StyledLogo = styled('img', {}); // For css based styling

const Logo = (props) => <StyledLogo src={logo} alt="logo" {...props} />;
export const Icon = (props) => <StyledLogo src={icon} alt="icon" {...props} />;
export const SecondaryLogo = (props) => <StyledLogo src={secondary_logo} alt="logo" {...props} />;

export default Logo;
