import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchPatientSummary = (primary_patient_id) => {
    return axios
        .post(`${API_URL}/ObservationsController/get_one_page_summary`, {
            primary_patient_id: primary_patient_id,
        })
        .then((r) => r.data);
};


export const downloadPatientSummaryReport = (primary_patient_id) => {
    return axios
        .post(`${API_URL}/ObservationsController/get_patient_summary_base64`, {
            primary_patient_id: primary_patient_id,
        })
        .then((r) => r.data);
};
