import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Widget from './index';

// TODO: Deprecate config.children and migrate to config.widgets

const Column = ({ config, props }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {config.children || config.widgets
                ? (config.children || config.widgets).map((child) => (
                      <Widget key={child.id} config={child} props={props} style={{marginBottom: '15px' }} />
                  ))
                : null}
        </div>
        // <h1>Test</h1>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
    }
}));

export default Column;
