import * as React from 'react';
import { default as MuiTextField } from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { default as MuiMobileTimePicker } from '@mui/lab/MobileTimePicker';
import { default as MuiDesktopTimePicker } from '@mui/lab/DesktopTimePicker';

import { default as MuiDesktopDatePicker } from '@mui/lab/DesktopDatePicker';
import { default as MuiMobileDatePicker } from '@mui/lab/MobileDatePicker';
import { default as MuiStaticDatePicker } from '@mui/lab/StaticDatePicker';

import { default as MuiDateTimePicker } from '@mui/lab/DateTimePicker';
import { default as MuiDesktopDateTimePicker } from '@mui/lab/DesktopDateTimePicker';
import { default as MuiMobileDateTimePicker } from '@mui/lab/MobileDateTimePicker';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: 'hsl(191,41%,48%)',
        },
    },
    components: {
        // Component to override
        MuiCalendarPicker: {
            styleOverrides: {
                // Name of the slot
                viewTransitionContainer: {
                    scrollBehavior: 'initial',
                },
            },
        },
        MuiMonthPicker: {
            styleOverrides: {
                // Name of the slot
                root: {
                    margin: '0 auto',
                },
            },
        },
    },
});

export const DefaultInput = ({
    InputProps: { helperText, fullWidth, variant = 'outlined', ...restInputProps },
    inputProps,
    ...props
}) => (
    <TextField
        helperText={helperText}
        fullWidth={fullWidth}
        variant={variant}
        InputProps={restInputProps}
        FormHelperTextProps={{
            error: restInputProps.error,
        }}
        inputProps={{
            autoComplete: 'off',
            ...inputProps,
        }}
        {...props}
    />
);

const withMuiProviders = (WrappedComponent) => {
    return (props) => (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <WrappedComponent {...props} />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

// Helper method that copies propTypes
const wrapMuiComponent = (Base) => {
    const Component = withMuiProviders(Base);
    Component.propTypes = Base.propTypes;
    return Component;
};

export const MobileTimePicker = wrapMuiComponent(MuiMobileTimePicker);
export const DateTimePicker = wrapMuiComponent(MuiDateTimePicker);
export const DesktopDatePicker = wrapMuiComponent(MuiDesktopDatePicker);
export const MobileDatePicker = wrapMuiComponent(MuiMobileDatePicker);
export const MobileDateTimePicker = wrapMuiComponent(MuiMobileDateTimePicker);
export const StaticDatePicker = wrapMuiComponent(MuiStaticDatePicker);
export const DesktopDateTimePicker = wrapMuiComponent(MuiDesktopDateTimePicker);
export const DesktopTimePicker = wrapMuiComponent(MuiDesktopTimePicker);
export const TextField = wrapMuiComponent(MuiTextField);
