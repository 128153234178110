import useStyles from '../styles';
import React from 'react';
import PhoneNumberField from '../../components/FormComponents/PhoneNumberField';
import MultiSelector from '../../components/FormComponents/MultiSelector';
import { useClients } from '@a2d24/care-at-work-template-module/queries';
import isValidPhoneNumber from '../../utils';

const SiteSettings = () => {
    const classes = useStyles();
    const clientsQuery = useClients();
    const clientOptions = (clientsQuery.data??[]).map(
        (client)=>{
            return {"value":client.id , "label":client.name}
        }
    )
    return (
        // TODO: REMOVE
        <div className={classes.pageContainer} style={{ width: '100%' }}>
            <div className={classes.mainHeading}>Site settings</div>
            <div className={classes.generalText}>
                Please add your site settings which will enable us to allow booking as per your site’s specifications.
            </div>
            <div className={classes.siteSettingsContainer}>
                <PhoneNumberField id={"contact_number"} name={"Site contact number for booking queries"} validate={isValidPhoneNumber}/>
                <MultiSelector
                    id={"clients_serviced"}
                    description={"Clients serviced"}
                    options={clientOptions}
                />
            </div>
        </div>
    );


}
export default SiteSettings
