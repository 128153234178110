import styles from '../styles';
import Chip from '@mui/material/Chip';

export const AllergiesAndDiseases = ({demographics})=> {
    const classes = styles();
    return(
        <div className={classes.SectionContainer}>
                <div className={classes.MainHeadingDiv}>Allergies & diseases</div>
                <div className={classes.ItemContainer}>
                    <div className={classes.SubHeadingDiv}>
                        Allergies
                    </div>
                    <div className={classes.ValuesDiv}>
                    {
                        (demographics?.allergies??[]).length > 0?
                             demographics.allergies.map((allergy, idx)=>{
                                    return (
                                        <Chip key= {idx} label={allergy} />
                                    )
                            })
                        :
                            <div className={classes.NoInformationDiv}>
                                No Information Available
                            </div>
                    }

                    </div>
                </div>

                <div className={classes.ItemContainer}>
                    <div className={classes.SubHeadingDiv}>
                        Chronic diseases
                    </div>
                    <div className={classes.ValuesDiv}>
                        {
                            (demographics?.chronic_diseases??[]).length > 0 ?
                                demographics.chronic_diseases.map((disease)=>{
                                    return (
                                        <Chip key= {disease.condition} label={`${disease.condition}${disease.pathway? `(${disease.pathway})` : ``}`} />
                                    )
                                })
                            :
                                <div className={classes.NoInformationDiv}>
                                    No Information Available
                                </div>
                        }
                    </div>



                </div>

                <div className={classes.ItemContainer}>
                    <div className={classes.SubHeadingDiv}>
                            Work-related diseases
                    </div>
                    <div className={classes.ValuesDiv}>
                        {
                            (demographics?.workplace_diseases??[]).length > 0 ?
                                 demographics.workplace_diseases.map((disease)=>{
                                return (
                                    <Chip key= {disease.condition} label={`${disease.condition}${disease.pathway? `(${disease.pathway})` : ``}`} />
                                )
                            })
                            :
                                <div className={classes.NoInformationDiv}>
                                    No Information Available
                                </div>
                        }
                    </div>
                </div>

        </div>
    )

}
