import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';

const ReactSelectAdapter = ({ input, ...rest }) => {
    const classes = useStyles();

    const { onChange, ...restInput } = input;
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        const optionFromID = rest.options.find((o) => o.value === restInput.value);

        if (optionFromID !== undefined) {
            setSelectedOption(optionFromID);
        } else {
            setSelectedOption(null);
        }
    }, [restInput.value, rest.options]);

    const internalOnChange = (e) => {
        onChange(e?.value !== undefined ? e?.value : null);
    };
    return (
        <>
            <div>
                <InputLabel size="small" shrink={true}>
                    {rest.label}
                </InputLabel>
                <Select
                    {...restInput}
                    {...rest}
                    styles={customStyles}
                    isClearable={true}
                    isSearchable={true}
                    onChange={internalOnChange}
                    value={selectedOption}
                    isRequired
                />
                {
                    // Hack - Select does not support required
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={selectedOption?.value !== undefined ? selectedOption?.value : ''}
                        required={true}
                        // readOnly
                        onChange={() => {}}
                        testid={input.name}
                    />
                }
            </div>
        </>
    );
};

const MedicalHistoryReactSelect = ({ id, name, options, optional = false }) => {
    const label = name;

    const mapped_options = options.map(({ id, name }) => ({
        label: name,
        value: id,
    }));

    return (
        <Field
            name={id}
            label={label}
            optional={optional}
            options={mapped_options}
            component={ReactSelectAdapter}
            fullWidth
        ></Field>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        label: {
            color: theme.palette.grey[600],
            fontSize: '0.9rem',
        },
    };
});

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            background: 'none',
            border: 'none',
            borderColor: 'none',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            width: '100%',
        };
    },
};

export default MedicalHistoryReactSelect;
