import { MobileTimePicker } from '@mui/x-date-pickers';
import { DefaultInput } from '@a2d24-ui/react-final-pickers';
import { Field } from 'react-final-form';
import React from 'react';

export const FieldTimePicker = ({name, validationFn, onAccept=null, minTime=null, maxTime=null,disabled=false}) => {
    return (
        <Field name={name} validate={validationFn}>
                    {(fieldProps) => {
                        return (
                            <div>
                                <MobileTimePicker
                                    value={fieldProps.input.value}
                                    onChange={(val) => {
                                        fieldProps.input.onChange(val);
                                    }}
                                    onAccept={(val)=>{
                                        if(onAccept) {
                                            onAccept(val);
                                        }
                                    }}
                                    renderInput={DefaultInput}
                                    ampm={false}
                                    minutesStep={5}
                                    minTime={minTime}
                                    maxTime={maxTime}
                                    disabled={disabled}
                                />
                                <label
                                    style={{
                                        color: '#d29292',
                                        fontSize: '12px',
                                        display: 'block',
                                        fontWeight: 400,
                                        marginTop: '3px',
                                        textAlign: 'left'
                                    }}
                                >
                                    {fieldProps.meta.error}
                                </label>
                            </div>

                    )
                        ;
                    }}
                </Field>
    )
}
