import React from 'react';
import { Field } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import RffFieldLayout from './RffField';

const RffTextField = ({ id, name, optional, unit, validate }) => {
    const label = name;

    return (
        <RffFieldLayout>
            <Field name={id} type="text" validate={validate ? validate : null}>
                {({ input, meta }) => {
                    return (
                        <div>
                            <br />
                            <div>
                                <TextField
                                    variant="standard"
                                    label={label}
                                    name={input.name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment key={`${id}-Adornment`} position="end">
                                                <label>{unit}</label>
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ marginBottom: '1rem' }}
                                    autoComplete="off"
                                    required={optional === false}
                                />
                            </div>
                        </div>
                    );
                }}
            </Field>
        </RffFieldLayout>
    );
};

export default RffTextField;
