import makeStyles from '@mui/styles/makeStyles';

export const sharedStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '34px',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    subheading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    helperText: {
        color: '#666666',
    },
}));
