import styles from '../styles';
import { formatDate } from '../utils';




export const PrimaryHealthCare = ({primary_health_care}) => {
    const classes = styles();
    const render_primary_healthcare_dates_and_systems = (visits) =>{
    return(
        <div className={classes.PHCVisitsContainer}>
            <div className={classes.PHCColumnContainer}>
                <div className={classes.SubHeadingDiv}>
                    Date of Visit
                </div>
                {
                    visits.map(
                        (visit, idx)=>{
                            return(
                                <div key = {idx}>
                                    {formatDate(new Date(visit.created_at))}
                                </div>
                            )
                        }

                    )
                }

            </div>
            <div className={classes.PHCColumnContainer}>
                <div className={classes.SubHeadingDiv}>
                    Systems
                </div>
                 {
                    visits.map(
                        (visit, idx)=>{
                            return(
                                <div key ={idx}>
                                    {visit.value}
                                </div>
                            )
                        }

                    )
                }

            </div>

        </div>
    )
}

    return(
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Primary healthcare</div>
            {
                (primary_health_care?.historical_phc_visit_type ??[]).length > 0 ?
                render_primary_healthcare_dates_and_systems(primary_health_care.historical_phc_visit_type)
                :
                <div className={classes.NoInformationDiv}>
                    No Information Available
                </div>
            }
        </div>

    )
}
