import MultiPageForm from '../../MultiPageForm';
import FormPage from '../../MultiPageForm/FormPage';
import React, { useState } from 'react';
import IdentificationPage from './IdentificationPage';
import PatientDetailsPage from './PatientDetailsPage';
import BookingDetailsPage from './BookingDetailsPage';
import AdhocDetailsPage from './AdhocDetailsPage';
import {
    useClientProcedures,
    useClients,
    useConsultTypes,
    useDiseases,
    useDivisionOptions,
    useMedicalRoles,
    useServices,
    useSiteOptions,
} from '../../../queries';

const ManualBookingForm = ({
    handleSubmit,
    prePopulate,
    prePopulatePageIndex,
    valid,
    initialValues,
    setInitialValues,
    values,
    submitting,
    authStore,
}) => {
    const [selectedClient, setClientSelection] = useState(null);
    const [selectedDivision, setDivisionSelection] = useState(null);
    const [diseaseTypeOptions, setDiseaseTypeOptions] = useState([]);
    const clientsQuery = useClients();
    const consultTypesQuery = useConsultTypes();
    const diseasesQuery = useDiseases();
    const divisionsQuery = useDivisionOptions(selectedClient);
    const siteOptionsQuery = useSiteOptions(
        selectedClient,
        (divisionsQuery.data ?? []).length > 0,
        selectedDivision
    );

    // NOTE: THIS IS SET TO BEST PRACTICE FOR NOW
    const clientProceduresQuery = useClientProcedures('Best Practice');
    const medicalRolesQuery = useMedicalRoles();

    const onClientChange = (newValue) => {
        const clientSelection = newValue === null ? null : newValue.value;
        setDivisionSelection(null);
        setClientSelection(clientSelection);
    };

    const onDivisionChange = (newValue) => {
        const divisionSelection = newValue === null ? null : newValue.value;
        setDivisionSelection(divisionSelection);
    };

    const onConsultTypeChange = (consultType) => {
        const consultTypeSelection = consultType === null ? null : consultType.value;
        if (consultTypeSelection === 'chronic_care_consult') {
            const diseaseOptions = diseasesQuery.data
                .filter((disease) => {
                    return disease.condition_category === 'chronic_care';
                })
                .map((disease) => ({
                    id: disease.condition,
                    name: disease.condition,
                }));
            setDiseaseTypeOptions(diseaseOptions);
        } else if (consultTypeSelection === 'workplace_disease_consult') {
            const diseaseOptions = diseasesQuery.data
                .filter((disease) => {
                    return disease.condition_category === 'workplace_disease';
                })
                .map((disease) => ({
                    id: disease.condition,
                    name: disease.condition,
                }));
            setDiseaseTypeOptions(diseaseOptions);
        } else {
            setDiseaseTypeOptions([]);
        }
    };

    const [basePageList, setPageList] = useState([
        'Identification',
        'Patient Details',
        'Booking Details',
    ]);
    const getUpdatedPages = (values) => {
        if (values && values.consult_type === 'adhoc_occupational_health_consult_without_cof') {
            return ['Identification', 'Patient Details', 'Booking Details', 'Adhoc Details'];
        } else {
            return ['Identification', 'Patient Details', 'Booking Details'];
        }
    };

    return (
        <MultiPageForm
            handleSubmit={handleSubmit}
            prePopulate={prePopulate}
            prePopulatePageIndex={prePopulatePageIndex}
            values={values}
            initialValues={initialValues}
            valid={valid}
            submitting={submitting}
            pageList={basePageList}
            setPageList={setPageList}
            getUpdatedPages={getUpdatedPages}
            setInitialValues={setInitialValues}
        >
            <FormPage children={<IdentificationPage formValues={values} />} />
            <FormPage children={<PatientDetailsPage formValues={values} />} />
            <FormPage
                children={
                    <BookingDetailsPage
                        formValues={values}
                        consultTypes={consultTypesQuery.data ?? []}
                        onConsultTypeChange={onConsultTypeChange}
                        diseaseTypeOptions={diseaseTypeOptions}
                        clients={clientsQuery.data ?? []}
                        divisions={divisionsQuery.data ?? []}
                        onClientChange={onClientChange}
                        onDivisionChange={onDivisionChange}
                        siteOptions={siteOptionsQuery.data ?? []}
                        medicalRoleOptions={medicalRolesQuery.data}
                    />
                }
            />
            {values && values.consult_type === 'adhoc_occupational_health_consult_without_cof' ? (
                <FormPage
                    children={
                        <AdhocDetailsPage
                            formValues={values}
                            options={clientProceduresQuery.data ? clientProceduresQuery.data : []}
                        />
                    }
                />
            ) : null}
        </MultiPageForm>
    );
};

export default ManualBookingForm;
