import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';
import Button from '@mui/material/Button';
import { sharedStyles } from '../../shared/styles';
import Recycle from '@a2d24/care-at-work-core/Recycle';

const AgendaSearch = ({ config, props }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const sharedClasses = sharedStyles();
    const [site, setSite] = useState(null);
    const [role, setRole] = useState(null);

    const handleProceed = () => {
        Recycle.rpcPostV2({
            controller: 'AgendaManager',
            method: 'get_employee',
            kwargs: { patient_identifier: props.patient_identifier },
        })
            .then((employee) => {
                if (
                    employee.employment_site_id === site.value &&
                    employee.employee_role_id === role.value
                ) {
                    createConsultDetails(
                        `/agenda-events/${props.consult_id}/${props.patient_identifier}`
                    );
                } else {
                    createConsultDetails('/Dashboard');
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    createConsultDetails('/Dashboard');
                }
            });
    };

    const createConsultDetails = (redirectPath) => {
        Recycle.rpcPostV2({
            controller: 'AgendaManager',
            method: 'create_consult_details',
            kwargs: {
                patient_identifier: props.patient_identifier,
                consult_id: props.consult_id,
                employment_site_id: site.value,
                employee_role_id: role.value,
            },
        }).then((res) => {
            navigate(redirectPath);
        });
    };

    return (
        <div className={classes.container}>
            <div className={sharedClasses.subheading}>
                Please confirm the employee's site and role
            </div>
            <div className={classes.inputContainer}>
                <div>
                    <InputLabel shrink={true}>Employment Site</InputLabel>
                    <Select value={site} onChange={setSite} options={props.sites} />
                </div>

                <div>
                    <InputLabel shrink={true}>Employee Role</InputLabel>
                    <Select value={role} onChange={setRole} options={props.roles} />
                </div>
            </div>
            <Button
                className={classes.proceedButton}
                variant="contained"
                color="primary"
                onClick={handleProceed}
                disabled={!site || !role}
            >
                proceed
            </Button>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(3),
    },
    inputContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gap: theme.spacing(3),
    },
    proceedButton: {
        justifySelf: 'end',
    },
}));

export default AgendaSearch;
