import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const getPatientPresignedUrl = (identifier, content_type, media_extension) => {
    return axios
        .post(`${API_URL}/PatientController/create_presigned_post_url`, {
            identifier: identifier,
            content_type: content_type,
            file_extension: media_extension,
        })
        .then((r) => {
            return r.data[0];
        });
};
