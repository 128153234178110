import React from 'react';
import { styled, theme } from '@a2d24-ui/theme';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { observer } from '@a2d24/care-at-work-state/mobx-react-lite';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';

const AuthenticatorContents = styled('div', {
    maxWidth: '65ch',
    width: '80vw',

    padding: '3ch',
    border: '1px solid $cardIconSeparatorColor',
    borderRadius: '$2',

    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: '2ch',
    p: {
        marginLeft: '4ch',
    },
});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$warning',
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$primary4' },
    '&:focus': { boxShadow: `0 0 0 2px $colors$primary3` },
});

export const Authenticator = observer(({ authenticator }) => {
    const { mfaStore } = useStores();

    return (
        <AuthenticatorContents>
            {authenticator.oob_channel === 'email' ? (
                <EnvelopeClosedIcon width={25} height={25} />
            ) : (
                <ChatBubbleIcon width={25} height={25} />
            )}
            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {authenticator.name.replaceAll('X', '*')}
                {!authenticator.active && (
                    <span
                        style={{
                            fontSize: 'smaller',
                            color: theme.colors.warning,
                            padding: '2px 5px',
                        }}
                    >
                        Unverified
                    </span>
                )}
            </p>
            <IconButton
                onClick={() => {
                    mfaStore.removeAuthenticator(authenticator.id);
                }}
            >
                <Cross2Icon />
            </IconButton>
        </AuthenticatorContents>
    );
});

export default observer(({ setScreen }) => {
    const { mfaStore } = useStores();

    React.useEffect(() => {
        mfaStore.fetchMfaAuthenticators();
    }, [mfaStore]);

    return (
        <Content>
            <Link to="/care/preferences">&lt; Go Back</Link>

            <h1>Would you like to add another verification method?</h1>
            <VerificationMethod>
                <h2>Email</h2>
                <p>You'll receive a 6-digit verification code via email upon login</p>
                <Button type="button" variant="outlined" onClick={() => setScreen('email-screen')}>
                    Set up Email
                </Button>
            </VerificationMethod>
            <VerificationMethod>
                <h2>SMS Text Message</h2>
                <p>You'll receive a 6-digit verification code via SMS Text Message upon login</p>
                <Button type="button" variant="outlined" onClick={() => setScreen('sms-screen')}>
                    Set up SMS
                </Button>
            </VerificationMethod>
            <h1>Existing verification methods</h1>
            {mfaStore.mfaAuthenticators.length === 0 && (
                <p>You currently do not have any verification methods set up</p>
            )}
            {mfaStore.mfaAuthenticators.map((auth) => (
                <Authenticator authenticator={auth} key={auth.id} />
            ))}
        </Content>
    );
});

const VerificationMethod = styled('div', {
    padding: '3ch',
    border: '1px solid $cardIconSeparatorColor',
    borderRadius: '$2',
    maxWidth: '80ch',
    width: '90vw',

    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '1fr auto',
    gap: '2ch',
    h2: {
        fontSize: '1.4em',
        fontWeight: 300,
        gridColumn: '1 / span 2',
        gridRow: 1,
    },
    p: {
        gridRow: 2,
        gridColumn: 1,
    },
    button: {
        gridRow: '1 / span 2',
        gridColumn: 2,
        placeSelf: 'center',
        minWidth: '15ch',
        fontSize: '1em',
        fontWeight: 400,
    },
});

const Content = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '2ch',
    color: '$primaryBlack',
    alignItems: 'center',
    padding: '0 4ch',
    height: '100%',
    width: '100%',
    alignSelf: 'flex-start',
    overflowY: 'auto',
    h1: {
        fontWeight: 300,
        fontSize: '1.5em',
        textAlign: 'center',
    },

    a: {
        textDecoration: 'none',
        color: '$primary',
        fontWeight: 500,
        // maxWidth: '80ch',
        width: '100vw',
        paddingLeft: '4ch',
        textAlign: 'left',
    },
});
