import styles from '../styles';
import { renderValue } from '../utils';

export const VitalSigns = ({vital_signs}) => {
    const classes = styles();
    const bpSittingObj = {
        value:vital_signs?.blood_pressure_sitting_systolic?.value && vital_signs?.blood_pressure_sitting_diastolic?.value
            ?`${vital_signs.blood_pressure_sitting_systolic.value}/${vital_signs.blood_pressure_sitting_diastolic.value}`
            : null,
        units: null
    }
    const bpLyingObj = {
        value:vital_signs?.blood_pressure_lying_systolic?.value && vital_signs?.blood_pressure_lying_diastolic?.value
            ?`${vital_signs.blood_pressure_lying_systolic.value}/${vital_signs.blood_pressure_lying_diastolic.value}`
            : null,
        units: null
    }
    return(
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Vital Signs</div>
            {renderValue('BMI', vital_signs?.bmi)}
            {renderValue('Blood pressure sitting', bpSittingObj)}
            {renderValue('Blood pressure lying',bpLyingObj)}
            {renderValue('Blood sugar', vital_signs?.blood_sugar)}
            {renderValue('Cholesterol', vital_signs?.cholesterol)}

        </div>

    )
}
