import { App, Auth0 } from '@a2d24/recycle-frontend-core';

export {
    interpolate,
    getObjectByPath,
    evaluatePolishExpression,
} from '@a2d24/recycle-frontend-core';

const authProvider = new Auth0({
    grant_type: 'password',
    audience: 'https://distributed-occhealth.netcare.digital',
    scope: 'openid',
    client_id: process.env.REACT_APP_CLIENT_ID,
    endpoint: 'https://auth.netcaretech.co.za',
    connection: 'Username-Password-Authentication',
});

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
export default new App(authProvider, baseUrl);
