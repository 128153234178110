import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useState, useEffect } from 'react';
import { Select } from '@a2d24-ui/select';
const ExaminationCell = ({ consult }) => {
    const classes = useStyles();

    return <div className={classes.time}>{consult?.dsp?.name}</div>;
};

const useStyles = makeStyles((theme) => ({
    time: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
    },
}));

export const ExaminationCellFilter = (props) => {
    const [siteSelection, setSiteSelection] = useState(null);
    const { onFilterChanged, columnDef } = props;
    useEffect(() => {
        if (siteSelection) {
            onFilterChanged(columnDef.tableData.id, siteSelection.value);
        } else {
            onFilterChanged(columnDef.tableData.id, '');
        }
    }, [siteSelection, columnDef, onFilterChanged]);
    return (
        <Select
            options={Object.keys(props.columnDef.lookup).map((key) => {
                return {
                    label: props.columnDef.lookup[key],
                    value: key,
                };
            })}
            name={'examination_site_filter'}
            value={siteSelection}
            onChange={(values) => setSiteSelection(values)}
            showCustomValue={false}
            isClearable
            css={{
                fontSize: '0.8rem',
                color: '#000d1a',
                width: '75%',
                cursor: 'pointer',
                '.react-select__control': {
                    '&:focus-within': {
                        cursor: 'pointer',
                        borderColor: '#000d1a',
                    },
                },
            }}
            color={'#000d1a'}
        />
    );
};
export default ExaminationCell;
