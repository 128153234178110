import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import { riskMapping } from './CoMorbidityMappings';

export const CoMorbidityDetails = ({
    co_morbidity_status,
    co_morbidity_comments,
    other_co_morbidity_comments,
}) => {
    const classes = useStyles();
    return (
        <Tooltip
            title={
                <div className={classes.tool_tip_div}>
                    <div>
                        <b>Co-morbidity risk stratification:</b>
                    </div>
                    <div>
                        <b>{co_morbidity_status}</b>
                    </div>
                    {
                        <ul style={{ paddingInlineStart: '15px' }}>
                            {[...co_morbidity_comments, ...other_co_morbidity_comments].map(
                                (comment, idx) => {
                                    return <li key={idx}>{comment}</li>;
                                }
                            )}
                        </ul>
                    }
                </div>
            }
            className={classes.conditionTooltip}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: '#000d1a',
                        boxShadow: '2px 2px 2px 3px rgba(0, 0, 0, 0.1)',
                        fontSize: '0.8rem',
                    },
                },
            }}
        >
            <div
                className={classes.co_morbidity_badge}
                style={{ backgroundColor: riskMapping[co_morbidity_status].color }}
            >
                {riskMapping[co_morbidity_status].acronym}
            </div>
        </Tooltip>
    );
};

const useStyles = makeStyles((theme) => ({
    co_morbidity_badge: {
        borderRadius: '10%',
        width: '35px',
        height: '35px',
        color: '#56636b',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tool_tip_div: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
