import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { TrashIcon } from '@a2d24-icons/trash-icon';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import { useState ,useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getUploadedDocument } from '../../Apis/Uploads';
import { DownloadFromUrl } from '@a2d24/downloader/helpers';


const removalDialog = ({title, upload, open, onClose, onConfirm})=>{
  return(
    <Dialog
          open={open}
          onClose={onClose}
          fullWidth={true}
          maxWidth="md"
          sx={{ maxHeight: 'fit-content' }}
      >
          <DialogTitle sx={{ fontSize: '22px', fontWeight: '900' }}>{title}</DialogTitle>
          <DialogContent>
              <div>
                  Are you sure you want to remove {upload?.original_name || upload?.document_upload}?
              </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={onClose} variant="contained">
                  Cancel
              </Button>
              <Button
                onClick={
                  ()=>{
                    onConfirm(upload.document_upload);
                    onClose();
                  }}
                  variant="contained">
                  Confirm
              </Button>
          </DialogActions>
      </Dialog>
  )
}

export const UploadedDisplay = ({title, maxReached,  uploads, onRemove})=>{
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadToRemove, setUploadToRemove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const onDialogClose = ()=>{
    setDialogOpen(false);
    setUploadToRemove(null);
  }
  useEffect(()=>{
    if(uploadToRemove){
      setDialogOpen(true);
    }
  }, [uploadToRemove])
  const classes = useStyles();
  const downloadUpload = (document)=>{
    getUploadedDocument(document.document_upload)
    .then((res) => {
          if(!res){
            enqueueSnackbar("Upload does not exist", { variant: 'error' });
              return;
          }
          const fileName = document.original_name || document.document_upload;
          DownloadFromUrl(res, fileName);
        })
      .catch((err) => {
        enqueueSnackbar("Failed to fetch upload", { variant: 'error' });
      });
  }
  const keyValuePair = ({key, value})=>{
    return value && !value.startsWith('Consult_Documents/')?
        (<div key={key} className={classes.accordionDetailsKeyValue}>
          <div className={classes.accordionDetailsKey}>
            {key.replaceAll('_', ' ').replace(/^./, key[0].toUpperCase())}
          </div>
          <div className={classes.accordionDetailsValue}>
            {value}
          </div>
        </div>
        )
          :
        null
  }

  return(
   <div className={classes.displayContainer}>
     <div className={classes.heading}>
       {title}
        {
           maxReached?
                <div className={classes.warning}>
                   {"Maximum number of uploads reached."}
                </div>
               :null
       }
     </div>
     <div className={classes.displayItemsContainer}>
       {uploads.map((upload, idx)=>(
           <Accordion key={`${upload.document_upload}_${idx}`} className={classes.accordionContainer}>
             <AccordionSummary
               className={classes.accordionSummary}
               expandIcon={
                <MoreVertIcon className={classes.optionsActionIcon}/>
               }>
               {upload.original_name || upload.document_upload}
             </AccordionSummary>
             <AccordionDetails className={classes.accordionDetails}>
               {Object.keys(upload).map((key, index)=> keyValuePair({key:key, value: upload[key]}))}
             </AccordionDetails>
             <AccordionActions>
               <div className={classes.itemActions}>
                    <IconButton
                        aria-label="download"
                        onClick={()=>{
                            downloadUpload(upload);
                          }
                     }
                    >
                      <Tooltip title={'Download File'}>
                        <DownloadIcon className={classes.actionIcon}/>
                      </Tooltip>
                    </IconButton>
                     <IconButton
                        aria-label="remove"
                        onClick={()=>{
                          setUploadToRemove(upload)
                          }
                     }
                    >
                      <Tooltip title={'Remove File'}>
                        <TrashIcon className={classes.actionIcon}/>
                      </Tooltip>
                    </IconButton>
                 </div>
             </AccordionActions>
           </Accordion>
         )
       )}
      </div>
     {
       removalDialog(
         {
           title:'Upload removal',
           upload:uploadToRemove,
           open:dialogOpen,
           onClose:onDialogClose,
           onConfirm:onRemove,
         })
     }
   </div>
  )

}
const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    warning: {
        color: theme.palette.warning.main,
        fontSize: '12px',
        fontWeight: 'normal',
        marginTop: '10px',
    },
    displayContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    displayItemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    accordionContainer: {
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '10px',
      padding: '0px !important'

    },
    accordionSummary: {
      width: '100%',
      paddingLeft: '15px',
      paddingRight: '15px',
      alignItems: 'center',
      borderRadius: '10px',
      color: 'white',
      fontWeight: '300',
      fontSize: '14px',
      backgroundColor: theme.palette.primary.main,
    },
    accordionDetails:{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    accordionDetailsKeyValue:{
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      color:theme.palette.primary.main,
    },
    accordionDetailsKey:{
      fontSize: '12px',
      fontWeight: '500'
    },
    accordionDetailsValue:{
      fontSize: '12px',
      fontWeight: '300'
    },
    itemActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      gap: '5px'
    },
    actionIcon: {
      color: theme.palette.grey[500],
    },
    optionsActionIcon: {
      color: 'white',
    }
}));
