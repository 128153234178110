import styles from '../styles';
import { renderValue, renderValues } from '../utils';
import { riskMapping } from '../../CoMorbidity/CoMorbidityMappings';

export const FitnessStatus = ({ fitness_status }) => {
    const classes = styles();

    return (
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Fitness Status</div>
            {renderValue('Fitness status', fitness_status?.fitness_status)}
            {renderValue('Last medical date', fitness_status?.last_medical_date, true)}
            {renderValues('Fitness comments', fitness_status?.comments, false)}
            {renderValues('Other fitness comments', fitness_status?.other_comments, false)}
            {renderValue(
                'Co-morbidity risk',
                fitness_status?.co_morbidity_risk_status,
                false,
                riskMapping[fitness_status?.co_morbidity_risk_status?.value]?.color ?? 'transparent'
            )}
            {renderValues(
                'Co-morbidity risk comments',
                fitness_status?.co_morbidity_risk_status_comments,
                false
            )}
            {renderValues(
                'Other co-morbidity risk comments',
                fitness_status?.other_co_morbidity_risk_status_comments,
                false
            )}
        </div>
    );
};
