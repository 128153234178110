import React from 'react';
import { Field } from 'react-final-form';
import FieldLayout from './FieldLayout';
import { RFFDatePicker } from '../../modules/react-final-pickers';
import { isAfter, isBefore, isValid, parseISO } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    pickerInput: {
        marginBottom: '1rem',
    },
}));

const AFTER_DATE = parseISO('1900-01-01T00:00:00Z');
const BEFORE_DATE = parseISO('2099-12-31T00:00:00Z');

const dateValidator =
    (errorMessage, { after, before }) =>
    (value) => {
        let valid = isValid(value);
        valid = valid && (after ? isAfter(value, after) : true);
        valid = valid && (before ? isBefore(value, before) : true);

        return valid ? undefined : errorMessage;
    };

const DateField = ({ id, name, optional, unit, description, warning, ...rest }) => {
    const classes = useStyles();
    const [openedPickers, setOpenedPickers] = React.useState({});

    const onOpenPickerChange = (name, value) =>
        setOpenedPickers((prev) => ({
            ...prev,
            [name]: value,
        }));

    const label = name;

    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                component={RFFDatePicker}
                validate={dateValidator('A valid date is required', {
                    before: BEFORE_DATE,
                    after: AFTER_DATE,
                })}
                label={label}
                toolbarTitle={description}
                inputFormat="dd/MM/yyyy"
                mask="__/__/____"
                disableMaskedInput={false}
                InputProps={{
                    fullWidth: true,
                    className: classes.pickerInput,
                    testid: id,
                }}
                showTodayButton={true}
                open={openedPickers['start_date'] || false}
                onOpen={() => onOpenPickerChange('start_date', true)}
                onClose={() => onOpenPickerChange('start_date', false)}
            />
        </FieldLayout>
    );
};

export default DateField;
