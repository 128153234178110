import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteConsult } from '../../Apis/Consult';
import { SnackbarProvider, useSnackbar } from 'notistack';

const ActionsMenu = ({ consult, options }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDelete = () => {
        deleteConsult(consult.consult_id)
            .then((res) => {
                if (res) {
                    enqueueSnackbar('Consult deleted', { variant: 'success' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(err, { variant: 'error' });
            });
        handleClose();
    };

    const menuItemAction = (option) => {
        switch (option.toLowerCase()) {
            case 'delete':
                handleDelete();
        }
    };
    if (options.length === 0) {
        return null;
    }
    return (
        <>
            <IconButton onClick={handleClick} size="large">
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option}
                            onClick={() => {
                                menuItemAction(option);
                            }}
                        >
                            {option}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default ActionsMenu;
