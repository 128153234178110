import React from 'react';
import { createRoot } from 'react-dom/client';

const renderRootComponent = (elementId = 'root') => {
    const RootComponent = engine.rootComponent;
    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript

    root.render(<RootComponent />);
};

export default renderRootComponent;
