import useStyles from '../styles';
import { Field } from 'react-final-form';
import React from 'react';
import UnavailabilityFieldArray from './UnavailabilityFieldArray';
import { Checkboxes } from 'mui-rff';

const Unavailability = ({eventTypes, mutators}) => {
    const classes = useStyles();
    return (
        <div className={classes.pageContainer} style={{ width: '100%' }}>
            <div className={classes.mainHeading}>Unavailability</div>
            <div className={classes.generalText}>
                Add dates and times where you are unavailable during work hours.
            </div>
            <div className={classes.UnavailabilityFormContainer}>
                <UnavailabilityFieldArray mutators={mutators} eventTypes={eventTypes}/>
                <div className={classes.emphasizedText}>
                    Public holiday availability
                </div>
                <div className={classes.generalText}>
                    Your site is defaulted to be available on public holidays. To mark yourself as unavailable during
                    public holidays, please select below button and save.
                </div>
                <Field name={`block_public_holidays`}>
                    {(fieldProps) => {
                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '10px'
                            }}>
                                <Checkboxes
                                    variant="standard"
                                    name={fieldProps.input.name}
                                    data={{ value: false }}
                                    required={false}

                                />
                                <div className={classes.generalText}>
                                    Site is unavailable on public holidays
                                </div>
                            </div>
                        );
                    }}
                </Field>

            </div>

        </div>
    );


}
export default Unavailability
