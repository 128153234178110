import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import { AppConfigContext } from '../../../App';
import ReceiveTable from '../ReceiveTable';
import { useSnackbar } from 'notistack';
import { useStores } from '@a2d24/care-at-work-state/stores';

const TransferStockInFromAnotherClinicItems = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { authStore } = useStores();
    const [search, setSearch] = useState('');
    const [valueBeingCaptured, setValueBeingCaptured] = useState('item');
    const [item, setItem] = useState();
    const [selectMenuOpen, setSelectMenuOpen] = useState(false);
    const [unitsReceived, setUnitsReceived] = useState(null);
    const [price, setPrice] = useState(null);
    const [goodsReceived, setGoodsReceived] = useState([]);
    const [completedItems, setCompletedItems] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [openConfirmStockDialog, setOpenConfirmStockDialog] = React.useState(false);
    const [stockItems, setStockItems] = useState([]);
    const [openBatchDetailsDialog, setOpenBatchDetailsDialog] = useState(true);
    const appConfig = useContext(AppConfigContext);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const site = authStore.dsp_details.dsp_reference;

        async function fetchTransferStockItems() {
            const stock = await props.getTransferStockItems(
                site,
                props.clinicName,
                props.transferNumber
            );
            const goods = stock.stock_transferred_out.map((item) => {
                const new_item = { ...item, site_id: site, stock_move_type: 'goods_received' };
                return { item: new_item, quantity: item.quantity };
            });
            setGoodsReceived(goods);
        }

        fetchTransferStockItems();
    }, []);

    const handleOpenConfirmStockDialog = () => {
        setOpenConfirmStockDialog(true);
    };

    const handleCloseConfirmStockDialog = () => {
        setOpenConfirmStockDialog(false);
    };

    const onItemChanged = (item) => {
        setItem(item);
        setSearch('');
        setSelectMenuOpen(false);
        setUnitsReceived(null);
        setPrice(null);

        if (item) setValueBeingCaptured('units');
        else setValueBeingCaptured('item');
    };

    const handleAction = () => {
        if (valueBeingCaptured === 'units' && unitsReceived) {
            addReceivedItem();
            onItemChanged(null);
        }
    };

    const addReceivedItem = () => {
        setGoodsReceived([
            ...goodsReceived,
            {
                item: Object.assign({}, item),
                unitsReceived: parseFloat(unitsReceived),
            },
        ]);

        setCompletedItems({ ...completedItems, [item.id]: true });
    };

    const removeReceivedItem = (itemId) => {
        setGoodsReceived(goodsReceived.filter((received) => received.item.id !== itemId));
        setCompletedItems({ ...completedItems, [itemId]: false });
    };

    const filterOptionsAvailable = () => {
        return stockItems.map((category) => {
            return Object.assign({}, category, {
                options: category.options.filter(
                    (option) =>
                        completedItems[option.id] === undefined ||
                        completedItems[option.id] === false
                ),
            });
        });
    };

    const updateBatchStock = (batchInformation, stockId, quantitySum) => {
        let data = [...goodsReceived];
        const index = data.findIndex((obj) => obj.item.stock_item_id === stockId);
        console.log('index', index);
        console.log('data', data);
        if (index !== -1) {
            data[index].item.batch_items = batchInformation;
            data[index].item.batch_stock_moves_captured = true;
            data[index].item.quantity = quantitySum;
            setGoodsReceived(data);
        }
    };

    async function saveStockReceivedTransferIn(
        site_id,
        good_received,
        site_id_transfer_out,
        transfer_id
    ) {
        await props
            .saveStockReceivedTransferIn(site_id, good_received, site_id_transfer_out, transfer_id)
            .then((r) => {
                if (r === true) {
                    enqueueSnackbar('Goods Received Successfully Recorded', { variant: 'success' });
                } else {
                    enqueueSnackbar('Unable to record Goods Received', { variant: 'error' });
                }
            });
    }

    const submitGoodsReceived = () => {
        const site = authStore.dsp_details.dsp_reference;
        const payload = {
            supplier_name: 'Internal Stock Move',
            site_id: site,
            invoice_number: props.transferNumber,
            items_received: goodsReceived.map((received) => ({
                stock_id: received.item.id,
                stock_name: received.item.name,
                uom_id: received.item.uom.id,
                uom_name: received.item.uom.name,
                units_received: received.unitsReceived,
            })),
        };
        setSubmitting(true);

        saveStockReceivedTransferIn(site, payload, props.clinicName, props.transferNumber);
        handleCloseConfirmStockDialog();
        setSubmitting(false);
        navigate(`/stock-management`);
    };

    return (
        <>
            <Container className={classes.container}>
                {/*<ConfirmStockDialog*/}
                {/*    textBody="Are you sure you have entered all the stock received?"*/}
                {/*    open={openConfirmStockDialog}*/}
                {/*    handleClose={handleCloseConfirmStockDialog}*/}
                {/*    onDone={submitGoodsReceived}*/}
                {/*    submitting={submitting}*/}
                {/*/>*/}
                <NoPaddingCard elevation={2}>
                    {goodsReceived.length > 0 ? (
                        <>
                            <Box
                                display="flex"
                                height="70%"
                                flexDirection="column"
                                bgcolor="background.paper"
                            >
                                <Box>
                                    <ReceiveTable
                                        allowDelete={false}
                                        allowBatchEdition={true}
                                        updateBatchStock={updateBatchStock}
                                        stockItems={goodsReceived}
                                        removeReceivedItem={removeReceivedItem}
                                    />
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                height="10%"
                                flexDirection="column"
                                bgcolor="background.paper"
                            >
                                <Box>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            key="next"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={
                                                !(valueBeingCaptured === 'item') || submitting
                                            }
                                            onClick={submitGoodsReceived} //submitGoodsReceived()}
                                            className={classes.doneButton}
                                        >
                                            Done
                                        </Button>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    ) : null}
                </NoPaddingCard>
            </Container>
        </>
    );
};
const Container = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    max-height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    padding: 20px;
`;

const NoPaddingCard = styled(Paper)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    max-height: 100%;
    overflow-y: auto;
`;

const ListContainer = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555;
    }
`;

const StockListBody = styled.div`
    padding: 5px;
    margin: 0;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 60px 1fr 60px;
    grid-template-columns: 1fr;
`;

// const SelectItemBody = styled(CardBody)`
//     display: grid;
//     grid-template-rows: auto 1fr;
//     grid-template-columns: 1fr;
// `;

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '100vw',
        height: '100vh',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    input: {
        // marginLeft: theme.spacing(1),
        // flex: 1,
        fontSize: '3em',
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
                display: 'none',
            },
    },
    iconButton: {
        padding: 10,
        width: '200px',
        height: '200px',
    },
    divider: {
        height: 28,
        margin: 4,
    },
    doneButton: {
        width: '100px',
        height: '7%',
        justifyContent: 'center',
        alignSelf: 'center',
    },
}));

export default TransferStockInFromAnotherClinicItems;
