import axios from 'axios';
import { reFormat } from '../widgets/utils';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchServices = () => {
    return axios.get(`${API_URL}/ServiceController/get_walk_in_services`).then((res) => res.data);
};

export const fetchClients = () => {
    return axios.get(`${API_URL}/ClientController/get_all_clients`).then((res) => {
        return res.data.clients.map((client) => ({
            id: client.client_id,
            name: client.name,
        }));
    });
};

export const fetchConsultTypes = () => {
    return axios.get(`${API_URL}/ConsultController/get_consult_types`).then((res) => {
        return res.data.map((consultType) => {
            return {
                id: consultType,
                name: reFormat(consultType),
            };
        });
    });
};

export const fetchDiseases = () => {
    return axios
        .get(`${API_URL}/MedicalConditionsController/get_all_medical_conditions`)
        .then((res) => res.data);
};

export const fetchClientProcedures = (client) => {
    return axios
        .post(`${API_URL}/ServiceController/get_procedures_for_client`, {
            client: client,
        })
        .then((res) => {
            let clientProcOptions = [];
            res.data.procedures.forEach((procedure) => {
                clientProcOptions.push({
                    label: procedure.name,
                    value: procedure,
                });
            });
            return clientProcOptions;
        });
};

export const fetchSiteOptions = (client_id) => {
    return axios
        .post(`${API_URL}/SiteController/get_sites_for_client`, {
            client_id: client_id,
        })
        .then((res) => {
            return res.data.map((siteOption) => ({
                id: siteOption.employment_site_id,
                name: siteOption.employment_site_name,
            }));
        });
};

export const fetchDivisionOptions = (client_id) => {
    return axios
        .post(`${API_URL}/SiteController/get_divisions_for_client`, {
            client_id: client_id,
        })
        .then((res) => {
            return res.data.map((division) => ({
                id: division.division_id,
                name: division.division_name,
            }));
        });
};

export const fetchClientDivisionSiteOptions = (client_id, division_id) => {
    return axios
        .post(`${API_URL}/SiteController/get_sites_for_client_division`, {
            client_id: client_id,
            division_id: division_id,
        })
        .then((res) => {
            return res.data.map((siteOption) => ({
                id: siteOption.employment_site_id,
                name: siteOption.employment_site_name,
            }));
        });
};

export const fetchMedicalRoles = () => {
    return axios.get(`${API_URL}/MedicalRolesController/get_all_medical_roles`).then((res) => {
        return res.data.map((medicalRole) => ({
            id: medicalRole.medical_role_id,
            name: medicalRole.medical_role_name,
        }));
    });
};
