import { Field } from 'react-final-form';
import React from 'react';
import { PhonenumberComponent } from '../DynamicForm/PhonenumberField';
import RffFieldLayout from './RffField';
import RffFieldError from './RffFieldError';

const RffPhoneNumberField = ({ id, name, optional, validate }) => {
    let label = name;
    return (
        <RffFieldLayout>
            <Field
                name={id}
                placeholder={label}
                optional={optional}
                validate={validate ? validate : null}
            >
                {(fieldProps) => {
                    const meta = fieldProps.meta;
                    const { invalid, dirty } = meta;
                    return (
                        <div>
                            <div>
                                <PhonenumberComponent
                                    name={fieldProps.input.name}
                                    label={fieldProps.placeholder}
                                    value={fieldProps.input.value}
                                    onChange={fieldProps.input.onChange}
                                    required={optional === false}
                                    invalid={invalid}
                                    dirty={dirty}
                                />
                            </div>
                            <RffFieldError meta={meta} />
                        </div>
                    );
                }}
            </Field>
        </RffFieldLayout>
    );
};

export default RffPhoneNumberField;
