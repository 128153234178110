import { useEffect, useState } from 'react';
import DeadPoll from '../DeadPoll';

const useStreamingList = (identifier, { client: _client, websocketUrl, dataUrl, token }) => {
    const [data, setData] = useState(null);
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (_client) setClient(_client);
        else if (websocketUrl && dataUrl) setClient(new DeadPoll(websocketUrl, dataUrl, token));
    }, [_client, websocketUrl, dataUrl, token]);

    const onData = (newData) => {
        let arr = [];
        Object.keys(newData).forEach((key) => {
            arr.push(newData[key]);
        });
        setData(arr);
    };

    useEffect(() => {
        if (!client) return;
        let componentId;
        client
            .subscribe({
                identifier: `list#${identifier}`,
                callback: onData,
            })
            .then((r) => {
                componentId = r;
            });
        return () => {
            if (!client) return;
            client.unsubscribe({
                identifier: `list#${identifier}`,
                componentId,
            });
        };
    }, [client, identifier]);

    useEffect(() => {
        if(data){
            setLoading(false);
        }
    }, [data]);
    return {
        data: data??[],
        loading
    };
};

export default useStreamingList;
