import React from 'react';
import { Flex, keyframes, styled } from '@a2d24-ui/theme';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { Button } from '@a2d24-ui/button';
import { ExclamationIcon } from '@a2d24-icons/exclamation-icon';

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
    backgroundColor: '$overlay',
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

function Root({ children, ...props }) {
    return (
        <AlertDialogPrimitive.Root {...props}>
            <StyledOverlay />
            {children}
        </AlertDialogPrimitive.Root>
    );
}

const StyledContent = styled(AlertDialogPrimitive.Content, {
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '500px',
    maxHeight: '85vh',
    padding: 25,
    pointerEvents: 'auto !important',
    overflowY: 'auto',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&:focus': { outline: 'none' },
});

const StyledTitle = styled(AlertDialogPrimitive.Title, {
    margin: 0,
    fontSize: '$7',
    fontWeight: 500,
    textAlign: 'center',
});

const StyledDescription = styled('div', {
    margin: '$2',
    textAlign: 'center',
    color: '$primaryText',
    fontSize: '$6',
    lineHeight: 1.5,
});

const Exclamation = styled('div', {
    display: 'flex',
    margin: '60px auto 0',
    square: '80px',
});

const AlertDialogCancel = AlertDialogPrimitive.Cancel;
const AlertDialogAction = AlertDialogPrimitive.AlertDialogAction;

export const AlertDialog = ({
    title,
    content,
    open,
    setOpen,
    icon,
    cancelButton,
    hideCancel = false,
    cancelIsPrimary = false,
    actionButton,
    onAction,
    hideAction = false,
    onCancel,
    noIcon = false,
    actionButtonText = 'YES',
    cancelButtonText = 'NO',
    cancelButtonProps = {},
    actionButtonProps = {},
    descriptionProps = {},
    children,
    css,
}) => (
    <Root open={open} onOpenChange={setOpen}>
        <StyledContent css={{ ...css }}>
            {icon ? (
                <Exclamation>{icon}</Exclamation>
            ) : noIcon ? null : (
                <Exclamation>
                    <ExclamationIcon />
                </Exclamation>
            )}
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription {...descriptionProps}>
                {typeof content === 'string'
                    ? content.split('\n').map((content, index) => <p key={index}>{content}</p>)
                    : content}
            </StyledDescription>
            {children}

            <Flex css={{ justifyContent: 'center' }}>
                {!hideCancel && (
                    <AlertDialogCancel asChild>
                        {cancelButton ? (
                            cancelButton
                        ) : (
                            <Button
                                variant={cancelIsPrimary ? 'contained' : 'outlined'}
                                color={'secondary'}
                                css={{ minWidth: '120px', margin: '20px' }}
                                onClick={onCancel}
                                {...cancelButtonProps}
                            >
                                {cancelButtonText}
                            </Button>
                        )}
                    </AlertDialogCancel>
                )}
                {!hideAction && (
                    <AlertDialogAction asChild>
                        {actionButton ? (
                            actionButton
                        ) : (
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                css={{ minWidth: '120px', margin: '20px' }}
                                onClick={onAction}
                                {...actionButtonProps}
                            >
                                {actionButtonText}
                            </Button>
                        )}
                    </AlertDialogAction>
                )}
            </Flex>
        </StyledContent>
    </Root>
);
