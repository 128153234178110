import { Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from '../styles';
import { AddIcon } from '@a2d24-icons/add-icon';
import React, { useEffect, useState } from 'react';
import MultiSelector from '../../components/FormComponents/MultiSelector';
import { FieldTimePicker } from '../../components/FormComponents/FieldTimePicker';

const WorkingHourRange = ({fields, name, index, eventTypes})=>{
    const classes = useStyles();
    const [minTime, setMinTime] = useState(fields.value[index] !== undefined? Date.parse(fields.value[index].start_datetime):undefined);
    useEffect(() => {
        if(minTime!==undefined && new Date(minTime) > new Date(fields.value[index].end_datetime)){
            fields.update(index, {...fields.value[index], end_datetime: undefined})
        }

    }, [minTime]);
    return (
            <div key={index} className={classes.workingHourInputs}>
                <FieldTimePicker
                    name={`${name}.start_datetime`}
                    validationFn={(val)=>{if(!val){return "Required"}}}
                    onAccept={setMinTime}
                />
                <FieldTimePicker
                    name={`${name}.end_datetime`}
                    validationFn={(val)=>{if(!val){return "Required"}}}
                    minTime={minTime}
                    disabled={minTime===undefined}
                />
                <MultiSelector
                    id={`${name}.event_types`}
                    description={'Event types'}
                    options={eventTypes}
                    optional={false}
                />
                <IconButton
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        padding: '0px'
                    }}
                    onClick={() => {
                        fields.remove(index);
                    }}
                    aria-label="delete"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
    )
}

const WorkingHoursFieldArray = ({ mutators: { push }, day, eventTypes}) => {
    const classes = useStyles();
    const fieldArrayId = `${day.value}_working_hours`;
    const eventTypeOptions = eventTypes.map((eventType)=>{
        return {
            value: eventType.event_type_id,
            label: eventType.name
        }
    })
    const renderNoEntries = () => {
        return (
            <>
                <div style={{justifySelf: 'center'}}>
                    <Typography variant="h8">
                        Unavailable{' '}
                    </Typography>
                </div>
            </>
        );
    };

    return (
        <div>
            <FieldArray name={fieldArrayId}>
                {({ fields }) => {
                    return (
                        <div className={classes.dayInputContainer}>
                            <div className={classes.dayContainer}>
                                <div className={classes.emphasizedText}>{day.label}</div>
                                <div className={classes.timePickersContainer}>
                                    {
                                        fields.length === 0
                                            ? renderNoEntries()
                                            :
                                            fields.map((name, index) => {
                                                return (
                                                    <WorkingHourRange fields={fields} key={index} name={name} index={index} eventTypes={eventTypeOptions}/>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>
                                    <IconButton
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => {
                                            push(fieldArrayId, undefined);
                                        }}
                                        aria-label='Add'
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                    )}}
                </FieldArray>

        </div>
    );
};

export default WorkingHoursFieldArray;
