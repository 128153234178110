import React from 'react';
import { Field } from 'react-final-form';
import { Radios } from 'mui-rff';
import FieldLayout from './FieldLayout';

const RadioField = ({ id, name, options, optional, unit, description, warning }) => {
    let label = name;
    let adornment = unit ? ' '.concat('(', unit, ')') : '';
    let mapped_options = options.map(({ id, name }) => ({
        label: name.concat(adornment),
        value: id,
    }));

    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                label={label}
                type="radio"
                optional={optional}
                testid={id}
                adornment={adornment}
            >
                {(fieldProps) => {
                    return (
                        <div style={{ paddingBottom: description ? '15px' : '0px' }}>
                            <br />
                            <Radios
                                name={fieldProps.input.name}
                                label={label}
                                formControlProps={{ margin: 'none' }}
                                radioGroupProps={{ row: true }}
                                required={optional === false}
                                data={mapped_options}
                            />
                            <br />
                        </div>
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default RadioField;
