import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';

const ArrivalCell = ({ consult }) => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <div className={classes.arrival}>
                {dayjs(consult.arrived_at).format('ddd, DD/MM/YYYY')}
            </div>
            <div className={classes.arrival}>
                {dayjs(consult.arrived_at).format('hh:mm A')}
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    arrival: {
        fontSize: '11px',
        color: '#666666',
        lineHeight: '15px',
    },
}));

export default ArrivalCell;
