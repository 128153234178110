import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';

import Preferences from '@a2d24/care-at-work-core/views/Preferences';

const CareCore = () => {
    return (
        <Container>
            <Routes>
                <Route path="preferences" element={<Preferences />} />
                <Route path="/" element={<Navigate to={'preferences'} />} />
            </Routes>
        </Container>
    );
};

const CareCoreRoutes = <Route path="care/*" element={<CareCore />} key={'care'} />;

const Container = styled('div', {
    width: '100%',
    height: '100%',
    overflow: 'auto',
});

export default CareCoreRoutes;
