import React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import MaterialTable from '@material-table/core';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';

const AdhocProceduresView = ({ tableData, removeOneSelection, maxHeight }) => {
    const styles = useStyles(theme);
    const columns = [
        {
            field: 'procedure_client',
            title: 'Client',
            render: (rowData) => (
                <div>
                    <div>{rowData.procedure_client}</div>
                </div>
            ),
        },
        {
            field: 'procedure_id',
            title: 'Procedure ID',
            render: (rowData) => (
                <div>
                    <div>{rowData.procedure_id}</div>
                </div>
            ),
        },
        {
            field: 'procedure_name',
            title: 'Procedure Name',
            render: (rowData) => (
                <div>
                    <div>{rowData.procedure_name}</div>
                </div>
            ),
        },
        {
            field: 'procedure_type',
            title: 'Type',
            render: (rowData) => (
                <div>
                    <div>{rowData.type}</div>
                </div>
            ),
        },
        {
            field: 'remove',
            render: (rowData) => (
                <div>
                    <Button
                        className={styles.removeButton}
                        onClick={(event) => {
                            const procedureName = event.target.id.split('_')[0];
                            removeOneSelection(procedureName);
                        }}
                        id={`${rowData.procedure_name}_RemoveButton`}
                    >
                        Remove
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            <MaterialTable
                options={{
                    overflowY: 'scroll',
                    maxBodyHeight: maxHeight,
                    pageSize: 20,
                    search: false,
                }}
                columns={columns}
                data={tableData}
                title="Adhoc Procedures"
            />
        </div>
    );
};
//TODO: Remove value from selector and view

const useStyles = makeStyles((theme) => ({
    removeButton: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        outline: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    },
    tableHeading: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.dark,
    },
}));
export default AdhocProceduresView;
