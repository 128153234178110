import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import makeStyles from '@mui/styles/makeStyles';
import { appointmentStatusColorMapping, consultStatusColorMapping } from '../const';

const MetricCard = ({ count, status, type }) => {
    const colorMappings =
        type === 'bookings' ? appointmentStatusColorMapping : consultStatusColorMapping;
    const classes = useStyles({ status, colorMappings });
    const elevation = 8;
    const className = null;

    const renderBookingIcon = () => {
        if (status === 'upcoming' || status === 'pending') {
            return <AccessTimeIcon />;
        } else if (status === 'arrived') {
            return <DoneIcon />;
        } else if (status === 'completed') {
            return <DoneIcon />;
        } else if (status === 'cancelled' || status === 'no_show') {
            return <ClearIcon />;
        }
    };

    const renderConsultIcon = () => {
        if (status === 'in_progress' || status === 'pending') {
            return <AccessTimeIcon />;
        } else if (status === 'procedures_completed') {
            return <HourglassEmptyRoundedIcon />;
        } else if (status === 'completed') {
            return <DoneIcon />;
        } else if (status === 'awaiting_omp') {
            return <PriorityHighRoundedIcon />;
        }
    };
    const renderIcon = type === 'bookings' ? renderBookingIcon() : renderConsultIcon();
    let status_card_name = status;
    if (status.includes('_omp')) {
        status_card_name = status_card_name.replace('_omp', ' OMP');
    }
    status_card_name = status_card_name.replace('_', ' ');

    const renderCount = () => {
        const stringNumber = count.toString();
        return stringNumber.padStart(2, '0');
    };

    return (
        <Paper className={`${classes.root} ${className}`} elevation={elevation}>
            <div className={classes.countContainer}>{renderCount()}</div>

            <div className={classes.statusContainer}>
                {renderIcon}
                {status_card_name}
            </div>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    countContainer: (props) => {
        const backgroundColor = props.colorMappings(theme)[props.status].main;

        return {
            fontSize: '36px',
            color: 'white',
            backgroundColor: backgroundColor,
            padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(
                3
            )}`,
            borderRadius: '3px 8px 8px 3px',
        };
    },
    statusContainer: (props) => {
        const color = props.colorMappings(theme)[props.status].dark;

        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            color: color,
            textTransform: 'capitalize',
            padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(2)}`,
            '& svg': {
                marginRight: theme.spacing(1),
            },
        };
    },
}));

MetricCard.propTypes = {
    count: PropTypes.number.isRequired,
    // status: PropTypes.oneOf(['upcoming', 'completed', 'cancelled']).isRequired,
    // elevation: PropTypes.number,
    // className: PropTypes.string
};

MetricCard.defaultProps = {
    elevation: 3,
};

export default MetricCard;
