import styles from '../styles';
import { renderValue, renderValues } from '../utils';

export const VisualAcuity = ({ visual_acuity }) => {
    const classes = styles();

    return (
        <div className={classes.SectionContainer}>
            <div className={classes.MainHeadingDiv}>Occupational health history: Visual acuity</div>
            {renderValue('Corrective lenses used', visual_acuity?.corrective_lenses_used)}
            {renderValue('Colour vision', visual_acuity?.colour_vision)}
            {renderValues('Test result', visual_acuity?.test_result ?? [])}
        </div>
    );
};
