import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import EventTypeView from '../EventTypeView';
import React from 'react';
import useStyles from '../styles';

const EventTypesViewDialogContent = ({eventTypes, handleDialogClose,openNewEventType}) => {
    const classes = useStyles();
    return (
        <>
            <DialogTitle className={classes.dialogTile}>
                <div className={classes.mainHeading}>
                    Advanced settings: Event settings
                </div>
                <div className={classes.generalText}>
                    Create events to share for people to book on your site’s calendar
                </div>
            </DialogTitle >
            <DialogContent sx={{ overflowY: 'scroll' }}>
                <div className={classes.dialogAction}>
                    <Button
                        onClick={openNewEventType}
                        style={{ margin: '5px' }}
                        variant="contained"
                        color={'primary'}
                    >
                        Add new event type
                    </Button>
                </div>
                <div className={classes.EventTypes}>
                    {
                        eventTypes.map(
                            (eventType, index) => <EventTypeView eventType={eventType} key={index}/>
                        )
                    }
                </div>

            </DialogContent>
            <div className={classes.dialogAction} style={{padding:'24px'}}>
                <Button
                    onClick={handleDialogClose}
                    style={{ margin: '5px' }}
                    variant="contained"
                    color={'primary'}
                >
                    Exit
                </Button>
            </div>
    </>
    )
}

export default EventTypesViewDialogContent;
