import useStyles from '../styles';
import React from 'react';
import WorkingHoursFieldArray from './WorkingHoursFieldArray';
const days = [{label:"Mon", value:1}, {label:"Tues", value:2}, {label:"Wed", value:3}, {label:"Thurs", value:4}, {label:"Fri", value:5}, {label:"Sat", value:6}, {label:"Sun", value:7 }]

const WorkingHours = ({mutators, eventTypes}) => {
    const classes = useStyles();
    return (
        // TODO: REMOVE
        <div className={classes.pageContainer} style={{ width: '100%' }}>
            <div className={classes.mainHeading}>Working hours</div>
            <div className={classes.generalText}>
                To edit, select the box to the left of the day and then edit the hours in the boxes. Use the plus sign
                to add multiple slots in one day. This will help you accommodate breaks in your day. Please note that
                these are the site’s working hours regardless of event type booked
            </div>
            <div className={classes.workingDaysContainer}>
                {
                    days.map((day) => <WorkingHoursFieldArray key={day.value} day={day} mutators={mutators} eventTypes={eventTypes}/>)
                }
            </div>

        </div>
    );
};


export default WorkingHours;
