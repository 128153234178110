import makeStyles from '@mui/styles/makeStyles';
import theme from '../../theme';

const styles = makeStyles((theme) => ({
    tableDiv: {
        marginBottom: '0px',
        marginLeft: '20px',
        marginRight: '120x',
        justifyContent: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
    mainTextDiv: {
        display: 'flex',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '24px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        marginLeft: '0px',
        outline: 'none',
    },
    normalTextDivDark: {
        display: 'flex',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        fontSize: '16px',
        justifyContent: 'flex-start',
        margin: '15px',
        outline: 'none',
    },
    normalTextWhite: {
        display: 'flex',
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: '16px',
        justifyContent: 'flex-start',
        margin: '15px',
        outline: 'none',
    },
    subPathwayDivDark: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        verticalAlign: 'middle',
        color: theme.palette.primary.dark,
        width: '50%',
        fontSize: '16px',
    },
    subPathwayDivWhite: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        verticalAlign: 'middle',
        alignContent: 'center',
        color: 'white',
        width: '50%',
        fontSize: '16px',
    },
    checkBoxDiv: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    selectedRow: {
        backgroundColor: theme.palette.success.main,
        borderRadius: '5px',
    },
    alternativesRow: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '5px',
    },
    tableContainer: {
        backgroundColor: 'white',
        height: 'fit-content',
        borderRadius: '0px 0px 5px 5px',
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    dialogPrimaryTextDiv: {
        color: theme.palette.primary.dark,
        fontSize: '16px',
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    dialogSecondaryTextDiv: {
        color: theme.palette.primary.dark,
        fontSize: '16px',
        paddingLeft: '10px',
        fontWeight: '400',
        paddingBottom: '5px',
    },
    dialogNormalTextDiv: {
        color: theme.palette.primary.dark,
        fontSize: '16px',
        paddingLeft: '10px',
        paddingBottom: '5px',
    },
    dialogListText: {
        listStylePosition: 'inside',
        listStyleType: 'disc',
        color: theme.palette.primary.dark,
        fontSize: '16px',
        fontWeight: '400',
        paddingLeft: '15px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    dialogListDetail: {
        listStylePosition: 'inside',
        listStyleType: 'disc',
        color: theme.palette.primary.dark,
        fontSize: '16px',
        fontWeight: '100',
        paddingLeft: '15px',
        paddingRight: '15px',
        margin: '10px',
        borderRadius: '10px',
        justifyContent: 'center',
    },
    loadingDiv: {
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.primary.dark,
        fontSize: '16px',
    },
    noDataDiv: {
        justifyContent: 'center',
        fontSize: '14px',
        color: theme.palette.primary.dark,
    },
}));

export default styles;
