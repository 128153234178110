import React, { useState } from 'react';
import DynamicForm from '../DynamicForm';
import { sharedStyles } from '../../shared/styles';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '../ConfirmDialog';

const ProcedureDetails = ({
    config,
    props: { procedure, consult_id, consult, procedure_values },
    refresh,
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const sharedClasses = sharedStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [showDialog, setShowDialog] = useState(false);

    const onSubmit = async (values) => {
        const version = procedure_values.version || 0;
        const draft_version = procedure_values.draft_version || 0;

        await Recycle.rpcPostV2({
            controller: 'ProcedureController',
            method: 'save_procedure_details',
            kwargs: {
                consult_id,
                procedure_id: procedure.id,
                values,
                version,
                draft_version,
                procedure_name: procedure.name,
            },
        })
            .then((res) => {
                enqueueSnackbar(`Saved ${procedure.name}`, { variant: 'success' });
                navigate(`/consults/${consult_id}`);
            })
            .catch((err) => {
                if (err.response?.status === 409) {
                    setShowDialog(true);
                    return;
                }
                enqueueSnackbar(`Failed to save  ${procedure.name}!`, { variant: 'error' });
                if (err.response?.data?.message) {
                    enqueueSnackbar(err.response?.data?.message, { variant: 'error' });
                }
                console.log(err.response);
            });
    };

    const onSaveDraft = async (values) => {
        const version = procedure_values.draft_version || 0;

        await Recycle.rpcPostV2({
            controller: 'ProcedureController',
            method: 'save_procedure_details_draft',
            kwargs: {
                consult_id,
                procedure_id: procedure.id,
                values,
                version,
                procedure_name: procedure.name,
            },
        })
            .then((res) => {
                enqueueSnackbar(`Saved ${procedure.name} draft`, { variant: 'success' });
                navigate(`/consults/${consult_id}`);
            })
            .catch((err) => {
                if (err.response?.status === 409) {
                    setShowDialog(true);
                    return;
                }
                enqueueSnackbar(`Failed to save  ${procedure.name} draft!`, { variant: 'error' });
                if (err.response?.data?.message) {
                    enqueueSnackbar(err.response?.data?.message, { variant: 'error' });
                }
                console.log(err.response);
            });
    };

    const onCloseDialog = () => {
        setShowDialog(false);
        refresh();
    };

    return (
        <React.Fragment>
            <Paper>
                <div className={classes.container}>
                    <div className={sharedClasses.heading}>{procedure.name}</div>
                    <Divider className={classes.divider} variant="middle" />
                    <DynamicForm
                        onSubmit={onSubmit}
                        config={{
                            fields: procedure,
                            defaultValues: procedure_values.values || {},
                        }}
                        extraValues={{ patient: consult.patient }}
                        actions={[
                            ({ submitting }) => (
                                <Button
                                    key="cancel"
                                    disabled={submitting}
                                    onClick={() => navigate(`/consults/${consult_id}`)}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                            ),
                            ({ submitting, getValues }) =>
                                procedure_values.is_draft !== false ? (
                                    <Button
                                        key="save_draft"
                                        variant="contained"
                                        disabled={submitting}
                                        onClick={() => onSaveDraft(getValues())}
                                    >
                                        Save draft
                                    </Button>
                                ) : null,
                            ({ submitting }) => (
                                <Button
                                    key="save"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting}
                                >
                                    Save
                                </Button>
                            ),
                        ]}
                    />
                </div>
            </Paper>

            <ConfirmDialog
                open={showDialog}
                title="Note"
                message="Your procedure form data is outdated, this may be due to another clinician capturing data, the page will now refresh"
                onClose={onCloseDialog}
            />
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 15,
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export default ProcedureDetails;
