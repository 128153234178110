import React, { useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import { styled, theme } from '@a2d24-ui/theme';
import { Chip } from '@a2d24-ui/chip';
import { AddIcon } from '@a2d24-icons/add-icon';
import { CloseIcon } from '@a2d24-icons/close-icon';
import { TrashIcon } from '@a2d24-icons/trash-icon';
import { ArrowIcon } from '@a2d24-icons/arrow-icon';

const MultiValue = (props) => {
    const renderRemoveIcon = () => {
        let icon = <CloseIcon />;

        if (props.data.preselected) icon = <TrashIcon />;

        return (
            <RemoveIcon
                onClick={() => {
                    props.removeProps.onClick();
                }}
            >
                {icon}
            </RemoveIcon>
        );
    };

    return (
        <Chip>
            {props.data.label}
            {renderRemoveIcon()}
        </Chip>
    );
};

const Option = ({ innerRef, innerProps, ...props }) => {
    return (
        <OptionDiv
            ref={innerRef}
            {...innerProps}
            focused={props.isFocused}
            selected={props.isSelected}
        >
            {props.data.label}
            {props.isMulti ? (
                props.isSelected ? (
                    <CloseIcon color={theme.colors.warning} />
                ) : (
                    <AddIcon />
                )
            ) : null}
        </OptionDiv>
    );
};
const { Placeholder } = components;

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowIcon color={'currentColor'} />
        </components.DropdownIndicator>
    );
};

const LabelText = styled('div', { zIndex: 0 });

const ValueContainer = (props) => {
    const shouldFloatLabel = props.selectProps.menuIsOpen || props.hasValue;
    return (
        <components.ValueContainer {...props}>
            {shouldFloatLabel && props?.selectProps?.alwaysShowPlaceholder && (
                <LabelText
                    className={
                        props.hasValue && props?.isMulti
                            ? 'emr-placeholder'
                            : 'emr-placeholder-no-value'
                    }
                >
                    {props.selectProps.placeholder}
                </LabelText>
            )}
            {props.children}
        </components.ValueContainer>
    );
};

export const Select = ({
    input,
    meta,
    error,
    helperText,
    disabled,
    name,
    isMulti,
    css,
    value,
    alwaysShowPlaceholder = false,
    ...props
}) => {
    const showError = meta?.touched && meta?.invalid;
    const ref = useRef();

    const StyledClearComponent = styled(components.ClearIndicator, {
        color: `${props.color??'$primary'} !important`,
    });
    return (
        <InputBox css={{ ...css }}>
            <StyledSelect
                ref={ref}
                {...props}
                {...input}
                onKeyDown={(e) => {
                    if (e.keyCode === 32) {
                        e.preventDefault();
                        ref?.current?.onInputChange(
                            (ref?.current?.inputRef?.value || '') + ' ',
                            'set-value'
                        );
                    }
                }}
                isMulti={isMulti}
                value={input?.value || value}
                isDisabled={disabled}
                classNamePrefix={'react-select'}
                closeMenuOnSelect={
                    props.closeMenuOnSelect === undefined ? !isMulti : props.closeMenuOnSelect
                }
                inputId={input?.name || name}
                menuShouldScrollIntoView={true}
                tabSelectsValue={false}
                // hideSelectedOptions={!isMulti}
                error={meta ? showError : error}
                components={{
                    ClearIndicator: StyledClearComponent,
                    MultiValue,
                    Option,
                    ValueContainer,
                    DropdownIndicator,
                    ...props?.components,
                }}
                styles={{
                    placeholder: (base, state) => ({
                        ...base,
                        display:
                            state.isSelected || state.selectProps.inputValue ? 'none' : 'block',
                    }),
                }}
                alwaysShowPlaceholder={alwaysShowPlaceholder}
            />
            <HelperText error={showError}>
                {showError ? (meta && meta.error ? meta.error : error) : helperText}
            </HelperText>
        </InputBox>
    );
};

const StyledSelect = styled(ReactSelect, {
    display: 'flex',
    alignItems: 'center',
    '.react-select__control': {
        marginTop: '10px',
        width: '100%',
        minHeight: '$inputFieldHeight',
        boxShadow: `0 0 0 1px $inputBorder`,
        borderColor: '$inputBorder',
        '&::placeholder': {
            color: '$placeholderText',
        },
        cursor: 'default',
        '&:focus-within': {
            cursor: 'text',
            borderColor: '$primary',
        },
    },
    '.react-select__control--is-disabled': {
        boxShadow: `0 0 0 1px $lightText1`,
        borderColor: '$lightText1',
    },
    '.react-select__value-container': {
        overflow: 'visible',
    },
    '.react-select__group-heading': {
        color: '$lightText',
    },
    '.react-select__menu': {
        // width: '80%',
        borderRadius: '$1',
        // overflow: 'hidden',
        boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.08)',
        // marginLeft: '5px',
    },
    '.react-select__placeholder': {
        marginLeft: '20px',
        color: '$lightText',
    },
    '.emr-placeholder': {
        position: 'absolute',
        top: '-10px',
        color: '$lightText',
        padding: 'auto 1ch',
        background: '$backgroundCard',
        fontSize: '$4',
    },
    '.emr-placeholder-no-value': {
        position: 'absolute',
        top: '-15px',
        marginLeft: '8px',
        color: '$lightText',
        padding: 'auto 1ch',
        background: '$backgroundCard',
        fontSize: '$4',
    },
    '.react-select__menu-list': {
        padding: '0',
    },
    variants: {
        fullWidthOptions: {
            true: {
                '.react-select__menu': {
                    width: '100%',
                },
            },
        },
        error: {
            true: {
                '.react-select__control': { borderColor: '$warning !important' },
                '.react-select__placeholder': { color: '$warning !important' },
            },
        },
    },
});


const OptionDiv = styled('div', {
    minHeight: '44px',
    height: 'fit-content',
    display: 'grid',
    gridTemplateColumns: 'auto 15px',
    padding: '13px 12px 13px 20px',
    color: '$primaryText',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '$selectHoverColor',
    },
    variants: {
        selected: {
            true: {
                fontWeight: 600,
            },
        },
        focused: {
            true: {
                backgroundColor: '$selectHoverColor',
            },
        },
    },
});

const RemoveIcon = styled('div', {
    cursor: 'pointer',
    square: '14px',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const InputBox = styled('div', {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '55px 15px',
});

const HelperText = styled('div', {
    fontSize: '$4',
    variants: {
        error: {
            true: {
                color: '$warning !important',
            },
        },
    },
});
