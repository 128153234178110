import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PdfViewer from './PdfViewer';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        maxHeight: '100vh',
        overflow: 'hidden',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocumentViewerDialog(props) {
    const classes = useStyles();
    const [scale, setScale] = React.useState(1.0);

    const handleClose = () => {
        setScale(1.0);
        props.setClose();
    };

    const handleZoomOut = () => {
        setScale((prevState) => Math.max(0.5, prevState - 0.5));
    };

    const handleZoomIn = () => {
        setScale((prevState) => prevState + 0.5);
    };

    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <div className={classes.container}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleZoomOut}
                            aria-label="close"
                            size="large"
                        >
                            <ZoomOutIcon />
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleZoomIn}
                            aria-label="close"
                            size="large"
                        >
                            <ZoomInIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <PdfViewer scale={scale} isUrl={props.isUrl} data={props.data} download_filename={props.title} />
            </div>
        </Dialog>
    );
}
