import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;
export const fetchPages = () => {
    return axios.post(`${API_URL}/Pages/get`, {}).then((r) => r.data);
};

export const fetchMenu = () => {
    return axios.post(`${API_URL}/Menu/load`, {}).then((r) => r.data);
};

export const fetchDefaultRoute = () => {
    return axios.post(`${API_URL}/Routes/default_route`, {}).then((r) => r.data);
};

export const fetchDSP = (site) => {
    return axios.post(`${API_URL}/Dsp/get_details`, { dsp_reference: site }).then((r) => r.data);
};
