import Recycle from '@a2d24/care-at-work-core/Recycle';

export const createConsult = (booking_id, patient, tariff_code, medical_type, careActivityId) => {
    return Recycle.rpcPostV2({
        controller: 'ConsultController',
        method: 'create_consult',
        kwargs: {
            booking_id: booking_id,
            patient_primary_identifier: patient.primary_identifier.value,
            tariff_code_override: tariff_code,
            medical_type: medical_type,
            care_activity_id: careActivityId,
        },
    });
};
