import styles from './styles';
import PreviousPlanSection from './PreviousPlanSection';
import RecommendedPlanSection from './RecommendedPlanSection';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AdditionalPlanSection from './AdditionalPlanSection';
import ConfirmDiseaseManagementPlanDialog from './ConfirmDiseaseManagementPlanDialog';
import {
    useAdditionalConditions,
    useCurrentPatientConditions,
    useMedicalHistory,
} from '../../queries';
import { useNavigate } from 'react-router-dom';
//dual options param for subway path Override flag will still be used only applicable to recommended fields and previous plans
const cleanPlans = (plansList) => {
    return plansList.map((plan) => {
        const { isSelected, isOverrode, ...cleanedPlan } = plan;
        return cleanedPlan;
    });
};
const extract_disease_names = (diseaseDataList) => {
    return diseaseDataList.map((plan) => {
        return plan.disease;
    });
};

const DiseaseManagementTable = ({ config, props, ...rest }) => {
    const navigate = useNavigate();
    const consult = props.consult;
    const recommendedDiseaseManagementConditions =
        consult.rules_result?.disease_management_paths || [];
    // TODO: Remove commented code after testing complete
    // const recommendedDiseaseManagementConditions = [{
    //   disease: 'hypertension',
    //   plan_pathway: 'Uncontrolled'
    // }];
    const recommendedPathwayMap = recommendedDiseaseManagementConditions.reduce(
        (obj, item) => Object.assign(obj, { [item.disease]: item.plan_pathway.name }),
        {}
    );

    const recommendedDiseases = recommendedDiseaseManagementConditions.map((item) => item.disease);
    const currentPatientConditionsQuery = useCurrentPatientConditions(
        consult.patient.primary_identifier.value
    );
    const AdditionalConditionQuery = useAdditionalConditions(
        extract_disease_names(
            currentPatientConditionsQuery.isSuccess ? currentPatientConditionsQuery.data : []
        )
    );
    const classes = styles();
    const previousPlans = (
        currentPatientConditionsQuery.isSuccess ? currentPatientConditionsQuery.data : []
    ).map((condition) => {
        return { isSelected: true, ...condition };
    });
    const additionalPlansLessEnrolled = AdditionalConditionQuery.isSuccess
        ? AdditionalConditionQuery.data
        : [];
    const additionalPlans = additionalPlansLessEnrolled.filter(
        (plan) => !recommendedDiseases.includes(plan.disease)
    );
    const recommendedPlans = additionalPlansLessEnrolled
        .filter((plan) => recommendedDiseases.includes(plan.disease))
        .map(({ pathway, ...rest }) => ({ pathway: recommendedPathwayMap[rest.disease], ...rest }));

    const initialPlans = JSON.parse(JSON.stringify(previousPlans));
    const [selectedPreviousPlans, setSelectedPreviousPlans] = useState(previousPlans);
    const [selectedRecPlans, setSelectedRecPlans] = useState([]);
    const [selectedAddPlans, setSelectedAddPlans] = useState([]);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const handleOnClick = () => {
        setConfirmationDialogOpen(true);
    };
    useEffect(() => {
        setSelectedPlans([...selectedPreviousPlans, ...selectedRecPlans, ...selectedAddPlans]);
    }, [selectedPreviousPlans, selectedRecPlans, selectedAddPlans]);

    useEffect(() => {
        const notUpdated =
            selectedPlans.filter((plan) => {
                const cleanPlan = {
                    disease: plan.disease,
                    pathway: plan.pathway,
                };
                const cleanInitialPlans = initialPlans.map((plan) => {
                    return JSON.stringify({
                        disease: plan.disease,
                        pathway: plan.pathway,
                    });
                });
                return cleanInitialPlans.includes(JSON.stringify(cleanPlan));
            }).length === selectedPlans.length && initialPlans.length === selectedPlans.length;
        setIsUpdated(!notUpdated);
    }, [selectedPlans]);

    if (currentPatientConditionsQuery.isLoading || AdditionalConditionQuery.isLoading) {
        return <div className={classes.loadingDiv}>Loading...</div>;
    }

    if (currentPatientConditionsQuery.isError || AdditionalConditionQuery.isError) {
        return (
            <div className={classes.loadingDiv}>
                Could not load current patient medical conditions: <br />{' '}
                {currentPatientConditionsQuery.error}
            </div>
        );
    }
    return (
        <div>
            <div className={classes.heading}>Disease Management Plan Selector</div>
            <div className={classes.tableDiv}>
                <PreviousPlanSection
                    plans={previousPlans}
                    onSelectionsChange={(updatedSelectedRecPlans) => {
                        setSelectedPreviousPlans(updatedSelectedRecPlans);
                    }}
                />
                {recommendedPlans.length === 0 ? null : (
                    <RecommendedPlanSection
                        plans={recommendedPlans}
                        onSelectionsChange={(updatedSelectedRecPlans) => {
                            setSelectedRecPlans(updatedSelectedRecPlans);
                        }}
                    />
                )}
                {additionalPlans.length === 0 ? null : (
                    <AdditionalPlanSection
                        plans={additionalPlans}
                        onSelectionsChange={(updatedSelectedAddPlans) => {
                            setSelectedAddPlans(updatedSelectedAddPlans);
                        }}
                    />
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '30px' }}>
                <Button variant="contained" onClick={handleOnClick}>
                    {isUpdated ? 'Update plan' : 'Continue'}
                </Button>
            </div>
            <ConfirmDiseaseManagementPlanDialog
                open={confirmationDialogOpen}
                onClose={() => {
                    setConfirmationDialogOpen(false);
                }}
                onConfirm={() => {
                    setConfirmationDialogOpen(false);
                    navigate('/dashboard');
                }}
                title={'Disease Management Plan Confirmation'}
                isUpdated={isUpdated}
                patient_id={props.consult.patient.primary_identifier.value}
                consult_id={consult.consult_id}
                plans={{
                    currentPreviousPlans: cleanPlans(
                        initialPlans.filter((plan) => {
                            const cleanPlan = {
                                disease: plan.disease,
                                pathway: plan.pathway,
                            };
                            const cleanSelectedPlans = selectedPlans.map((plan) => {
                                return JSON.stringify({
                                    disease: plan.disease,
                                    pathway: plan.pathway,
                                });
                            });
                            return (
                                cleanSelectedPlans.includes(JSON.stringify(cleanPlan)) &&
                                !plan.isOverrode
                            );
                        })
                    ),
                    newSelectedPlans: cleanPlans(
                        selectedPlans.filter((plan) => {
                            const cleanPlan = {
                                disease: plan.disease,
                                pathway: plan.pathway,
                            };
                            const cleanInitialPlans = initialPlans.map((plan) => {
                                return JSON.stringify({
                                    disease: plan.disease,
                                    pathway: plan.pathway,
                                });
                            });
                            return !cleanInitialPlans.includes(JSON.stringify(cleanPlan));
                        })
                    ),
                    recommendedPlans: cleanPlans(recommendedPlans),
                    additionalPlans: cleanPlans(additionalPlans),
                    overrodePlans: cleanPlans(
                        selectedPlans.filter((plan) => {
                            return plan.isOverrode;
                        })
                    ),
                    removedPlans: cleanPlans(
                        initialPlans.filter((plan) => {
                            const cleanPlan = {
                                disease: plan.disease,
                                pathway: plan.pathway,
                            };
                            const cleanSelectedPlans = selectedPlans.map((plan) => {
                                return JSON.stringify({
                                    disease: plan.disease,
                                    pathway: plan.pathway,
                                });
                            });
                            return !cleanSelectedPlans.includes(JSON.stringify(cleanPlan));
                        })
                    ),
                }}
            />
        </div>
    );
};

export default DiseaseManagementTable;
