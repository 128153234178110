import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SunIcon from '@mui/icons-material/WbSunny';
import { AppConfigContext } from '../App';
import { sharedStyles } from '../shared/styles';
import clsx from 'clsx';
import { useStores } from '@a2d24/care-at-work-state/stores';
import Select from 'react-select';

const Greetings = ({ config, props }) => {
    const sharedClasses = sharedStyles();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const { authStore } = useStores();
    let reference = authStore.dsp_details?.dsp_reference
        ? authStore.dsp_details.dsp_reference.split('/')
        : '';
    // NOTE: IF THE ROLE IS GLOBAL OMP, THERE SHOULD NOT BE ANY DSP DETAILS
    if(authStore.selectedRole.role === 'global_omp'){
        reference = authStore.selectedSite.identifier.split('/')
    }
    reference = reference.length > 1 ? reference[reference.length - 2] : reference[-1];

    return (
        <>
            <div className={clsx([classes.container, sharedClasses.heading])}>
                <SunIcon className={classes.icon} />
                <div className={classes.hello}>Hello,</div>
                <div>{authStore.user.name.first_names}</div>
            </div>
            <div className={classes.site}>
                {authStore.dsp_details?.dsp_site_name
                    ? authStore.dsp_details.dsp_site_name
                    : authStore.dsp_details?.name}
                {
                    authStore.selectedRole.role === 'global_omp'
                    ? authStore.selectedSite.name
                    : ''
                }
                {' '}
                ({reference})
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: '#F6BA3B',
        fontSize: '45px',
        marginRight: theme.spacing(1),
    },
    hello: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
    },
    site: {
        fontWeight: 'bold',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        // color: '#1D3440'
        color: theme.palette.primary.main,
        fontSize: '20px',
    },
}));

export default Greetings;
