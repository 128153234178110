import ConditionalField from '../../ReactFinalFormFields/RffConditionalWrapper';
import React, { useEffect } from 'react';
import RffReactSelectField from '../../ReactFinalFormFields/RffReactSelectField';
import RffDateField from '../../ReactFinalFormFields/RffDateField';
import { sub } from 'date-fns';
const BookingDetailsPage = ({
    formValues,
    consultTypes,
    onConsultTypeChange,
    diseaseTypeOptions,
    clients,
    divisions,
    onClientChange,
    onDivisionChange,
    siteOptions,
    medicalRoleOptions,
}) => {
    return (
        <>
            <RffReactSelectField
                id="client"
                name="Client"
                options={clients}
                optional={false}
                onChange={onClientChange}
            />
            <RffReactSelectField
                id="consult_type"
                name="Reason for Visit"
                options={consultTypes}
                optional={false}
                onChange={onConsultTypeChange}
            />
            <ConditionalField condition={(values) => divisions.length > 0} fields={['division']}>
                <RffReactSelectField
                    id="division"
                    name="Division"
                    options={divisions}
                    optional={false}
                    onChange={onDivisionChange}
                />
            </ConditionalField>
            <RffReactSelectField
                id="employment_site"
                name="Employment Site"
                options={siteOptions}
                optional={false}
            />
            <ConditionalField
                condition={(values) =>
                    values.consult_type === 'occupational_health_consult_with_cof'
                }
                fields={['medical_role']}
            >
                <RffReactSelectField
                    id="medical_role"
                    name="Medical Role"
                    options={medicalRoleOptions}
                    optional={false}
                />
            </ConditionalField>
            <ConditionalField
                condition={(values) =>
                    ['chronic_care_consult', 'workplace_disease_consult'].includes(
                        values.consult_type
                    )
                }
                fields={['disease_type']}
            >
                <RffReactSelectField
                    id="disease_type"
                    name={
                        formValues.consult_type === 'chronic_care_consult'
                            ? 'Chronic Disease Type'
                            : 'Workplace Disease Type'
                    }
                    options={diseaseTypeOptions}
                    optional={false}
                />
            </ConditionalField>
            <RffDateField
                id="service_date"
                name="Service Date"
                optional={false}
                minDate={sub(new Date(), { months: 6 })}
                maxDate={new Date()}
                defaultValue={new Date()}
                isDisabled={
                    ![
                        'occupational_health_consult_with_cof',
                        'adhoc_occupational_health_consult_without_cof',
                        'primary_health_care_consult',
                    ].includes(formValues.consult_type)
                }
            />
        </>
    );
};
export default BookingDetailsPage;
