import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';
import { evaluatePolishExpression } from '@a2d24/care-at-work-core/Recycle';

const CalculatedTextField = ({ id, name, optional, unit, description, warning, calculation }) => {
    const label = name;
    const form = useForm();
    const state = useFormState({ subscription: { values: true } });

    React.useEffect(() => {
        // const shouldCalculate = evaluatePolishExpression(calculation_precondition, state.values);

        let value = evaluatePolishExpression(calculation, state.values);

        // [IM]: Quick fix resulted in this fragile nested if structure. Needs restructuring.
        if (value === undefined) {
            if (state.values[id] !== undefined) {
                form.change(id, null);
            }
        }
        if (state.values[id] !== value) {
            form.change(id, value);
        }
    }, [state.values, id, form, calculation]);

    return (
        <FieldLayout description={description} warning={warning}>
            <Field name={id} placeholder={label} optional={optional} unit={unit}>
                {(fieldProps) => {
                    return (
                        <TextField
                            label={fieldProps.placeholder}
                            name={fieldProps.input.name}
                            testid={id}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                            required={optional === false}
                        />
                    );
                }}
            </Field>
        </FieldLayout>
    );
};

export default CalculatedTextField;
